import { type NavigateFunction } from "react-router-dom";
import { routes } from "../../router/routes";
import {
	AccountData,
	AccountParams,
	APIError,
	RequestOptions,
	UpdateAccountData
} from "../../types";

import { alertConflictError } from "../../utils/error";
import { createRoute } from "../../utils/request";

import { api } from "./api";

import { crud } from "./crud";

const deleteAccount = (
	navigate: NavigateFunction,
	params: { accountID: string; password: string }
): Promise<void | {} | APIError> => {
	const { accountID, password } = params;
	const route = createRoute(true, routes.ACCOUNTS, accountID);

	const config = {
		headers: {
			"X-Confirm-Password": encodeURIComponent(password as string)
		}
	};

	const options = { config, clearSession: true };

	return new Promise((resolve, reject) => {
		api.DELETE<void | {} | APIError>(navigate, route, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const getAccount = (
	navigate: NavigateFunction,
	options: RequestOptions | {}
): Promise<AccountData | {} | APIError> => {
	const route = createRoute(false, routes.ACCOUNTS, routes.CURRENT);

	return crud.READ<AccountData>(navigate, route, options as RequestOptions);
};

const updateAccount = (
	navigate: NavigateFunction,
	params: AccountParams,
	data: UpdateAccountData,
	options?: RequestOptions
): Promise<AccountData | {} | APIError> => {
	const { accountID } = params;

	const route = createRoute(true, routes.ACCOUNTS, accountID);

	return crud.UPDATE<AccountData>(
		navigate,
		route,
		data,
		options as RequestOptions
	);
};

const accountAPI = {
	delete: deleteAccount,
	read: getAccount,
	update: updateAccount
};

export default accountAPI;
