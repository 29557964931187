import { createSlice } from "@reduxjs/toolkit";

import { APIRequestArgs } from "../../types";

export type RequestQueueElement = {
	request: (options: APIRequestArgs) => void;
	requestOptions: APIRequestArgs;
};

export interface ISessionReducer {
	requestQueue: RequestQueueElement[];
	tokenIsRefreshing: boolean;
}

const initialState: ISessionReducer = {
	requestQueue: [],
	tokenIsRefreshing: false
};

const sessionSlice = createSlice({
	name: "session",
	initialState,
	reducers: {
		addRequestToRequestQueue: (
			state,
			action: { payload: RequestQueueElement }
		) => {
			state.requestQueue.push(action.payload);
		},
		emptyRequestQueue: state => {
			state.requestQueue = [];
		},
		setAccessTokenIsRefreshing: (state, action: { payload: boolean }) => {
			state.tokenIsRefreshing = action.payload;
		}
	}
});

export const {
	addRequestToRequestQueue,
	emptyRequestQueue,
	setAccessTokenIsRefreshing
} = sessionSlice.actions;
export default sessionSlice.reducer;
