import { configureStore } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";

import { config } from "../../config/config";

import { env } from "../../utils/const";

import reducers, { IStoreState } from "../reducers";

const sentryReduxEnhancer = createReduxEnhancer();

export type RootState = ReturnType<typeof reducers>;

export const testStore = (preloadedState?: Partial<IStoreState>) => {
	return configureStore({
		reducer: reducers,
		preloadedState: preloadedState as any
	});
};

export const store = configureStore({
	reducer: reducers,
	devTools: config.env !== env.PROD,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({ serializableCheck: false }),
	enhancers: getDefaultEnhancers =>
		getDefaultEnhancers().concat(sentryReduxEnhancer)
});

export const dispatch = store.dispatch;

export type AppDispatch = typeof store.dispatch;
