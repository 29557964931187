import { type NavigateFunction } from "react-router-dom";
import { createSlice } from "@reduxjs/toolkit";

import { routes } from "../../router/routes";

import { PersistedPaging } from "../../types/ui";

import { paging } from "../../utils/const";
import { createRoute } from "../../utils/request";

const { PARTICIPANT_RESULTS, REPORT, GROUPS, PARTICIPANTS, RUNS, TESTS } =
	paging.LOCATION;

export interface IUIReducer {
	contactUsModalActive: boolean;
	lastOpenedPage: string;
	loading: boolean;
	loggedIn: boolean;
	mosLoading: boolean;
	pagination: PersistedPaging[];
}

export const uiInitialState: IUIReducer = {
	contactUsModalActive: false,
	lastOpenedPage: "",
	loading: false,
	loggedIn: false,
	mosLoading: false,
	pagination: [
		{ filters: null, location: GROUPS, page: null },
		{ filters: null, location: PARTICIPANTS, page: null },
		{ location: PARTICIPANT_RESULTS.ASSERTS, page: null },
		{ location: REPORT.ASSERTS, page: null },
		{ filters: null, location: REPORT.PARTICIPANTS, page: null },
		{ filters: null, location: RUNS, page: null },
		{ filters: null, location: TESTS, page: null }
	]
};

const uiSlice = createSlice({
	name: "ui",
	initialState: uiInitialState,
	reducers: {
		checkAuthStatus: (
			state,
			action: {
				payload: {
					authState: boolean;
					nextRoute: string | null;
					navigate: NavigateFunction;
				};
			}
		) => {
			const { authState, nextRoute, navigate } = action.payload;
			if (state.loggedIn === authState) return;
			state.loggedIn = authState;
			if (!authState || nextRoute) {
				navigate(nextRoute || createRoute(false, routes.LOG_IN));
			}
		},
		persistPagingInfo: (
			state,
			action: {
				payload: PersistedPaging;
			}
		) => {
			const { filters, location, page } = action.payload;
			state.pagination = state.pagination.map(pagingInfo =>
				pagingInfo.location === location
					? { ...pagingInfo, filters, page }
					: pagingInfo
			);
		},
		clearPagingInfo: (state, action) => {
			const location = action.payload;
			state.pagination = state.pagination.map(pagingInfo =>
				pagingInfo.location === location
					? { ...pagingInfo, filters: null, page: 1 }
					: pagingInfo
			);
		},
		clearAllPagingInfo: state => {
			state.pagination = uiInitialState.pagination;
		},
		initializeLoader: state => {
			state.loading = true;
		},
		onDataLoading: (state, action) => {
			state.loading = action.payload;
		},
		onMOSLoading: (state, action) => {
			state.mosLoading = action.payload;
		},
		setLastOpenedPage: (state, action) => {
			state.lastOpenedPage = action.payload;
		},
		clearUIData: () => {
			return uiInitialState;
		}
	}
});

export const {
	checkAuthStatus,
	persistPagingInfo,
	clearPagingInfo,
	clearAllPagingInfo,
	initializeLoader,
	onDataLoading,
	onMOSLoading,
	setLastOpenedPage,
	clearUIData
} = uiSlice.actions;

export default uiSlice.reducer;
