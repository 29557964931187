import { type NavigateFunction } from "react-router-dom";

import {
	APIError,
	ArtifactOptions,
	ArtifactResponse,
	RequestOptions,
	WebRTCDump
} from "../../types";

import { alertConflictError } from "../../utils/error";
import { getRequestConfig } from "../../utils/request";

import { crud } from "./crud";
import { mediaAPIRest } from "./config";

const get = (
	navigate: NavigateFunction,
	url: string,
	options?: RequestOptions & ArtifactOptions
): Promise<ArtifactResponse | WebRTCDump | APIError> => {
	return crud.READ(navigate, url, options || {});
};

const getArtifact = (
	url: string,
	options?: RequestOptions
): Promise<ArtifactResponse | APIError> => {
	return new Promise((resolve, reject) => {
		mediaAPIRest
			.get(url, {
				...getRequestConfig(options),
				baseURL: ""
			})
			.then(
				response => {
					resolve(response.data || {});
				},
				error => {
					alertConflictError(error);
					reject(error);
				}
			);
	});
};

const mediaAPI = {
	get,
	getArtifact
};

export default mediaAPI;
