import { default as i18n } from "i18next";
import type { InitOptions } from "i18next";

import eng from "../translations/eng.json";

i18n.init({
	debug: false,
	defaultNS: "eng",
	fallbackLng: "en-US",
	lng: "en-US",
	react: {
		useSuspense: true,
		bindI18n: "languageChanged loaded",
		nsMode: "default",
		bindStore: false
	},
	resources: {
		"en-US": {
			eng
		}
	}
} as InitOptions);

export default i18n;
