import { createSlice } from "@reduxjs/toolkit";

import { WebRTCDump } from "../../types";

export interface IDataDumpReducer {
	browser: string | null;
	rru: string | null;
	selenium: string | null;
	stats: unknown | unknown[];
	webrtc: WebRTCDump | string | null;
}

const initialState: IDataDumpReducer = {
	browser: null,
	rru: null,
	selenium: null,
	stats: [],
	webrtc: null
};

const dataDumpSlice = createSlice({
	name: "dataDump",
	initialState,
	reducers: {
		persistDump: (
			state,
			action: { payload: { type: string; dump: unknown | unknown[] } }
		) => {
			const { type, dump } = action.payload;
			(state as any)[type as keyof IDataDumpReducer] = dump;
		},
		clearDumps: () => initialState
	}
});

export const { persistDump, clearDumps } = dataDumpSlice.actions;
export default dataDumpSlice.reducer;
