import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	RequestOptions,
	SetupIntentData,
	Subscription,
	SubscriptionData,
	SubscriptionParams
} from "../../types";
import { createRoute } from "../../utils/request";

import { crud } from "./crud";

const createSubscription = (
	navigate: NavigateFunction,
	params: SubscriptionParams,
	data: SubscriptionData,
	options: RequestOptions
): Promise<Subscription | {} | APIError> => {
	const { projectID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS
	);

	return crud.CREATE<Subscription>(navigate, route, data, options);
};

const updateSubscription = (
	navigate: NavigateFunction,
	params: Required<SubscriptionParams>,
	data: SubscriptionData,
	options: RequestOptions
): Promise<Subscription | {} | APIError> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID
	);

	return crud.UPDATE<Subscription>(navigate, route, data, options);
};

const readSubscription = (
	navigate: NavigateFunction,
	params: Required<SubscriptionParams>,
	options: RequestOptions
): Promise<Subscription | void> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID
	);

	return crud.READ<Subscription | void>(navigate, route, options);
};

const cancelSubscription = (
	navigate: NavigateFunction,
	params: Required<SubscriptionParams>,
	options: RequestOptions
): Promise<void | APIError> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID
	);

	return crud.DELETE(navigate, route, options);
};

const createSetupIntent = (
	navigate: NavigateFunction,
	params: Required<SubscriptionParams>,
	options: RequestOptions
): Promise<SetupIntentData | APIError> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID,
		routes.SETUP_INTENT
	);

	return crud.READ<SetupIntentData>(navigate, route, options);
};

const activateSubscription = (
	navigate: NavigateFunction,
	params: Required<SubscriptionParams>,
	options: RequestOptions
): Promise<void | {} | APIError> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID,
		routes.ACTIVATE
	);

	return crud.CREATE(navigate, route, options);
};

const subscriptionAPI = {
	activate: activateSubscription,
	create: createSubscription,
	delete: cancelSubscription,
	update: updateSubscription,
	read: readSubscription,
	createSetupIntent
};

export default subscriptionAPI;
