import { type NavigateFunction } from "react-router-dom";
import { routes } from "../../router/routes";

import { TokenParams } from "../../types/project";
import { Token, APIError, RequestOptions } from "../../types";

import { queryKeys as qk } from "../../utils/const";
import { createQuery, createRoute, setQueryParam } from "../../utils/request";

import { crud } from "./crud";

const createToken = (
	navigate: NavigateFunction,
	data: Token,
	params: TokenParams,
	options: RequestOptions
): Promise<Token | {}> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.TOKENS);

	return crud.CREATE<Token>(navigate, route, data, options);
};

const deleteToken = (
	navigate: NavigateFunction,
	params: TokenParams,
	queryParams: { token: string },
	options: RequestOptions
): Promise<void | APIError> => {
	const { token } = queryParams;
	const { projectID } = params;
	let route = createRoute(false, routes.PROJECTS, projectID, routes.TOKENS);

	route += createQuery(setQueryParam(qk.TOKEN, token));

	return crud.DELETE(navigate, route, options);
};

const getAllTokens = (
	navigate: NavigateFunction,
	params: TokenParams,
	options: RequestOptions
): Promise<Token[] | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.TOKENS);

	return crud.READ<Token[]>(navigate, route, options);
};

const tokenAPI = {
	create: createToken,
	delete: deleteToken,
	readAll: getAllTokens
};

export default tokenAPI;
