import { isCancel } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CreateMemberData,
	MemberData,
	MemberParams,
	Pagination,
	ResponseWithPaging,
	RequestOptions
} from "../../types";

import { alertConflictError } from "../../utils/error";
import { checkID } from "../../utils/method";
import { createPaginationQuery, createRoute } from "../../utils/request";

import { api } from "./api";

import { crud } from "./crud";

const createMember = (
	navigate: NavigateFunction,
	params: MemberParams,
	data: CreateMemberData,
	options: RequestOptions
): Promise<MemberData | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.MEMBERS);
	return new Promise((resolve, reject) => {
		api.POST<MemberData | {} | APIError>(navigate, route, data, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const deleteMember = (
	navigate: NavigateFunction,
	params: Required<MemberParams>,
	pending: boolean
): Promise<void | {} | APIError> => {
	const { memberID, projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		pending ? routes.INVITED : "",
		checkID(memberID)
	);

	return new Promise((resolve, reject) => {
		api.DELETE<void | {} | APIError>(navigate, route).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const getAllMembers = (
	navigate: NavigateFunction,
	params: MemberParams,
	invited: boolean,
	pagination: Pagination | null,
	options: RequestOptions
): Promise<ResponseWithPaging<MemberData> | APIError> => {
	const { projectID } = params;
	let route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		invited ? routes.INVITED : ""
	);

	if (pagination) {
		const { limit, page } = pagination;
		route += createPaginationQuery(limit, page);
	}

	return crud.READ<ResponseWithPaging<MemberData>>(navigate, route, options);
};

const updateMember = (
	navigate: NavigateFunction,
	params: Required<MemberParams>,
	data: CreateMemberData,
	pending: boolean,
	options: RequestOptions
): Promise<MemberData | {} | APIError> => {
	const { memberID, projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.MEMBERS,
		pending ? routes.INVITED : "",
		checkID(memberID)
	);

	return crud.UPDATE<MemberData>(navigate, route, data, options);
};

const memberAPI = {
	create: createMember,
	delete: deleteMember,
	readAll: getAllMembers,
	update: updateMember
};

export default memberAPI;
