import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	Filters,
	GroupData,
	GroupParams,
	InternalGroup,
	Pagination,
	RequestOptions,
	ResponseWithPaging
} from "../../types";
import { queryKeys as qk } from "../../utils/const";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createGroup = (
	navigate: NavigateFunction,
	params: Required<GroupParams>,
	data: InternalGroup,
	options: RequestOptions
): Promise<InternalGroup | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS
	);

	return crud.CREATE<InternalGroup>(navigate, route, data, options);
};

const deleteGroup = (
	navigate: NavigateFunction,
	params: Required<GroupParams>,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { groupID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS,
		groupID
	);

	return crud.DELETE(navigate, route, options as RequestOptions);
};

const getGroup = (
	navigate: NavigateFunction,
	params: Required<GroupParams>,
	options: RequestOptions
): Promise<GroupData | {} | APIError> => {
	const { groupID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS,
		groupID
	);

	return crud.READ<GroupData>(navigate, route, options);
};

const getAllGroups = (
	navigate: NavigateFunction,
	params: GroupParams,
	queryParams: { filters: Filters; pagination: Pagination },
	options: RequestOptions
): Promise<ResponseWithPaging<InternalGroup> | {} | APIError> => {
	const { projectID, testID } = params;
	const { filters, pagination } = queryParams;
	const { limit, page } = pagination;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS
	);

	route += createQuery(
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page)),
		filters && setQueryParam(qk.FILTER.NAME, filters.name as string)
	);

	return crud.READ<ResponseWithPaging<InternalGroup>>(navigate, route, options);
};

const updateGroup = (
	navigate: NavigateFunction,
	params: Required<GroupParams>,
	data: InternalGroup,
	options: RequestOptions
): Promise<InternalGroup | {} | APIError> => {
	const { groupID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS,
		groupID
	);

	return crud.UPDATE<InternalGroup>(navigate, route, data, options);
};

const copyGroup = (
	navigate: NavigateFunction,
	params: Required<GroupParams>,
	data: InternalGroup,
	options: RequestOptions
): Promise<InternalGroup | {} | APIError> => {
	const { groupID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.GROUPS,
		groupID,
		routes.COPY
	);

	return crud.CREATE<InternalGroup>(navigate, route, data, options);
};

const groupAPI = {
	create: createGroup,
	delete: deleteGroup,
	read: getGroup,
	readAll: getAllGroups,
	update: updateGroup,
	copy: copyGroup
};

export default groupAPI;
