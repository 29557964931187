import i18n from "../config/i18n";
import { FlowToggleOption, RoleInfo, StorageKey } from "../types";

const accountRoles: { [key: string]: RoleInfo } = {
	ADMINISTRATOR: {
		label: "Administrator",
		key: "administrator"
	},
	DEVELOPER: {
		label: "Developer",
		key: "developer"
	},
	NONE: {
		label: "None",
		key: "none"
	},
	VISITOR: {
		label: "Visitor",
		key: "visitor"
	}
};

const accountRolesOptions: RoleInfo[] = [
	{ key: "administrator", label: "Administrator" },
	{ key: "developer", label: "Developer" },
	{ key: "visitor", label: "Visitor" }
];

const allRegionsToolTip = [
	"AP East - Hong Kong",
	"AP Northeast - Tokyo",
	"AP Northeast - Seoul",
	"AP South - Mumbai",
	"AP Southeast - Sydney",
	"EU Central - Frankfurt",
	"EU West - Ireland",
	"EU West - Paris",
	"SA East - São Paulo",
	"US East - North Virginia",
	"US East - Ohio",
	"US West - Oregon"
];

const app = {
	BLOG: "https://loadero.com/blog",
	COOKIE_POLICY: "https://loadero.com/cookie-policy",
	DOCS: "https://api.loadero.com/v2/docs/",
	NAME: "Loadero",
	WIKI: "https://wiki.loadero.com"
};

const artifacts = {
	AUDIO: {
		title: "ARTIFACTS.AUDIO",
		icon: "artifacts-audio",
		iconExpired: "artifacts-audio-expired"
	},
	DOWNLOADS: {
		title: "ARTIFACTS.DOWNLOADS",
		icon: "artifacts-downloads",
		iconExpired: "artifacts-downloads-expired"
	},
	SCREENSHOTS: {
		title: "ARTIFACTS.SCREENSHOTS",
		icon: "artifacts-photos",
		iconExpired: "artifacts-photos-expired"
	},
	VIDEO: {
		title: "ARTIFACTS.VIDEO",
		icon: "artifacts-video",
		iconExpired: "artifacts-video-expired"
	}
};

const audioFeed = {
	VISQOL_SPEECH: "visqol-speech",
	SILENCE: "silence"
};

const authKeys: { [key: string]: StorageKey } = {
	ACCESS_TOKEN: "access_token",
	CLIENT_CREDENTIALS: "client_credentials",
	CLIENT_ID: "client_id",
	CODE_CHALLENGE: "code_challenge",
	CODE_CHALLENGE_METHOD: "code_challenge_method",
	CODE_VERIFIER: "code_verifier",
	EXPIRES_AT: "expires_at",
	GRANT_TYPE: "grant_type",
	LOADERO_SUPERUSER: "loadero_superuser",
	REDIRECT_URI: "redirect_uri",
	REFRESH_TOKEN: "refresh_token",
	REFRESH_TOKEN_PENDING: "refresh_token_pending",
	REFRESH_TOKEN_RESOLVED: "refresh_token_resolved",
	RESPONSE_TYPE: "response_type",
	SCOPE: "scope",
	SCOPES: "scopes",
	STATE: "state",
	TOKEN_TYPE: "token_type"
};

const blogLinks = {
	START_USING_LOADERO:
		"https://loadero.com/blog/articles/how-to-start-using-loadero/"
};

const browser = {
	CHROME: "chrome",
	FIREFOX: "firefox"
};

const browserActions = {
	POP: "POP",
	PUSH: "PUSH"
};

const buyerKind = {
	COMPANY: "company",
	PRIVATE: "private"
};

const cards = {
	AMEX: "amex",
	CARTES_BANCAIRES: "cartes_bancaires",
	DINERS_CLUB: "diners_club",
	DISCOVER: "discover",
	JCB: "jcb",
	MASTERCARD: "mastercard",
	VISA: "visa",
	UNIONPAY: "unionpay"
};

const codeEditor = {
	options: {
		fontSize: 12,
		minimap: {
			enabled: true
		},
		padding: {
			top: 7,
			bottom: 10
		},
		readOnly: false,
		scrollbar: {
			alwaysConsumeMouseWheel: false
		},
		scrollBeyondLastLine: false,
		automaticLayout: false
	},
	formatting: {
		tabSize: 4
	}
};

const confirmTypes = {
	CONFIRM: {
		key: "confirm",
		value: "CONFIRM"
	},
	CUSTOM: {
		key: "custom",
		value: "CONFIRM"
	},
	DELETE: {
		key: "delete",
		value: "DELETE"
	},
	INFO: {
		key: "info",
		value: "OK"
	}
};

const contactSources = {
	feedback: "feedback"
};

const cookies = {
	AD_PERSONALIZATION: "ad_personalization",
	AD_STORAGE: "ad_storage",
	AD_USER_DATA: "ad_user_data",
	ANALYTICS_STORAGE: "analytics_storage",
	CONSENT: "consent",
	DENIED: "denied",
	FUNCTIONALITY_STORAGE: "functionality_storage",
	GRANTED: "granted",
	LOGGED_IN: "loggedIn",
	LOGGED_IN_STAGE: "loggedInStage",
	PERSONALIZATION_STORAGE: "personalization_storage",
	SECURITY_STORAGE: "security_storage",
	STORAGE_KEY: "cookies"
};

const cookiesDefault = {
	ad_personalization: false,
	ad_storage: false,
	ad_user_data: false,
	analytics_storage: false,
	functionality_storage: false,
	personalization_storage: false,
	security_storage: true
};

const cookiesSwitchers = {
	analytics_storage: "analytics_storage",
	personalization_storage: "personalization_storage",
	security_storage: "security_storage"
};

const copyPrefix = "Copy of";

const countries = [
	{ label: "Afghanistan", key: "AF" },
	{ label: "Albania", key: "AL" },
	{ label: "Algeria", key: "DZ" },
	{ label: "Andorra", key: "AD" },
	{ label: "Angola", key: "AO" },
	{ label: "Argentina", key: "AR" },
	{ label: "Armenia", key: "AM" },
	{ label: "Australia", key: "AU" },
	{ label: "Austria", key: "AT" },
	{ label: "Azerbaijan", key: "AZ" },
	{ label: "Bahamas", key: "BS" },
	{ label: "Bahrain", key: "BH" },
	{ label: "Bangladesh", key: "BD" },
	{ label: "Barbados", key: "BB" },
	{ label: "Belarus", key: "BY" },
	{ label: "Belgium", key: "BE" },
	{ label: "Bolivia", key: "BO" },
	{ label: "Bosnia and Herzegovina", key: "BA" },
	{ label: "Botswana", key: "BW" },
	{ label: "Brazil", key: "BR" },
	{ label: "Bulgaria", key: "BG" },
	{ label: "Burkina Faso", key: "BF" },
	{ label: "Burundi", key: "BI" },
	{ label: "Cambodia", key: "KH" },
	{ label: "Cameroon", key: "CM" },
	{ label: "Canada", key: "CA" },
	{ label: "Cayman Islands", key: "KY" },
	{ label: "Central African Republic", key: "CF" },
	{ label: "Chad", key: "TD" },
	{ label: "Chile", key: "CL" },
	{ label: "China", key: "CN" },
	{ label: "Colombia", key: "CO" },
	{ label: "Comoros", key: "KM" },
	{ label: "Congo", key: "CG" },
	{ label: "Costa Rica", key: "CR" },
	{ label: "Croatia", key: "HR" },
	{ label: "Cuba", key: "CU" },
	{ label: "Curaçao", key: "CW" },
	{ label: "Cyprus", key: "CY" },
	{ label: "Czech Republic", key: "CZ" },
	{ label: "Denmark", key: "DK" },
	{ label: "Dominica", key: "DM" },
	{ label: "Dominican Republic", key: "DO" },
	{ label: "Ecuador", key: "EC" },
	{ label: "Egypt", key: "EG" },
	{ label: "El Salvador", key: "SV" },
	{ label: "Equatorial Guinea", key: "GQ" },
	{ label: "Eritrea", key: "ER" },
	{ label: "Estonia", key: "EE" },
	{ label: "Ethiopia", key: "ET" },
	{ label: "Falkland Islands (Malvinas)", key: "FK" },
	{ label: "Faroe Islands", key: "FO" },
	{ label: "Fiji", key: "FJ" },
	{ label: "Finland", key: "FI" },
	{ label: "France", key: "FR" },
	{ label: "Gabon", key: "GA" },
	{ label: "Gambia", key: "GM" },
	{ label: "Georgia", key: "GE" },
	{ label: "Germany", key: "DE" },
	{ label: "Ghana", key: "GH" },
	{ label: "Gibraltar", key: "GI" },
	{ label: "Greece", key: "GR" },
	{ label: "Greenland", key: "GL" },
	{ label: "Grenada", key: "GD" },
	{ label: "Guadeloupe", key: "GP" },
	{ label: "Guam", key: "GU" },
	{ label: "Guatemala", key: "GT" },
	{ label: "Guernsey", key: "GG" },
	{ label: "Guinea", key: "GN" },
	{ label: "Guinea-Bissau", key: "GW" },
	{ label: "Guyana", key: "GY" },
	{ label: "Haiti", key: "HT" },
	{ label: "Holy See", key: "VA" },
	{ label: "Honduras", key: "HN" },
	{ label: "Hong Kong", key: "HK" },
	{ label: "Hungary", key: "HU" },
	{ label: "Iceland", key: "IS" },
	{ label: "India", key: "IN" },
	{ label: "Indonesia", key: "ID" },
	{ label: "Iran", key: "IR" },
	{ label: "Iraq", key: "IQ" },
	{ label: "Ireland", key: "IE" },
	{ label: "Isle of Man", key: "IM" },
	{ label: "Israel", key: "IL" },
	{ label: "Italy", key: "IT" },
	{ label: "Jamaica", key: "JM" },
	{ label: "Japan", key: "JP" },
	{ label: "Jersey", key: "JE" },
	{ label: "Jordan", key: "JO" },
	{ label: "Kazakhstan", key: "KZ" },
	{ label: "Kenya", key: "KE" },
	{ label: "Kuwait", key: "KW" },
	{ label: "Kyrgyzstan", key: "KG" },
	{ label: "Lao People's Democratic Republic", key: "LA" },
	{ label: "Latvia", key: "LV" },
	{ label: "Lebanon", key: "LB" },
	{ label: "Lesotho", key: "LS" },
	{ label: "Liberia", key: "LR" },
	{ label: "Libya", key: "LY" },
	{ label: "Liechtenstein", key: "LI" },
	{ label: "Lithuania", key: "LT" },
	{ label: "Luxembourg", key: "LU" },
	{ label: "Macedonia ", key: "MK" },
	{ label: "Madagascar", key: "MG" },
	{ label: "Malawi", key: "MW" },
	{ label: "Malaysia", key: "MY" },
	{ label: "Maldives", key: "MV" },
	{ label: "Mali", key: "ML" },
	{ label: "Malta", key: "MT" },
	{ label: "Mauritania", key: "MR" },
	{ label: "Mexico", key: "MX" },
	{ label: "Moldova", key: "MD" },
	{ label: "Monaco", key: "MC" },
	{ label: "Mongolia", key: "MN" },
	{ label: "Montenegro", key: "ME" },
	{ label: "Montserrat", key: "MS" },
	{ label: "Morocco", key: "MA" },
	{ label: "Mozambique", key: "MZ" },
	{ label: "Myanmar", key: "MM" },
	{ label: "Namibia", key: "NA" },
	{ label: "Nauru", key: "NR" },
	{ label: "Nepal", key: "NP" },
	{ label: "Netherlands", key: "NL" },
	{ label: "New Caledonia", key: "NC" },
	{ label: "New Zealand", key: "NZ" },
	{ label: "Nicaragua", key: "NI" },
	{ label: "Niger", key: "NE" },
	{ label: "Nigeria", key: "NG" },
	{ label: "Niue", key: "NU" },
	{ label: "Norfolk Island", key: "NF" },
	{ label: "Northern Mariana Islands", key: "MP" },
	{ label: "Norway", key: "NO" },
	{ label: "Oman", key: "OM" },
	{ label: "Pakistan", key: "PK" },
	{ label: "Palau", key: "PW" },
	{ label: "Palestine, State of", key: "PS" },
	{ label: "Panama", key: "PA" },
	{ label: "Papua New Guinea", key: "PG" },
	{ label: "Paraguay", key: "PY" },
	{ label: "Peru", key: "PE" },
	{ label: "Philippines", key: "PH" },
	{ label: "Pitcairn", key: "PN" },
	{ label: "Poland", key: "PL" },
	{ label: "Portugal", key: "PT" },
	{ label: "Puerto Rico", key: "PR" },
	{ label: "Qatar", key: "QA" },
	{ label: "Republic of Kosovo", key: "XK" },
	{ label: "Romania", key: "RO" },
	{ label: "Russian Federation", key: "RU" },
	{ label: "San Marino", key: "SM" },
	{ label: "Sao Tome and Principe", key: "ST" },
	{ label: "Saudi Arabia", key: "SA" },
	{ label: "Senegal", key: "SN" },
	{ label: "Serbia", key: "RS" },
	{ label: "Seychelles", key: "SC" },
	{ label: "Sierra Leone", key: "SL" },
	{ label: "Singapore", key: "SG" },
	{ label: "Slovakia", key: "SK" },
	{ label: "Slovenia", key: "SI" },
	{ label: "Solomon Islands", key: "SB" },
	{ label: "Somalia", key: "SO" },
	{ label: "South Africa", key: "ZA" },
	{ label: "South Korea", key: "KR" },
	{ label: "South Sudan", key: "SS" },
	{ label: "Spain", key: "ES" },
	{ label: "Sri Lanka", key: "LK" },
	{ label: "Sudan", key: "SD" },
	{ label: "Suriname", key: "SR" },
	{ label: "Swaziland", key: "SZ" },
	{ label: "Sweden", key: "SE" },
	{ label: "Switzerland", key: "CH" },
	{ label: "Syrian Arab Republic", key: "SY" },
	{ label: "Taiwan", key: "TW" },
	{ label: "Tajikistan", key: "TJ" },
	{ label: "Tanzania, United Republic of", key: "TZ" },
	{ label: "Thailand", key: "TH" },
	{ label: "Tunisia", key: "TN" },
	{ label: "Turkey", key: "TR" },
	{ label: "Turkmenistan", key: "TM" },
	{ label: "Tuvalueu", key: "TV" },
	{ label: "Uganda", key: "UG" },
	{ label: "Ukraine", key: "UA" },
	{ label: "United Arab Emirates", key: "AE" },
	{ label: "United Kingdom", key: "GB" },
	{ label: "United States of America", key: "US" },
	{ label: "Uruguay", key: "UY" },
	{ label: "Uzbekistan", key: "UZ" },
	{ label: "Vanuatu", key: "VU" },
	{ label: "Venezuela", key: "VE" },
	{ label: "Vietnam", key: "VN" },
	{ label: "Yemen", key: "YE" },
	{ label: "Zambia", key: "ZM" },
	{ label: "Zimbabwe", key: "ZW" }
];

const cssClasses = {
	ACTIVE: "active",
	ANIMATED_IN: "animated-in",
	NO_OVERSCROLL_BEHAVIOUR: "no-overscroll-behavior",
	NO_SCROLL: "no-scroll",
	NO_TRANSITION: "no-transition",
	POSITION_FIXED: "position-fixed"
};

const currencySymbols = {
	euro: "€",
	usd: "$",
	gbp: "£"
};

const dataDumpType = {
	BROWSER: "browser",
	RRU: "rru",
	STATS: "stats",
	SELENIUM: "selenium",
	WEBRTC: "webrtc"
} as const;

const dateTimeFormat = {
	DATE_SLASH: "DD/MM/YYYY",
	DATE_DASH: "DD-MM-YYYY",
	DATE_TIME: "HH:mm:ss DD/MM/YYYY",
	MONTH_DAY_YEAR_TIME: "MMM DD, YYYY HH:mm:ss",
	TIME: "HH:mm:ss"
};

const defaultConfirmModal = {
	message: "",
	type: confirmTypes.INFO,
	visible: false
};

const downloadWrapperType = { BUTTON: "button", LINK: "link" } as const;

const emailConfig = {
	EMAIL: "support@loadero.com",
	UPGRADE: "I want to upgrade project plan"
};

const env = {
	DEV: "dev",
	DEV_STAGE: "dev-stage",
	PROD: "prod",
	STAGE: "stage"
};

const errorMessages = {
	UNVERIFIED_EMAIL: "unverified_email"
};

const EUCountries = {
	AT: "AT",
	BE: "BE",
	BG: "BG",
	CZ: "CZ",
	CY: "CY",
	DK: "DK",
	DE: "DE",
	EE: "EE",
	ES: "ES",
	FI: "FI",
	FR: "FR",
	GR: "GR",
	HR: "HR",
	HU: "HU",
	IE: "IE",
	IT: "IT",
	LV: "LV",
	LT: "LT",
	LU: "LU",
	MT: "MT",
	NL: "NL",
	PL: "PL",
	PT: "PT",
	RO: "RO",
	SI: "SI",
	SK: "SK",
	SE: "SE"
};

const filters = {
	asserts: {
		ALL: {
			key: "all",
			value: "ALL"
		},
		ACTIONS: {
			key: "actions",
			value: "REPORT.ASSERTS.ACTIONS"
		},
		MACHINE: {
			key: "machine",
			value: "REPORT.ASSERTS.MACHINE"
		},
		WEBRTC: {
			key: "webrtc",
			value: "REPORT.ASSERTS.WEBRTC"
		}
	},
	classificators: {
		LOCATION: {
			key: "location",
			value: "PARTICIPANT.LOCATION"
		},
		BROWSER: {
			key: "browser",
			value: "PARTICIPANT.BROWSER"
		},
		NETWORK: {
			key: "network",
			value: "PARTICIPANT.NETWORK"
		}
	},
	loadChart: {
		ALL: {
			key: "all",
			value: "ALL"
		},
		FAIL: {
			key: "fail",
			value: "RESULT.STATE.FAIL"
		}
	},
	statistics: {
		AVG: {
			altKey: "average",
			key: "avg",
			value: "STATISTICS.AVG"
		},
		MIN: {
			altKey: "minimum",
			key: "min",
			value: "STATISTICS.MIN"
		},
		MAX: {
			altKey: "maximum",
			key: "max",
			value: "STATISTICS.MAX"
		},
		TOTAL: {
			altKey: "total",
			key: "total",
			value: "STATISTICS.TOTAL"
		},
		STDDEV: {
			altKey: "stddev",
			key: "std_dev",
			value: "STATISTICS.STDDEV"
		},
		RELATIVE: {
			altKey: "rstddev",
			key: "relative_std_dev",
			value: "STATISTICS.RELATIVE_STD"
		},
		PERC1: {
			altKey: "perc_1st",
			key: "perc_1",
			value: "STATISTICS.PERC_1"
		},
		PERC5: {
			altKey: "perc_5th",
			key: "perc_5",
			value: "STATISTICS.PERC_5"
		},
		PERC25: {
			altKey: "perc_25th",
			key: "perc_25",
			value: "STATISTICS.PERC_25"
		},
		PERC50: {
			altKey: "perc_50th",
			key: "perc_50",
			value: "STATISTICS.PERC_50"
		},
		PERC75: {
			altKey: "perc_75th",
			key: "perc_75",
			value: "STATISTICS.PERC_75"
		},
		PERC95: {
			altKey: "perc_95th",
			key: "perc_95",
			value: "STATISTICS.PERC_95"
		},
		PERC99: {
			altKey: "perc_99th",
			key: "perc_99",
			value: "STATISTICS.PERC_99"
		}
	}
};

const filterType = {
	AUDIO_FEED: "audio feed",
	BROWSER: "browser",
	COMPUTE_UNITS: "compute units",
	LOCATION: "location",
	MEDIA: "media",
	NAME: "name",
	NETWORK: "network",
	STATUS: "status",
	TEST_MODE: "testMode",
	VIDEO_FEED: "video feed"
};

const flowToggleOptions: FlowToggleOption[] = [
	{ label: "In", value: "in" },
	{ label: "Out", value: "out" }
];

const zeroTimeValue = "0001-01-01T00:00:00Z";

const iconNames = {
	ABORTED: "aborted",
	ACCOUNT: "account",
	CHART: "chart",
	CHECK: "check",
	LOAD: "load",
	LOGO: "logo",
	MOS_TEST: "mos-test",
	PASSWORD_HIDE: "password-hide",
	PERFORMANCE: "performance",
	RUNNING_TEST: "running-test",
	SCRIPT: "script",
	SEARCH: "search",
	SESSION_RECORD: "session-record",
	STOP: "stop",
	TEST_DONE: "test-done",
	TEST_ERROR: "test-error",
	WARNING: "warning"
};

const inputPlaceholders = {
	CARD_EXPIRY: "MM / YY",
	CARD_NUMBER: "xxxx xxxx xxxx xxxx",
	CVC: "xxx"
};

const invoiceStatus = {
	OPEN: "open",
	PAID: "paid"
};

const keyCodes = {
	ENTER: 13,
	ESC: 27,
	SPACE: 32,
	TAB: 9
};

const labelColors = {
	lightGray: "#e2e2e8",
	lightBlue: "#d0f7fb",
	lightGreen: "#d2f9e9",
	lightYellow: "#fcf2cf",
	lightRed: "#f9d2d8",
	lightPurple: "#e1d6f5",
	darkGray: "#c6c6d2",
	darkBlue: "#71e9f4",
	darkGreen: "#79ecbe",
	darkYellow: "#f7d76e",
	darkRed: "#f2a6b1",
	darkPurple: "#c4acec"
};

const limitedRegionsToolTip = [
	"AP South - Mumbai",
	"AP Southeast - Sydney",
	"EU Central - Frankfurt",
	"EU West - Ireland",
	"SA East - São Paulo",
	"US West - Oregon",
	"US East - North Virginia"
];

const mainNavTabs = {
	HIDDEN: 0,
	TESTS: 1,
	RUNS: 2,
	FAVORITES: 3,
	SETTINGS: 4
};

const maxCounterNumber = 50000;

const metrics = {
	aggregator: {
		PERCENT: "percent",
		RSTDDEV: "rstddev",
		TOTAL: "total"
	},
	metric: {
		BITRATE: "bitrate",
		BYTES: "bytes",
		CODEC: "codec",
		CONNECTIONS: "connections",
		CPU: "cpu",
		E_MODEL: "e-model",
		FPS: "fps",
		FRAME_HEIGHT: "frameHeight",
		FRAME_WIDTH: "frameWidth",
		JITTER: "jitter",
		JITTER_BUFFER: "jitterBuffer",
		LEVEL: "level",
		NETWORK: "network",
		PACKETS_LOST: "packetsLost",
		PACKETS: "packets",
		RAM: "ram",
		RTT: "rtt",
		TIME_EXECUTION: "timeExecution",
		TOTAL: "total"
	},
	type: {
		ACTIONS: "actions",
		MACHINE: "machine",
		MOS: "mos",
		WEBRTC: "webrtc"
	}
};

const modals = {
	ADD_MEMBER: "add-member",
	CREATE_EDIT_LABEL: "create-edit-label",
	CREATE_MOS: "create-mos",
	CREATE_TEST_WITHOUT_PARTICIPANTS: "create-test-without-participants",
	CREATE_TOKEN: "create-token",
	DELETE_ASSERTS: "delete-asserts",
	DELETE_PROJECT: "delete-project",
	DELETE_TOKEN: "delete-token",
	LABEL_CONFIRM_CLOSE_MODAL: "label-confirm-close-modal",
	PAYMENT_ADDRESS: "payment-address",
	PAYMENT_CARD: "payment-card",
	PAYMENT_COMPANY: "payment-company",
	PAYMENT_EMAIL: "payment-email",
	TEST_BUILDER: "test-builder",
	TEST_BUILDER_CONFIRM_CLOSE_MODAL: "test-builder-confirm-close-modal",
	TEST_BUILDER_CONFIRM_DELETE_ASSERT: "test-builder-confirm-delete-assert",
	TEST_BUILDER_CONFIRM_DELETE_GROUP: "test-builder-confirm-delete-group",
	TEST_BUILDER_CONFIRM_DELETE_PARTICIPANT:
		"test-builder-confirm-delete-participant",
	TEST_BUILDER_CONFIRM_DELETE_TEST: "test_builder_confirm_delete_test",
	TEST_BUILDER_DISCARD_ASSERT_CHANGES: "test_builder_discard_assert_changes",
	TEST_BUILDER_EXIT: "test-builder-exit",
	TEST_BUILDER_EXIT_TO_UPGRADE: "test-builder-exit-to-ugrade",
	TEST_BUILDER_GROUP: "test-builder-group",
	TEST_BUILDER_PARTICIPANT: "test-builder-participant",
	TEST_FORM: "test-form",
	TOKEN_INFO: "token-info"
};

const mosStatus = {
	AVAILABLE: "available",
	CALCULATING: "calculating",
	CALCULATION_ERROR: "calculation-error",
	CALCULATION_TIMEOUT: "calculation-timeout",
	NONE: "none"
};

const operator = {
	REGEX: "regex"
};

const operatorLabel = {
	EQ: "==",
	NEQ: "!=",
	REGEX: "Regex"
};

const pages = {
	APP: {
		HOME: "/",
		REGISTER: "register"
	},
	LANDING: {
		PRICING: "pricing",
		PRIVACY_POLICY: "privacy-policy",
		TERMS_AND_CONDITIONS: "terms-and-conditions"
	}
};

const paging = {
	ATTRIBUTES: {
		LOCATION: "location"
	},
	LOCATION: {
		GROUPS: "groups",
		PARTICIPANTS: "participants",
		PARTICIPANT_RESULTS: {
			ASSERTS: "participant-results-asserts"
		},
		REPORT: {
			ASSERTS: "report-asserts",
			PARTICIPANTS: "report-participants"
		},
		RUNS: "runs",
		TESTS: "tests"
	},
	PER_PAGE: 20
};

const participantStatus = {
	ABORTED: "aborted",
	FAIL: "fail",
	PASS: "pass",
	TIMEOUT: "timeout"
};

const payment = {
	flow: {
		TRIAL_UPGRADE: "trial-upgrade",
		WEB_SUBSCRIPTION: "web-subscription"
	},
	status: {
		CANCELLED: "cancelled",
		DECLINED: "declined",
		DRAFT: "draft",
		PROCESSING: "processing",
		SKIP_RENEWAL: "skip_renewal",
		SUCCESS: "success",
		VAT_INVALID: "vat_invalid"
	}
} as const;

const paymentPlans = {
	ENTERPRISE: "enterprise",
	ESSENTIAL: {
		name: "Essential (Pay as you go)",
		shortName: "essential",
		value: "single_basic"
	},
	MONTHLY: {
		maxCU: 10000,
		name: "Subscription (Monthly)",
		price: 399,
		shortName: "monthly",
		value: "monthly"
	},
	SUBSCRIPTION: "subscription",
	TRIAL: {
		maxCU: 100,
		minutes: 15,
		name: "Trial"
	},
	ULTIMATE: {
		name: "Ultimate (Pay as you go)",
		shortName: "ultimate",
		value: "single_pro"
	},
	YEARLY: {
		maxCU: 10000,
		name: "Subscription (Yearly)",
		price: 319.2,
		shortName: "yearly",
		value: "yearly"
	}
} as const;

const projectLanguage = {
	JAVA: {
		key: "java",
		name: "Java + TestUI",
		script: "public void testUIWithLoadero() {\n\n}",
		value: "PROJECT.LANGUAGE.JAVA"
	},
	JAVASCRIPT: {
		key: "javascript",
		name: "Javascript + Nightwatch",
		script: "client => {\n\t\n}",
		value: "PROJECT.LANGUAGE.JAVASCRIPT"
	},
	PYTHON: {
		key: "python",
		name: "Python + Py-TestUI",
		script: "def test_on_loadero(driver: TestUIDriver):\n    pass",
		value: "PROJECT.LANGUAGE.PYTHON"
	}
};

const queryKeys = {
	ADMIN: "admin",
	API_TOKEN: "api_token",
	AUTHORIZATION_CODE: "authorization_code",
	CODE: "code",
	DATE: {
		FROM: "from",
		TO: "to"
	},
	DESCRIBE: "describe",
	EMAIL: "email",
	FILTER: {
		ACTIVE_RUNS: "filter_active",
		BROWSER: "filter_browser",
		DATE_CREATED_FROM: "filter_created_from",
		DATE_CREATED_TO: "filter_created_to",
		DATE_FROM: "filter_started_from",
		DATE_TO: "filter_started_to",
		FINISHED: "filter_finished",
		LABEL: "filter_label_name",
		LOCATION: "filter_location",
		MEDIA: "filter_media",
		NAME: "filter_name",
		NETWORK: "filter_network",
		PATH: "filter_path",
		STATUS: "filter_status",
		TEST_MODE: "filter_test_mode",
		TEST_NAME: "filter_test_name"
	},
	LABEL: "label",
	LIMIT: "limit",
	OFFSET: "offset",
	ORDER_BY: "order_by",
	ORIGIN: "origin",
	PAGE: "page",
	PLAN: "plan",
	PREFIX: {
		ASSERTS: "asserts.",
		PARTICIPANTS: "participants."
	},
	PRESERVED: "preserved",
	RESET_PASSWORD: {
		EMAIL: "email",
		TOKEN: "token"
	},
	SEARCH: "search",
	TEST_MODE: "mode",
	TEST_IDS: "test_ids",
	TOKEN: "token",
	VERIFY: "verify"
};

const regexPatterns = {
	numbers: /^[0-9\b]+$/
};

const scopes = [
	"ACCOUNT_CREATE",
	"ACCOUNT_DELETE",
	"ACCOUNT_EMAIL_VERIFY",
	"ACCOUNT_READ",
	"ACCOUNT_UPDATE",
	"ACCOUNT_AVATAR_DELETE",
	"ACCOUNT_AVATAR_READ",
	"ACCOUNT_AVATAR_UPDATE",
	"ASSERTS_READ_ALL",
	"ASSERT_CREATE",
	"ASSERT_DELETE",
	"ASSERT_READ",
	"ASSERT_UPDATE",
	"AUTH_CHANGE_PASSWORD",
	"AWS_INFO_CREATE",
	"AWS_INFO_DELETE",
	"AWS_INFO_READ",
	"AWS_INFO_UPDATE",
	"BILLING_DELETE",
	"BILLING_READ",
	"BILLING_UPDATE",
	"FILES_READ_ALL",
	"FILE_CREATE",
	"FILE_DELETE",
	"FILE_READ",
	"FILE_UPDATE",
	"GROUPS_READ_ALL",
	"GROUP_CREATE",
	"GROUP_DELETE",
	"GROUP_READ",
	"GROUP_UPDATE",
	"INVITED_MEMBERS_READ_ALL",
	"INVITED_MEMBER_DELETE",
	"INVITED_MEMBER_READ",
	"INVITED_MEMBER_UPDATE",
	"LABELS_READ_ALL",
	"LABEL_CREATE",
	"LABEL_DELETE",
	"LABEL_READ",
	"LABEL_UPDATE",
	"MEMBERS_READ_ALL",
	"MEMBER_CREATE",
	"MEMBER_DELETE",
	"MEMBER_READ",
	"MEMBER_UPDATE",
	"PARTICIPANTS_READ_ALL",
	"PARTICIPANT_CREATE",
	"PARTICIPANT_DELETE",
	"PARTICIPANT_READ",
	"PARTICIPANT_UPDATE",
	"PRECONDITION_BULK_CREATE",
	"PRECONDITIONS_READ_ALL",
	"PRECONDITION_CREATE",
	"PRECONDITION_DELETE",
	"PRECONDITION_READ",
	"PRECONDITION_UPDATE",
	"PROJECTS_READ_ALL",
	"PROJECT_CREATE",
	"PROJECT_DELETE",
	"PROJECT_READ",
	"PROJECT_UPDATE",
	"PROJECT_AVATAR_DELETE",
	"PROJECT_AVATAR_READ",
	"PROJECT_AVATAR_UPDATE",
	"PROJECT_TOKEN_CREATE",
	"PROJECT_TOKEN_DELETE",
	"PROJECT_TOKENS_READ_ALL",
	"RESULTS_READ_ALL",
	"RESULT_READ",
	"RESULT_PRESIGN_GENERATE",
	"RESULT_STATISTICS_READ",
	"STATICS_READ_ALL",
	"STATIC_READ",
	"STATIC_TYPES_READ_ALL",
	"SUBSCRIPTION_CREATE",
	"SUBSCRIPTION_DELETE",
	"SUBSCRIPTION_READ",
	"SUBSCRIPTION_UPDATE",
	"TESTS_READ_ALL",
	"TEST_CREATE",
	"TEST_DELETE",
	"TEST_EXTENDED_CREATE",
	"TEST_EXTENDED_READ",
	"TEST_FAVORITE_CREATE",
	"TEST_FAVORITE_DELETE",
	"TEST_FAVORITES_READ_ALL",
	"TEST_LABEL_CREATE",
	"TEST_LABEL_BULK_CREATE",
	"TEST_LABELS_READ_ALL",
	"TEST_LABEL_DELETE",
	"TEST_READ",
	"TEST_METRIC_TRENDS_READ",
	"TEST_RUNS_READ_ALL",
	"TEST_RUN_CREATE",
	"TEST_RUN_PARTICIPANTS_READ_ALL",
	"TEST_RUN_PARTICIPANT_READ",
	"TEST_RUN_READ",
	"TEST_RUN_STOP",
	"TEST_SUCCESS_RATES_READ",
	"TEST_UPDATE"
];

const sorting = {
	ASCENDING: "asc",
	DESCENDING: "desc",
	LAST_RUN: "lastRun",
	LAST_UPDATED: "lastUpdated",
	NAME: "name",
	NEWEST: "newest",
	OLDEST: "oldest",
	RESET: "reset"
} as const;

const sortFields = {
	CREATED: "created",
	UPDATED: "updated",
	LAST_RUN: "last_run_date"
};

const sortOptions = [
	{ key: sorting.NEWEST, label: i18n.t("NEWEST") },
	{ key: sorting.OLDEST, label: i18n.t("OLDEST") },
	{ key: sorting.LAST_UPDATED, label: i18n.t("LAST_UPDATED") }
];

const stateColors = {
	PASS: "#36E29D",
	FAIL: "#E2364F",
	TIMEOUT: "#F3C425",
	ABORTED: "#B8B8C7"
};

const statics = {
	AUDIO_FEED: "audio_feed",
	BROWSER: "browser",
	COMPUTE_UNIT: "compute_unit",
	INCREMENT_STRATEGY: "increment_strategy",
	LOCATION: "location",
	METRIC_PATH: "metric_path",
	NETWORK: "network",
	OPERATOR: "operator",
	PROPERTY: "property",
	STATUS: "status",
	TEST_MODE: "test_mode",
	VIDEO_FEED: "video_feed"
} as const;

const staticsValue = {
	CUSTOM: "custom",
	DEFAULT: "default",
	G05: "g0.5",
	G1: "g1",
	G2: "g2",
	G4: "g4",
	G6: "g6",
	G8: "g8",
	G12: "g12",
	US_WEST_2: "us-west-2"
};

const subscriptionType = {
	ENTERPRISE: "enterprise",
	MONTHLY: "monthly",
	SINGLE_BASIC: "single_basic",
	SINGLE_PRO: "single_pro",
	YEARLY: "yearly"
};

const taxCodes = [
	{
		label: "None",
		key: ""
	},
	{
		label: "AD NRT",
		key: "ad_nrt"
	},
	{
		label: "AE TRN",
		key: "ae_trn"
	},
	{
		label: "AR CUIT",
		key: "ar_cuit"
	},
	{
		label: "AU ABN",
		key: "au_abn"
	},
	{
		label: "AU ARN",
		key: "au_arn"
	},
	{
		label: "BG UIC",
		key: "bg_uic"
	},
	{
		label: "BH VAT",
		key: "bh_vat"
	},
	{
		label: "BO TIN",
		key: "bo_tin"
	},
	{
		label: "BR CNPJ",
		key: "br_cnpj"
	},
	{
		label: "BR CPF",
		key: "br_cpf"
	},
	{
		label: "BY TIN",
		key: "by_tin"
	},
	{
		label: "CA BN",
		key: "ca_bn"
	},
	{
		label: "CA GST HST",
		key: "ca_gst_hst"
	},
	{
		label: "CA PST BC",
		key: "ca_pst_bc"
	},
	{
		label: "CA PST MB",
		key: "ca_pst_mb"
	},
	{
		label: "CA PST SK",
		key: "ca_pst_sk"
	},
	{
		label: "CA QST",
		key: "ca_qst"
	},
	{
		label: "CH UID",
		key: "ch_uid"
	},
	{
		label: "CH VAT",
		key: "ch_vat"
	},
	{
		label: "CL TIN",
		key: "cl_tin"
	},
	{
		label: "CN TIN",
		key: "cn_tin"
	},
	{
		label: "CO NIT",
		key: "co_nit"
	},
	{
		label: "CR TIN",
		key: "cr_tin"
	},
	{
		label: "DE STN",
		key: "de_stn"
	},
	{
		label: "DO RCN",
		key: "do_rcn"
	},
	{
		label: "EC RUC",
		key: "ec_ruc"
	},
	{
		label: "EG TIN",
		key: "eg_tin"
	},
	{
		label: "ES CIF",
		key: "es_cif"
	},
	{
		label: "EU OSS VAT",
		key: "eu_oss_vat"
	},
	{
		label: "EU VAT",
		key: "eu_vat"
	},
	{
		label: "GB VAT",
		key: "gb_vat"
	},
	{
		label: "GE VAT",
		key: "ge_vat"
	},
	{
		label: "HK BR",
		key: "hk_br"
	},
	{
		label: "HR OIB",
		key: "hr_oib"
	},
	{
		label: "HU TIN",
		key: "hu_tin"
	},
	{
		label: "ID NPWP",
		key: "id_npwp"
	},
	{
		label: "IL VAT",
		key: "il_vat"
	},
	{
		label: "IN GST",
		key: "in_gst"
	},
	{
		label: "IS VAT",
		key: "is_vat"
	},
	{
		label: "JP CN",
		key: "jp_cn"
	},
	{
		label: "JP RN",
		key: "jp_rn"
	},
	{
		label: "JP TRN",
		key: "jp_trn"
	},
	{
		label: "KE PIN",
		key: "ke_pin"
	},
	{
		label: "KR BRN",
		key: "kr_brn"
	},
	{
		label: "KZ BIN",
		key: "kz_bin"
	},
	{
		label: "LI UID",
		key: "li_uid"
	},
	{
		label: "MA VAT",
		key: "ma_vat"
	},
	{
		label: "MD VAT",
		key: "md_vat"
	},
	{
		label: "MX RFC",
		key: "mx_rfc"
	},
	{
		label: "MY FRP",
		key: "my_frp"
	},
	{
		label: "MY ITN",
		key: "my_itn"
	},
	{
		label: "MY SST",
		key: "my_sst"
	},
	{
		label: "NG TIN",
		key: "ng_tin"
	},
	{
		label: "NO VAT",
		key: "no_vat"
	},
	{
		label: "NO VOEC",
		key: "no_voec"
	},
	{
		label: "NZ GST",
		key: "nz_gst"
	},
	{
		label: "OM VAT",
		key: "om_vat"
	},
	{
		label: "PE RUC",
		key: "pe_ruc"
	},
	{
		label: "PH TIN",
		key: "ph_tin"
	},
	{
		label: "RO TIN",
		key: "ro_tin"
	},
	{
		label: "RS PIB",
		key: "rs_pib"
	},
	{
		label: "RU INN",
		key: "ru_inn"
	},
	{
		label: "RU KPP",
		key: "ru_kpp"
	},
	{
		label: "SA VAT",
		key: "sa_vat"
	},
	{
		label: "SG GST",
		key: "sg_gst"
	},
	{
		label: "SG UEN",
		key: "sg_uen"
	},
	{
		label: "SI TIN",
		key: "si_tin"
	},
	{
		label: "SV NIT",
		key: "sv_nit"
	},
	{
		label: "TH VAT",
		key: "th_vat"
	},
	{
		label: "TR TIN",
		key: "tr_tin"
	},
	{
		label: "TW VAT",
		key: "tw_vat"
	},
	{
		label: "TZ VAT",
		key: "tz_vat"
	},
	{
		label: "UA VAT",
		key: "ua_vat"
	},
	{
		label: "US EIN",
		key: "us_ein"
	},
	{
		label: "UY RUC",
		key: "uy_ruc"
	},
	{
		label: "UZ TIN",
		key: "uz_tin"
	},
	{
		label: "UZ VAT",
		key: "uz_vat"
	},
	{
		label: "VE RIF",
		key: "ve_rif"
	},
	{
		label: "VN TIN",
		key: "vn_tin"
	},
	{
		label: "ZA VAT",
		key: "za_vat"
	}
];

const testConsts = {
	MODE: {
		ALL: {
			key: "all",
			value: "ALL"
		},
		LOAD: {
			key: "load",
			value: "DETAILS.LOAD"
		},
		MOS: {
			key: "mos",
			max: 200,
			value: "DETAILS.MOS"
		},
		PERFORMANCE: {
			key: "performance",
			max: 200,
			value: "DETAILS.PERFORMANCE"
		},
		SESSION_RECORD: {
			key: "session-record",
			max: 10,
			value: "DETAILS.SESSION_RECORD"
		}
	},
	STRATEGY: {
		LINEAR: "linear",
		LINEAR_GROUP: "linear_group",
		RANDOM: "random",
		RANDOM_GROUP: "random_group"
	}
};

const testModeOptions = [
	{
		key: "all",
		label: "All"
	},
	{
		key: "load",
		label: "Load"
	},
	{
		key: "performance",
		label: "Performance"
	},
	{
		key: "session-record",
		label: "Session Recording"
	}
];

const testSortOptions = [
	{
		key: "newest",
		label: "Newest"
	},
	{
		key: "lastRun",
		label: "Last run"
	},
	{
		key: "lastUpdated",
		label: "Last updated"
	}
];

const testBuilder = {
	maxParticipantsInGroup: 5,
	limitedFeatures: {
		sessionRecordingAccess: "session_recording_access"
	} as const,
	steps: {
		ASSERTS: "asserts",
		DETAILS: "details",
		PARTICIPANTS: "participants",
		SCRIPT: "script"
	} as const
};

const testStates = {
	ABORTED: "aborted",
	DONE: "done",
	FAIL: "fail",
	INITIALIZING: "initializing",
	INITIALIZING_ENVIRONMENT: "Initializing Environment",
	PASS: "pass",
	PENDING: "pending",
	RUNNING: "running",
	SERVER_ERROR: "server-error",
	SKIPPED: "skipped",
	STOPPING: "stopping",
	TIMEOUT: "timeout",
	WAITING_RESULTS: "waiting-results",
	WAITING_FOR_RESULTS: "Waiting for results"
};

const toastAlert = {
	AUTO_CLOSE_TIME: 6000
};

const unitSymbol = {
	KB: "KB",
	KBT: "kbit",
	KBT_SEC: "kbit/sec",
	MB: "MB",
	MS: "ms",
	PER_SEC: "/sec",
	PERCENT: "%",
	PX: "px"
};

const wikiLinks = {
	API: `${app.WIKI}/docs/loadero-usage/api/direct-api-access/`,
	ASSERTS: `${app.WIKI}/docs/test-creation/asserts/asserts`,
	BASE: app.WIKI,
	COMPUTE_UNITS: `${app.WIKI}/docs/test-creation/participant-configuration/compute-units`,
	GROUP: `${app.WIKI}/docs/test-creation/participant-configuration/participant-groups`,
	MEMBER_ROLES: `${app.WIKI}/docs/loadero-usage/member-permissions`,
	PARTICIPANT: `${app.WIKI}/docs/category/participant-configuration`,
	PRECONDITIONS: `${app.WIKI}/docs/test-creation/asserts/preconditions`,
	SCRIPT: `${app.WIKI}/docs/script-development/script-development`,
	TEST_CREATION: `${app.WIKI}/docs/category/test-creation`,
	TEST_CREATION_ASSERTS: `${app.WIKI}/docs/category/post-run-assertions/`,
	TEST_CREATION_PARAMETERS: `${app.WIKI}/docs/test-creation/parameters/`,
	TEST_CREATION_SCRIPT: `${app.WIKI}/docs/test-creation/script/`,
	UPDATES: `${app.WIKI}/docs/whats-new/`
};

export {
	accountRoles,
	accountRolesOptions,
	allRegionsToolTip,
	app,
	artifacts,
	audioFeed,
	authKeys,
	blogLinks,
	browser,
	browserActions,
	buyerKind,
	cards,
	codeEditor,
	confirmTypes,
	cookies,
	cookiesDefault,
	cookiesSwitchers,
	contactSources,
	copyPrefix,
	countries,
	cssClasses,
	currencySymbols,
	dataDumpType,
	dateTimeFormat,
	defaultConfirmModal,
	downloadWrapperType,
	emailConfig,
	env,
	errorMessages,
	EUCountries,
	filters,
	filterType,
	flowToggleOptions,
	iconNames,
	inputPlaceholders,
	invoiceStatus,
	keyCodes,
	labelColors,
	limitedRegionsToolTip,
	mainNavTabs,
	maxCounterNumber,
	metrics,
	modals,
	mosStatus,
	operator,
	operatorLabel,
	pages,
	paging,
	participantStatus,
	payment,
	paymentPlans,
	projectLanguage,
	queryKeys,
	regexPatterns,
	scopes,
	sortFields,
	sorting,
	sortOptions,
	stateColors,
	statics,
	staticsValue,
	subscriptionType,
	taxCodes,
	testBuilder,
	testConsts,
	testModeOptions,
	testSortOptions,
	testStates,
	toastAlert,
	unitSymbol,
	wikiLinks,
	zeroTimeValue
};
