import type { AxiosResponse } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { store as reduxStore, dispatch } from "../redux/store/configureStore";
import { crud } from "../api/auth/api";

import {
	removeValueFromStorage,
	setValueToStorage
} from "../redux/slices/persistedStorage";

import {
	addRequestToRequestQueue,
	emptyRequestQueue,
	RequestQueueElement,
	setAccessTokenIsRefreshing
} from "../redux/slices/session";

import { routes } from "../router/routes";

import {
	APIRequest,
	APIRequestArgs,
	APIError,
	AuthorizeAccountData
} from "../types";

import { authKeys, scopes } from "./const";
import { arraysEqual } from "./method";
import { createRoute } from "./request";

const deleteSession = (): void => {
	Object.keys(authKeys).forEach(key => {
		dispatch(removeValueFromStorage(authKeys[key]));
	});
};

const setSession = (
	authResult: AuthorizeAccountData,
	expires_at?: string
): void => {
	const expiresAt = expires_at
		? expires_at
		: JSON.stringify(authResult.expires_in * 1000 + new Date().getTime());

	const { ACCESS_TOKEN, EXPIRES_AT, TOKEN_TYPE, REFRESH_TOKEN, SCOPES } =
		authKeys;
	const { access_token, refresh_token, token_type } = authResult;

	dispatch(setValueToStorage({ name: ACCESS_TOKEN, value: access_token }));
	dispatch(setValueToStorage({ name: REFRESH_TOKEN, value: refresh_token }));
	dispatch(setValueToStorage({ name: EXPIRES_AT, value: expiresAt }));
	dispatch(setValueToStorage({ name: TOKEN_TYPE, value: token_type }));
	dispatch(setValueToStorage({ name: SCOPES, value: authResult.scopes }));
};

const refreshToken = (
	navigate: NavigateFunction,
	retryAttempt = 0
): Promise<void> => {
	const route = createRoute(false, routes.AUTH_TOKEN);

	const { refresh_token_pending, refresh_token_resolved, scopes } =
		reduxStore.getState().persistedStorage;

	const data = new FormData();

	return new Promise<void>((resolve, reject) => {
		if (refresh_token_pending === true) {
			if (refresh_token_resolved === false) {
				if (retryAttempt >= 4) {
					return reject();
				}

				return setTimeout(async () => {
					await refreshToken(navigate, retryAttempt + 1).then(
						() => {
							return resolve();
						},
						() => {
							deleteSession();
							return reject();
						}
					);
				}, 1500);
			}

			return resolve();
		}

		if (!scopes || scopes.length === 0) {
			deleteSession();
			return reject();
		}

		data.append(authKeys.GRANT_TYPE, authKeys.REFRESH_TOKEN);
		data.append(
			authKeys.REFRESH_TOKEN,
			reduxStore.getState().persistedStorage.refresh_token
		);
		data.append(authKeys.SCOPE, scopes.join(" "));

		dispatch(
			setValueToStorage({ name: authKeys.REFRESH_TOKEN_PENDING, value: true })
		);
		dispatch(
			setValueToStorage({ name: authKeys.REFRESH_TOKEN_RESOLVED, value: false })
		);

		crud
			.POST<any>(route, data)
			.then(
				response => {
					if (arraysEqual(response.data.scopes, scopes)) {
						setSession(response.data);

						return resolve();
					}

					if (navigate) navigate(routes.LOG_OUT);

					reject();
				},
				() => {
					deleteSession();
					reject();
				}
			)
			.then(() => {
				dispatch(
					setValueToStorage({
						name: authKeys.REFRESH_TOKEN_PENDING,
						value: false
					})
				);
				dispatch(
					setValueToStorage({
						name: authKeys.REFRESH_TOKEN_RESOLVED,
						value: true
					})
				);
			});
	});
};

const validSession = (navigate: NavigateFunction): Promise<boolean> => {
	const expiresAt = parseInt(
		reduxStore.getState().persistedStorage.expires_at || "0",
		10
	);

	return new Promise(resolve => {
		if (new Date().getTime() > expiresAt) {
			refreshToken(navigate).then(
				() => {
					const expiresAt = parseInt(
						reduxStore.getState().persistedStorage.expires_at || "0",
						10
					);

					resolve(new Date().getTime() < expiresAt);
				},
				() => {
					resolve(false);
				}
			);
		} else {
			resolve(true);
		}
	});
};

const checkAuthStatus = (navigate: NavigateFunction) =>
	new Promise<void>((resolve, reject) => {
		validSession(navigate).then(authState => {
			const authScopes = reduxStore.getState().persistedStorage.scopes;

			if (
				authState &&
				Array.isArray(authScopes) &&
				arraysEqual(scopes, authScopes)
			) {
				resolve();
				return;
			}

			if (navigate) {
				navigate(routes.LOG_OUT);
			}

			reject();
		});
	});

const retryUnauthorizedRequests = (
	navigate: NavigateFunction,
	request: APIRequest,
	requestOptions: APIRequestArgs,
	retryAttempt = 5
): Promise<AxiosResponse> => {
	return new Promise((resolve, reject) => {
		request(...requestOptions).then(
			(response: AxiosResponse) => {
				resolve(response);
			},
			(error: APIError) => {
				if (error?.response?.status !== 401) return reject(error);

				if (retryAttempt === 0) {
					return reject(error);
				}

				dispatch(
					addRequestToRequestQueue({
						request: options => {
							retryUnauthorizedRequests(
								navigate,
								request,
								options,
								retryAttempt - 1
							).then(
								resp => {
									resolve(resp);
								},
								err => {
									dispatch(setAccessTokenIsRefreshing(false));
									reject(err);
								}
							);
						},
						requestOptions
					})
				);

				if (reduxStore.getState().session.tokenIsRefreshing) return;

				dispatch(setAccessTokenIsRefreshing(true));

				refreshToken(navigate).then(
					() => {
						processRequestQueue();
					},
					() => {
						dispatch(setAccessTokenIsRefreshing(false));
						reject(error);
					}
				);
			}
		);
	});
};

const updatedOptions = (options: APIRequestArgs) => {
	return options.map(option => {
		if (typeof option === "object" && option?.headers?.Authorization) {
			const updatedOption = {
				...option,
				headers: {
					...option.headers,
					Authorization: `Bearer ${reduxStore.getState().persistedStorage.access_token}`
				}
			};
			return updatedOption;
		}

		return option;
	});
};

const processRequestQueue = (): void => {
	const queue = [...reduxStore.getState().session.requestQueue];

	while (queue.length > 0) {
		const { request, requestOptions } = queue.shift() as RequestQueueElement;
		if (request) {
			request(updatedOptions(requestOptions) as APIRequestArgs);
		}
	}

	dispatch(setAccessTokenIsRefreshing(false));
	dispatch(emptyRequestQueue());
};

export {
	checkAuthStatus,
	deleteSession,
	retryUnauthorizedRequests,
	setSession,
	validSession
};
