import { FC, RefObject, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Button, Switch } from "ui-kit";

import { config, getCrossDomain } from "../../config/config";

import { AppDispatch } from "../../redux/store/configureStore";
import { hideCookiesManageBanner } from "../../redux/slices";

import { updateGTM } from "../../services/googleTag";

import { Cookies } from "../../types";

import { cookies, cookiesDefault, env } from "../../utils/const";

const cookieName =
	config.env === env.PROD
		? cookies.CONSENT
		: `${cookies.CONSENT}_${config.env}`;

const CookiesManageBanner: FC = (): JSX.Element | null => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	const modalInner: RefObject<HTMLDivElement> = useRef(null);

	const [showBg, setShowBg] = useState<boolean>(true);

	const [cookie, setCookie] = useCookies();

	const [cookieData, setCookieData] = useState<Cookies>(
		cookie[cookieName] ? { ...cookie[cookieName] } : { ...cookiesDefault }
	);

	const handleSwitch = (name: string, val: boolean): void => {
		let editedCookies = {};

		switch (name) {
			case cookies.PERSONALIZATION_STORAGE:
				editedCookies = {
					[cookies.PERSONALIZATION_STORAGE]: val,
					[cookies.FUNCTIONALITY_STORAGE]: val
				};

				break;
			case cookies.ANALYTICS_STORAGE:
				editedCookies = {
					[cookies.AD_PERSONALIZATION]: val,
					[cookies.AD_STORAGE]: val,
					[cookies.AD_USER_DATA]: val,
					[cookies.ANALYTICS_STORAGE]: val
				};

				break;
		}

		setCookieData({ ...cookieData, ...editedCookies });
	};

	const handleSubmit = (): void => {
		setCookie(
			cookieName,
			{ ...cookieData },
			{
				path: "/",
				domain: getCrossDomain()
			}
		);

		updateGTM(cookieData);

		dispatch(hideCookiesManageBanner());
	};

	useEffect(() => {
		const modal = modalInner.current;

		const handleScroll = (): void => {
			const modal = modalInner.current;

			if (
				modal &&
				modal.scrollHeight - modal.offsetHeight - modal.scrollTop > 20
			) {
				setShowBg(true);

				return;
			}

			setShowBg(false);
		};

		modal?.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", handleScroll);

		return () => {
			modal?.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleScroll);
		};
	}, []);

	return (
		<div className="c-manage-banner">
			<div className="c-manage-banner__title">{t("COOKIES_BANNER.TITLE")}</div>

			<div ref={modalInner} className="c-manage-banner__switchers-wrapper">
				<div className="c-manage-banner__switchers">
					<div className="c-manage-banner__block">
						<div className="c-manage-banner__block-top">
							<div className="c-manage-banner__block-label">
								{t("COOKIES_BANNER.SWITCH_1.LABEL")}

								<span>{t("COOKIES_BANNER.SWITCH_1.LABEL_SPAN")}</span>
							</div>

							<Switch
								name="security_storage"
								disabled
								selected={true}
								onChange={() => {}}
							/>
						</div>

						<div className="c-manage-banner__block-note">
							{t("COOKIES_BANNER.SWITCH_1.NOTE")}
						</div>
					</div>

					<div className="c-manage-banner__block">
						<div className="c-manage-banner__block-top">
							<div className="c-manage-banner__block-label">
								{t("COOKIES_BANNER.SWITCH_2.LABEL")}
							</div>

							<Switch
								name="personalization_storage"
								selected={cookieData.personalization_storage}
								onChange={handleSwitch}
							/>
						</div>

						<div className="c-manage-banner__block-note">
							{t("COOKIES_BANNER.SWITCH_2.NOTE")}
						</div>
					</div>

					<div className="c-manage-banner__block">
						<div className="c-manage-banner__block-top">
							<div className="c-manage-banner__block-label">
								{t("COOKIES_BANNER.SWITCH_3.LABEL")}
							</div>

							<Switch
								name="analytics_storage"
								selected={cookieData.analytics_storage}
								onChange={handleSwitch}
							/>
						</div>

						<div className="c-manage-banner__block-note">
							{t("COOKIES_BANNER.SWITCH_3.NOTE")}
						</div>
					</div>
				</div>
			</div>

			{showBg && <div className="c-manage-banner__switchers-bg" />}

			<Button
				className="c-manage-banner__button"
				size="sm"
				onClick={handleSubmit}
			>
				{t("COOKIES_BANNER.BUTTON_MANAGE")}
			</Button>
		</div>
	);
};

export default CookiesManageBanner;
