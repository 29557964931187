import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	AssertDTO,
	AssertParams,
	InternalAssert,
	QueryParams,
	RequestOptions,
	ResponseWithPaging
} from "../../types";

import { queryKeys as qk } from "../../utils/const";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createAssert = (
	navigate: NavigateFunction,
	params: AssertParams,
	data: Omit<InternalAssert, "preconditions" | "id">,
	options: RequestOptions
): Promise<AssertDTO | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS
	);

	return crud.CREATE<AssertDTO>(navigate, route, data, options);
};

const deleteAssert = (
	navigate: NavigateFunction,
	params: Required<AssertParams>,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { assertID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID
	);

	return crud.DELETE(navigate, route, options as RequestOptions);
};

const getAllAsserts = (
	navigate: NavigateFunction,
	params: AssertParams,
	queryParams: QueryParams,
	options?: RequestOptions
): Promise<ResponseWithPaging<InternalAssert> | {} | APIError> => {
	const { projectID, testID } = params;
	const { filters, pagination, sorts } = queryParams;
	const { limit, page } = pagination || {};

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS
	);

	route += createQuery(
		filters && filters.path ? setQueryParam(qk.FILTER.PATH, filters.path) : {},
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page))
	);

	// TODO: improve the sorting logic
	const sortsQuery = sorts
		? sorts.map(sort =>
				createQuery(setQueryParam(qk.ORDER_BY, sort.orderBy))
			)[0]
		: "";

	route += sortsQuery.replace("?", "&");

	return crud.READ<ResponseWithPaging<AssertDTO>>(
		navigate,
		route,
		options || {}
	);
};

const updateAssert = (
	navigate: NavigateFunction,
	params: Required<AssertParams>,
	data: Omit<InternalAssert, "preconditions" | "id">,
	options: RequestOptions
): Promise<AssertDTO | {} | APIError> => {
	const { assertID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID
	);

	return crud.UPDATE<AssertDTO>(navigate, route, data, options);
};

const copyAssert = (
	navigate: NavigateFunction,
	params: Required<AssertParams>,
	options: RequestOptions
): Promise<AssertDTO | {} | APIError> => {
	const { assertID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.COPY
	);

	return crud.CREATE<AssertDTO>(navigate, route, options || {});
};

const assertAPI = {
	create: createAssert,
	delete: deleteAssert,
	readAll: getAllAsserts,
	update: updateAssert,
	copy: copyAssert
};

export default assertAPI;
