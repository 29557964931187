import type { AxiosResponse } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { APIRequest, CRUDOptions, RequestOptions } from "../../types";

import { getRequestConfig } from "../../utils/request";
import {
	checkAuthStatus,
	retryUnauthorizedRequests
} from "../../utils/session";

import { mediaAPIRest } from "./config";

const DELETE = <T>(
	navigate: NavigateFunction,
	route: string,
	options?: RequestOptions | {}
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(
						navigate,
						mediaAPIRest.delete as APIRequest,
						[route, config]
					)
				);
			},
			() => {
				reject();
			}
		);
	});

const GET = <T>(
	navigate: NavigateFunction,
	route: string,
	options: CRUDOptions | RequestOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = { ...options, ...getRequestConfig(options) };

				resolve(
					retryUnauthorizedRequests(navigate, mediaAPIRest.get as APIRequest, [
						route,
						config
					])
				);
			},
			() => {
				reject();
			}
		);
	});

const POST = <T>(
	navigate: NavigateFunction,
	route: string,
	data: FormData | {},
	options: CRUDOptions | RequestOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(navigate, mediaAPIRest.post, [
						route,
						data,
						config
					])
				);
			},
			() => {
				reject();
			}
		);
	});

const PUT = <T>(
	navigate: NavigateFunction,
	route: string,
	data: FormData | {},
	options: CRUDOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(navigate, mediaAPIRest.put, [
						route,
						data,
						config
					])
				);
			},
			() => {
				reject();
			}
		);
	});

const mediaProxy = {
	DELETE,
	GET,
	POST,
	PUT
};

export { mediaProxy };
