import type { ThunkDispatch } from "redux-thunk";

import { PaymentsAction } from "../actions/payments";

import { IStoreState } from "../reducers";

export enum ActionTypes {
	// UI
	CheckAuthStatus = "CHECK_AUTH_STATUS",
	ClearPage = "CLEAR_PAGE",
	ClearPageInfo = "CLEAR_PAGE_INFO",
	ClearUIData = "CLEAR_UI_DATA",
	InitializeLoader = "INITIALIZE_LOADER",
	PersistPage = "PERSIST_PAGE",
	SetLastOpenedPage = "SET_LAST_OPENED_PAGE",
	SetLoaderInactive = "SET_LOADER_INACTIVE",
	SetMOSLoader = "SET_MOS_LOADER",

	// Statics
	FetchStaticsFailure = "FETCH_STATICS_FAILURE",
	FetchStaticsRequest = "FETCH_STATICS_REQUEST",
	FetchStaticsSuccess = "FETCH_STATICS_SUCCESS",

	FetchAllStaticsFailure = "FETCH_ALL_STATICS_FAILURE",
	FetchAllStaticsRequest = "FETCH_ALL_STATICS_REQUEST",
	FetchAllStaticsSuccess = "FETCH_ALL_STATICS_SUCCESS",

	// Account
	ClearAccountData = "CLEAR_ACCOUNT_DATA",
	FetchAccountDataRequest = "FETCH_ACCOUNT_DATA_REQUEST",
	FetchAccountDataSuccess = "FETCH_ACCOUNT_DATA_SUCCESS",
	FetchAccountDataFailure = "FETCH_ACCOUNT_DATA_FAILURE",

	FetchAvatarRequest = "FETCH_AVATAR_REQUEST",
	FetchAvatarSuccess = "FETCH_AVATAR_SUCCESS",
	FetchAvatarFailure = "FETCH_AVATAR_FAILURE",
	FetchAvatarForProject = "FETCH_AVATAR_FOR_PROJECT",
	FetchAvatarForProjectRequest = "FETCH_AVATAR_FOR_PROJECT_REQUEST",
	SetProjectAvatarBlob = "SET_PROJECT_AVATAR_BLOB",

	SetRole = "SET_ROLE",

	// Api access
	FetchTokensRequest = "FETCH_TOKENS_REQUEST",
	FetchTokensSuccess = "FETCH_TOKENS_SUCCESS",
	FetchTokensFailure = "FETCH_TOKENS_FAILURE",

	// Projects
	FetchProjectsRequest = "FETCH_PROJECTS_REQUEST",
	FetchProjectsSuccess = "FETCH_PROJECTS_SUCCESS",
	FetchProjectsFailure = "FETCH_PROJECTS_FAILURE",

	SetActiveProject = "SET_ACTIVE_PROJECT",
	ClearProjectData = "CLEAR_PROJECT_DATA",

	PlanLimitsAndUsageRequest = "PLAN_LIMITS_AND_USAGE_REQUEST",
	PlanLimitsAndUsageSuccess = "PLAN_LIMITS_AND_USAGE_SUCCESS",
	PlanLimitsAndUsageFailure = "PLAN_LIMITS_AND_USAGE_FAILURE",

	ClearCUInfo = "CLEAR_CU_INFO",

	// Payments
	PaymentGoNext = "PAYMENT_GO_NEXT",
	PaymentGoBack = "PAYMENT_GO_BACK",
	PaymentGoToStep = "PAYMENT_GO_TO_STEP",
	PaymentSetSteps = "PAYMENTS_SET_STEPS",
	PaymentClearData = "PAYMENT_CLEAR_DATA",
	PaymentClearBilling = "PAYMENT_CLEAR_BILLING",
	PaymentSetFlow = "PAYMENT_SET_FLOW",
	PaymentSetPlan = "PAYMENT_SET_PLAN",
	PaymentSetTaxable = "PAYMENT_SET_TAXABLE",

	CreateSubscriptionRequest = "CREATE_SUBSCRIPTION_REQUEST",
	CreateSubscriptionSuccess = "CREATE_SUBSCRIPTION_SUCCESS",
	CreateSubscriptionFailure = "CREATE_SUBSCRIPTION_FAILURE",

	FetchSubscriptionRequest = "FETCH_SUBSCRIPTION_REQUEST",
	FetchSubscriptionSuccess = "FETCH_SUBSCRIPTION_SUCCESS",
	FetchSubscriptionFailure = "FETCH_SUBSCRIPTION_FAILURE",

	UpdateSubscriptionRequest = "UPDATE_SUBSCRIPTION_REQUEST",
	UpdateSubscriptionSuccess = "UPDATE_SUBSCRIPTION_SUCCESS",
	UpdateSubscriptionFailure = "UPDATE_SUBSCRIPTION_FAILURE",

	CancelSubscriptionRequest = "CANCEL_SUBSCRIPTION_REQUEST",
	CancelSubscriptionSuccess = "CANCEL_SUBSCRIPTION_SUCCESS",
	CancelSubscriptionFailure = "CANCEL_SUBSCRIPTION_FAILURE",

	RemoveCardRequest = "REMOVE_CARD_REQUEST",
	RemoveCardSuccess = "REMOVE_CARD_SUCCESS",
	RemoveCardFailure = "REMOVE_CARD_FAILURE",

	UpdateBillingRequest = "UPDATE_BILLING_REQUEST",
	UpdateBillingSuccess = "UPDATE_BILLING_SUCCESS",
	UpdateBillingFailure = "UPDATE_BILLING_FAILURE",

	FetchBillingRequest = "FETCH_BILLING_REQUEST",
	FetchBillingSuccess = "FETCH_BILLING_SUCCESS",
	FetchBillingFailure = "FETCH_BILLING_FAILURE",

	ActivateSubscriptionRequest = "ACTIVATE_SUBSCRIPTION_REQUEST",
	ActivateSubscriptionSuccess = "ACTIVATE_SUBSCRIPTION_SUCCESS",
	ActivateSubscriptionFailure = "ACTIVATE_SUBSCRIPTION_FAILURE",

	DeleteCompanyRequest = "DELETE_COMPANY_REQUEST",
	DeleteCompanySuccess = "DELETE_COMPANY_SUCCESS",
	DeleteCompanyFailure = "DELETE_COMPANY_FAILURE",

	DeleteVATRequest = "DELETE_VAT_REQUEST",
	DeleteVATSuccess = "DELETE_VAT_SUCCESS",
	DeleteVATFailure = "DELETE_VAT_FAILURE",

	// Data dump persistence
	PersistDump = "PERSIST_DUMP",
	ClearDumps = "CLEAR_DUMPS",
	SetPreviewFileData = "SET_PREVIEW_FILE_DATA",

	// Modal
	HideAlertModal = "HIDE_ALERT_MODAL",
	HideConfirmModal = "HIDE_CONFIRM_MODAL",
	HideContactModal = "HIDE_CONTACT_MODAL",
	HideCookiesManageBanner = "HIDE_COOKIES_MANAGE_BANNER",
	HideModal = "HIDE_MODAL",
	HideScriptPreviewModal = "HIDE_SCRIPT_PREVIEW_MODAL",
	HideSuccessModal = "HIDE_SUCCESS_MODAL",

	ShowAlertModal = "SHOW_ALERT_MODAL",
	ShowConfirmModal = "SHOW_CONFIRM_MODAL",
	ShowContactModal = "SHOW_CONTACT_MODAL",
	ShowCookiesManageBanner = "SHOW_COOKIES_MANAGE_BANNER",
	ShowModal = "SHOW_MODAL",
	ShowScriptPreviewModal = "SHOW_SCRIPT_PREVIEW_MODAL",
	ShowSuccessModal = "SHOW_SUCCESS_MODAL",

	// Active runs
	FetchActiveRunsRequest = "FETCH_ACTIVE_RUNS_REQUEST",
	FetchActiveRunsSuccess = "FETCH_ACTIVE_RUNS_SUCCESS",
	FetchActiveRunsFailure = "FETCH_ACTIVE_RUNS_FAILURE",

	AddRunRequest = "ADD_RUN_REQUEST",
	AddRunSuccess = "ADD_RUN_SUCCESS",
	AddRunFailure = "ADD_RUN_FAILURE",

	SetRuns = "SET_RUNS",
	SetLoaded = "SET_LOADED",

	StopRunRequest = "STOP_RUN_REQUEST",
	StopRunSuccess = "STOP_RUN_SUCCESS",
	StopRunFailure = "STOP_RUN_FAILURE",

	ClearActiveRunsData = "CLEAR_ACTIVE_RUNS_DATA",

	// Test builder
	ClearTestBuilderData = "CLEAR_TEST_BUILDER_DATA",
	TestBuilderCreateTestRequest = "TEST_BUILDER_CREATE_TEST_REQUEST",
	TestBuilderCreateTestSuccess = "TEST_BUILDER_CREATE_TEST_SUCCESS",
	TestBuilderCreateTestFailure = "TEST_BUILDER_CREATE_TEST_FAILURE",
	TestBuilderGoBack = "TEST_BUILDER_GO_TO_PREVIOUS_STEP",
	TestBuilderGoNext = "TEST_BUILDER_GO_TO_NEXT_STEP",
	TestBuilderGoToStep = "TEST_BUILDER_GO_TO_STEP",

	TestBuilderReadTestRequest = "TEST_BUILDER_READ_REQUEST",
	TestBuilderReadTestSuccess = "TEST_BUILDER_READ_SUCCESS",
	TestBuilderReadTestFailure = "TEST_BUILDER_READ_Failure",

	TestBuilderSetFormData = "TEST_BUILDER_SET_FORM_DATA",

	TestBuilderValidateDetails = "TEST_BUILDER_VALIDATE_DETAILS",
	TestBuilderValidateScript = "TEST_BUILDER_VALIDATE_SCRIPT",

	TestBuilderSetParticipant = "TEST_BUILDER_SET_PARTICIPANT",
	TestBuilderRemoveParticipant = "TEST_BUILDER_REMOVE_PARTICIPANT",
	TestBuilderEditParticipant = "TEST_BUILDER_EDIT_PARTICIPANT",

	TestBuilderSetGroup = "TEST_BUILDER_SET_GROUP",
	TestBuilderRemoveGroup = "TEST_BUILDER_REMOVE_GROUP",
	TestBuilderEditGroup = "TEST_BUILDER_EDIT_GROUP",

	TestBuilderAllowAssertCreationToast = "TEST_BUILDER_ALLOW_ASSERT_CREATION_TOAST",

	TestBuilderInitializeAssert = "TEST_BUILDER_INITIALIZE_ASSERT",
	TestBuilderCancelInitializedAssert = "TEST_BUILDER_CANCEL_INITIALIZE_ASSERT",
	TestBuilderUpdateInitializedAssert = "TEST_BUILDER_UPDATE_INITIALIZED_ASSERT",
	TestBuilderSaveInitializedAssert = "TEST_BUILDER_SAVE_INITIALIZED_ASSERT",

	TestBuilderAddInitializedAssertPrecondition = "TEST_BUILDER_ADD_INITIALIZED_ASSERT_PRECONDITION",
	TestBuilderUpdateInitializedAssertPrecondition = "TEST_BUILDER_UPDATE_INITIALIZED_ASSERT_PRECONDITION",
	TestBuilderDeleteInitializedAssertPrecondition = "TEST_BUILDER_DELETE_INITIALIZED_ASSERT_PRECONDITION",

	TestBuilderAddModifiedAssert = "TEST_BUILDER_MODIFY_ASSERT",
	TestBuilderUpdateModifiedAssert = "TEST_BUILDER_UPDATE_MODIFIED_ASSERT",
	TestBuilderSaveModifiedAssert = "TEST_BUILDER_SAVE_MODIFIED_ASSERT",
	TestBuilderCancelModifiedAssert = "TEST_BUILDER_CANCEL_MODIFIED_ASSERT",
	TestBuilderDeleteModifiedAssert = "TEST_BUILDER_DELETE_MODIFIED_ASSERT",

	TestBuilderAddModifiedAssertPrecondition = "TEST_BUILDER_ADD_MODIFIED_ASSERT_PRECONDITION",
	TestBuilderUpdateModifiedAssertPrecondition = "TEST_BUILDER_UPDATE_MODIFIED_ASSERT_PRECONDITION",
	TestBuilderDeleteModifiedAssertPrecondition = "TEST_BUILDER_DELETE_MODIFIED_ASSERT_PRECONDITION",

	TestBuilderAllowShowingIncompleteAssertError = "TEST_BUILDER_ALLOW_SHOWING_INCOMPLETE_ASSERT_ERROR",
	TestBuilderAllowShowingIncompletePreconditionError = "TEST_BUILDER_ALLOW_SHOWING_INCOMPLETE_PRECONDITION_ERROR",
	TestBuilderDisableAssertTabActions = "TEST_BUILDER_DISABLE_ASSERT_TYPE_ACTION",

	TestBuilderSetAssertToSave = "TEST_BUILDER_SET_ASSERT_TO_SAVE",
	TestBuilderSetShouldSaveAsserts = "TEST_BUILDER_SET_SHOULD_SAVE_ASSERTS",
	TestBuilderSetShouldSaveTestAndNavigate = "TEST_BUILDER_SET_SHOULD_SAVE_TEST_AND_NAVIGATE",
	TestBuilderDiscardChangesInAssert = "TEST_BUILDER_DISCARD_CHANGES_IN_ASSERT",

	TestBuilderGetAllAssertsRequest = "TEST_BUILDER_GET_ALL_ASSERTS_REQUEST",
	TestBuilderGetAllAssertsSuccess = "TEST_BUILDER_GET_ALL_ASSERTS_SUCCESS",
	TestBuilderGetAllAssertsFailure = "TEST_BUILDER_GET_ALL_ASSERTS_FAILURE",

	TestBuilderCopyAssert = "TEST_BUILDER_SET_ASSERT",
	TestBuilderDeleteAssertCreateFlow = "TEST_BUILDER_DELETE_ASSERT_CREATE_FLOW",

	TestBuilderSetTestCUUsage = "TEST_BUILDER_SET_TEST_CU_USAGE",

	TestBuilderSaveSettings = "TEST_BUILDER_SAVE_SETTINGS",

	TestBuilderSetAllPreconditions = "TEST_BUILDER_SET_ALL_PRECONDITIONS",
	TestBuilderSetPrecondition = "TEST_BUILDER_SET_PRECONDITION",
	TestBuilderUpdatePrecondition = "TEST_BUILDER_UPDATE_PRECONDITION",
	TestBuilderDeletePreconditionViaTag = "TEST_BUILDER_DELETE_PRECONDITION_VIA_TAG",

	TestBuilderUpdateTestRequest = "TEST_BUILDER_UPDATE_TEST_REQUEST",
	TestBuilderUpdateTestSuccess = "TEST_BUILDER_UPDATE_TEST_SUCCESS",
	TestBuilderUpdateTestFailure = "TEST_BUILDER_UPDATE_TEST_FAILURE",

	TestBuilderSetEditMode = "TEST_BUILDER_SET_EDIT_MODE",

	TestBuilderSetNavigateFromUpgrade = "TEST_BUILDER_NAVIGATE_FROM_UPGRADE",

	TestBuilderSetInitialFormData = "TEST_BUILDER_SET_INITIAL_FORM_DATA",

	TestBuilderGetAllParticipantsRequest = "TEST_BUILDER_GET_ALL_PARTICIPANTS_REQUEST",
	TestBuilderGetAllParticipantsSuccess = "TEST_BUILDER_GET_ALL_PARTICIPANTS_SUCCESS",
	TestBuilderGetAllParticipantsFailure = "TEST_BUILDER_GET_ALL_PARTICIPANTS_FAILURE",

	TestBuilderSetEditingParticipantGroupID = "TEST_BUILDER_SET_EDITING_PARTICIPANT_GROUP_ID",

	TestBuilderEnableMOS = "TEST_BUILDER_ENABLE_MOS",

	TestBuilderSetActiveParticipantAndGroup = "TEST_BUILDER_SET_ACTIVE_PARTICIPANT_AND_GROUP",

	TestBuilderSetUnsavedChangesActive = "TEST_BUILDER_SET_UNSAVED_CHANGES_ACTIVE",

	// Persisted storage
	SetValueToStorage = "SET_VALUE_TO_STORAGE",
	RemoveValueFromStorage = "REMOVE_VALUE_FROM_STORAGE",

	// Session
	AddRequestToRequestQueue = "ADD_REQUEST_TO_REQUEST_QUEUE",
	EmptyRequestQueue = "EMPTY_REQUEST_QUEUE",
	SetAccessTokenIsRefreshing = "SET_ACCESS_TOKEN_IS_REFRESHING",

	// Tour
	TourNextPrev = "TOUR_NEXT_PREV",
	TourReset = "TOUR_RESET",
	TourRestart = "TOUR_RESTART",
	TourSetSteps = "TOUR_SET_STEPS",
	TourStart = "TOUR_START",
	TourStop = "TOUR_STOP",

	// Tests
	RefetchTests = "REFETCH_TESTS",
	SetTestIDToFetchLabels = "SET_TEST_ID_TO_FETCH_LABELS"
}

export type Action = PaymentsAction;

export type AppDispatch = ThunkDispatch<IStoreState, unknown, Action>;
