import { FC, MouseEvent } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "ui-kit";

import { config, getCrossDomain } from "../../config/config";

import { IStoreState } from "../../redux/reducers";
import { AppDispatch } from "../../redux/store/configureStore";
import { showCookiesManageBanner } from "../../redux/slices";

import { updateGTM } from "../../services/googleTag";

import { Cookies } from "../../types";

import { app, cookies, env } from "../../utils/const";

const cookieName =
	config.env === env.PROD
		? cookies.CONSENT
		: `${cookies.CONSENT}_${config.env}`;

const CookiesBanner: FC = (): JSX.Element | null => {
	const { t } = useTranslation();

	const [cookie, setCookie] = useCookies([cookieName]);

	const dispatch = useDispatch<AppDispatch>();

	const isActive = useSelector(
		({ modal }: IStoreState) => modal.cookiesManageBanner.active
	);

	const accept = (event: MouseEvent): void => {
		event.preventDefault();

		const cookiesAllAccepted: Cookies = {
			security_storage: true,
			ad_personalization: true,
			ad_storage: true,
			ad_user_data: true,
			analytics_storage: true,
			functionality_storage: true,
			personalization_storage: true
		};

		updateGTM(cookiesAllAccepted);

		setCookie(
			cookieName,
			{ ...cookiesAllAccepted },
			{
				path: "/",
				domain: getCrossDomain()
			}
		);
	};

	const toggleCookieManageModal = (): void => {
		dispatch(showCookiesManageBanner());
	};

	if (cookie[cookieName] || isActive) return null;

	return (
		<div className="c-wrapper">
			<div className="c-banner">
				<div className="c-banner__title">{t("COOKIES.TITLE")}</div>

				<div className="c-banner__note">
					{t("COOKIES.NOTE_START")}

					<span>
						<a href={app.COOKIE_POLICY}>{t("COOKIES.NOTE_LINK")}</a>
					</span>

					{t("COOKIES.NOTE_END")}
				</div>

				<Button
					className="button--full-width c-banner__accept-button"
					size="sm"
					onClick={accept}
				>
					{t("COOKIES.ACCEPT")}
				</Button>

				<Button
					className="button--full-width"
					brand="secondary"
					size="sm"
					onClick={toggleCookieManageModal}
				>
					{t("COOKIES.MANAGE")}
				</Button>
			</div>
		</div>
	);
};

export default CookiesBanner;
