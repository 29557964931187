import { Role } from "../types";

const permissionLevel: { [K in Role]: number } = {
	administrator: 3,
	developer: 2,
	visitor: 1,
	none: 0
};

const hasPermission = (memberRole: Role, requiredRole: Role): boolean =>
	permissionLevel[memberRole] >= permissionLevel[requiredRole];

export { hasPermission };
