import type { AxiosResponse } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { store as reduxStore } from "../../redux/store/configureStore";
import {
	APIRequest,
	CRUDOptions,
	DeleteAccountData,
	RequestOptions
} from "../../types";
import { getRequestConfig } from "../../utils/request";
import {
	checkAuthStatus,
	deleteSession,
	retryUnauthorizedRequests,
	setSession
} from "../../utils/session";

import { v2 } from "./config";

const DELETE = <T>(
	navigate: NavigateFunction,
	route: string,
	options?: RequestOptions | DeleteAccountData | {}
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		// TODO: Most likely this should be handled in a different way. More info:
		// https://code.tdlbox.com/loadero/frontend/-/merge_requests/952#note_181549

		const { access_token, refresh_token, expires_at, scopes } =
			reduxStore.getState().persistedStorage;

		checkAuthStatus(navigate).then(
			async () => {
				const config = getRequestConfig(options);

				if ((options as DeleteAccountData)?.clearSession) {
					deleteSession();
				}

				try {
					resolve(
						await retryUnauthorizedRequests(navigate, v2.delete as APIRequest, [
							route,
							config
						])
					);
				} catch (err) {
					setSession(
						{
							access_token,
							refresh_token,
							expires_in: 0,
							token_type: "Bearer",
							scopes
						},
						expires_at
					);
					reject(err);
				}
			},
			() => {
				reject();
			}
		);
	});

const GET = <T>(
	navigate: NavigateFunction,
	route: string,
	options: CRUDOptions | RequestOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(navigate, v2.get as APIRequest, [
						route,
						config
					])
				);
			},
			() => {
				reject();
			}
		);
	});

const POST = <T>(
	navigate: NavigateFunction,
	route: string,
	data: FormData | {},
	options: CRUDOptions | RequestOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(navigate, v2.post, [route, data, config])
				);
			},
			() => {
				reject();
			}
		);
	});

const PUT = <T>(
	navigate: NavigateFunction,
	route: string,
	data: FormData | {},
	options: CRUDOptions
): Promise<AxiosResponse<T>> =>
	new Promise((resolve, reject) => {
		checkAuthStatus(navigate).then(
			() => {
				const config = getRequestConfig(options);

				resolve(
					retryUnauthorizedRequests(navigate, v2.put, [route, data, config])
				);
			},
			() => {
				reject();
			}
		);
	});

const api = { DELETE, GET, POST, PUT };

export { api };
