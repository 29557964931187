import { createSlice } from "@reduxjs/toolkit";

export interface ITestReducer {
	shouldRefetchTests: boolean;
	isDeletingTest?: boolean;
	testIDToFetchLabels: number;
}

const initialState: ITestReducer = {
	shouldRefetchTests: false,
	isDeletingTest: false,
	testIDToFetchLabels: 0
};

const testsSlice = createSlice({
	name: "tests",
	initialState,
	reducers: {
		refetchTests: (
			state,
			action: {
				payload: { shouldRefetchTests: boolean; isDeletingTest?: boolean };
			}
		) => {
			state.shouldRefetchTests = action.payload.shouldRefetchTests;
			state.isDeletingTest = action.payload.isDeletingTest || false;
		},
		setTestIDToFetchLabels: (state, action: { payload: number }) => {
			state.testIDToFetchLabels = action.payload;
		}
	}
});

export const { refetchTests, setTestIDToFetchLabels } = testsSlice.actions;
export default testsSlice.reducer;
