import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CopyParticipantBody,
	InternalParticipant,
	Participant,
	ParticipantParams,
	QueryParams,
	RequestOptions,
	ResponseWithPaging
} from "../../types";
import { queryKeys as qk } from "../../utils/const";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createParticipant = (
	navigate: NavigateFunction,
	params: ParticipantParams,
	data: InternalParticipant,
	grouped: boolean,
	options: RequestOptions
): Promise<Participant | {} | APIError> => {
	const { groupID, projectID, testID } = params;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.PARTICIPANTS
	);

	if (grouped) {
		route = createRoute(
			true,
			routes.PROJECTS,
			projectID,
			routes.TESTS,
			testID,
			routes.GROUPS,
			groupID,
			routes.PARTICIPANTS
		);
	}

	return crud.CREATE<InternalParticipant>(
		navigate,
		route,
		data as InternalParticipant,
		options
	);
};

const deleteParticipant = (
	navigate: NavigateFunction,
	params: Required<ParticipantParams>,
	grouped: boolean,
	options: RequestOptions
): Promise<void | APIError> => {
	const { groupID, participantID, projectID, testID } = params;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.PARTICIPANTS,
		participantID
	);

	if (grouped) {
		route = createRoute(
			true,
			routes.PROJECTS,
			projectID,
			routes.TESTS,
			testID,
			routes.GROUPS,
			groupID,
			routes.PARTICIPANTS,
			participantID
		);
	}

	return crud.DELETE(navigate, route, options);
};

const getAllParticipants = (
	navigate: NavigateFunction,
	params: ParticipantParams,
	queryParams: QueryParams,
	grouped?: boolean,
	options?: RequestOptions
): Promise<ResponseWithPaging<InternalParticipant> | APIError> => {
	const { groupID, testID, projectID } = params;
	const { filters, pagination, sorts } = queryParams;
	const { limit, page } = pagination;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.PARTICIPANTS
	);

	if (grouped) {
		route = createRoute(
			true,
			routes.PROJECTS,
			projectID,
			routes.TESTS,
			testID,
			routes.GROUPS,
			groupID,
			routes.PARTICIPANTS
		);
	}

	route += createQuery(
		filters && filters.name ? setQueryParam(qk.FILTER.NAME, filters.name) : {},
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page))
	);

	// TODO: improve the sorting logic
	const sortsQuery = sorts
		? sorts.map(sort =>
				createQuery(setQueryParam(qk.ORDER_BY, sort.orderBy))
			)[0]
		: "";

	route += sortsQuery.replace("?", "&");

	return crud.READ<ResponseWithPaging<InternalParticipant>>(
		navigate,
		route,
		options || {}
	);
};

const updateParticipant = (
	navigate: NavigateFunction,
	params: Required<ParticipantParams>,
	data: InternalParticipant,
	grouped: boolean,
	options: RequestOptions
): Promise<InternalParticipant | {} | APIError> => {
	const { groupID, participantID, projectID, testID } = params;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.PARTICIPANTS,
		participantID
	);

	if (grouped) {
		route = createRoute(
			true,
			routes.PROJECTS,
			projectID,
			routes.TESTS,
			testID,
			routes.GROUPS,
			groupID,
			routes.PARTICIPANTS,
			participantID
		);
	}

	return crud.UPDATE<InternalParticipant>(
		navigate,
		route,
		data as InternalParticipant,
		options
	);
};

const copyParticipant = (
	navigate: NavigateFunction,
	params: Required<ParticipantParams>,
	data: CopyParticipantBody,
	grouped: boolean,
	options: RequestOptions
): Promise<InternalParticipant | {} | APIError> => {
	const { groupID, participantID, projectID, testID } = params;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.PARTICIPANTS,
		participantID,
		routes.COPY
	);

	if (grouped) {
		route = createRoute(
			true,
			routes.PROJECTS,
			projectID,
			routes.TESTS,
			testID,
			routes.GROUPS,
			groupID,
			routes.PARTICIPANTS,
			participantID,
			routes.COPY
		);
	}

	return crud.CREATE<CopyParticipantBody>(
		navigate,
		route,
		data as CopyParticipantBody,
		options
	);
};

const participantAPI = {
	create: createParticipant,
	delete: deleteParticipant,
	readAll: getAllParticipants,
	update: updateParticipant,
	copy: copyParticipant
};

export default participantAPI;
