import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CreateProjectData,
	Filters,
	Pagination,
	ProjectData,
	ProjectParams,
	RequestOptions,
	ResponseWithPaging
} from "../../types";
import { queryKeys as qk } from "../../utils/const";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createProject = (
	navigate: NavigateFunction,
	data: CreateProjectData,
	options: RequestOptions
): Promise<ProjectData | {} | APIError> => {
	const route = createRoute(false, routes.PROJECTS);

	return crud.CREATE<ProjectData>(navigate, route, data, options);
};

const deleteProject = (
	navigate: NavigateFunction,
	params: ProjectParams,
	options: RequestOptions
): Promise<void | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID);

	return crud.DELETE(navigate, route, options);
};

const getProject = (
	navigate: NavigateFunction,
	params: ProjectParams,
	options: RequestOptions
): Promise<ProjectData | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID);

	return crud.READ<ProjectData>(navigate, route, options);
};

const getAllProjects = (
	navigate: NavigateFunction,
	queryParams: { filters: Filters; pagination: Pagination } | {},
	options: RequestOptions
): Promise<ResponseWithPaging<ProjectData> | APIError> => {
	let route = createRoute(false, routes.PROJECTS);

	if ("pagination" in queryParams) {
		const { pagination } = queryParams;
		const { limit, page } = pagination;

		route += createQuery(
			setQueryParam(qk.LIMIT, limit),
			setQueryParam(qk.OFFSET, getOffset(limit, page))
		);
	}

	return crud.READ<ResponseWithPaging<ProjectData>>(navigate, route, options);
};

const updateProject = (
	navigate: NavigateFunction,
	params: ProjectParams,
	data: CreateProjectData,
	options: RequestOptions
): Promise<ProjectData | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID);

	return crud.UPDATE<ProjectData>(navigate, route, data, options);
};

const activateTrial = (
	navigate: NavigateFunction,
	params: ProjectParams,
	options: RequestOptions
): Promise<APIError | {} | void> => {
	const { projectID } = params;

	const route = createRoute(true, routes.PROJECTS, projectID, routes.TRIAL);

	return crud.CREATE(navigate, route, options);
};

const projectAPI = {
	activate: activateTrial,
	create: createProject,
	delete: deleteProject,
	read: getProject,
	readAll: getAllProjects,
	update: updateProject
};

export default projectAPI;
