const config = {
	clientID: process.env.REACT_APP_CLIENT_ID,
	env: process.env.REACT_APP_ENV,
	authURL: process.env.REACT_APP_AUTH_URL,
	apiURL: process.env.REACT_APP_API_URL,
	basicToken: process.env.REACT_APP_BASIC_TOKEN,
	mediaAPIURL: process.env.REACT_APP_MEDIA_API_URL,
	qaDomain: process.env.REACT_APP_QA_DOMAIN,
	stripeKey: process.env.REACT_APP_STRIPE_KEY,
	sentryKey: process.env.REACT_APP_SENTRY_KEY,
	version: process.env.REACT_APP_VERSION
};

const keys = {
	CRISP: process.env.REACT_APP_CRISP_KEY,
	GOOGLE_TAG_MANAGER: process.env.REACT_APP_GTM
};

const discordProxy = {
	contactUs: process.env.REACT_APP_SENTRY_KEY_CONTACT_US_URL
};

const pipedrive = {
	baseUrl: process.env.REACT_APP_PIPEDRIVE_URL,
	token: process.env.REACT_APP_PIPEDRIVE_TOKEN
};

const getUrl = (landing?: boolean): string => {
	if (landing) {
		return process.env.REACT_APP_LANDING_URL as string;
	}

	if (process.env.REACT_APP_ENV === "review") {
		return window.location.origin;
	}

	return process.env.REACT_APP_APP_URL as string;
};

const getCrossDomain = (): string =>
	process.env.REACT_APP_CROSS_DOMAIN as string;

const monacoEditor = {
	url: "https://gcore.jsdelivr.net/npm/monaco-editor@0.48.0/min/vs"
};

export {
	config,
	discordProxy,
	getCrossDomain,
	getUrl,
	keys,
	monacoEditor,
	pipedrive
};
