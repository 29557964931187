import { FC } from "react";

interface IProps {
	listItems: string[];
	title: string;
}

const ToastWithList: FC<IProps> = ({ listItems, title }): JSX.Element => {
	const itemRows = listItems.map((item, index) => <li key={index}>{item}</li>);

	return (
		<div className="toast-with-list">
			{title}

			<ul>{itemRows}</ul>
		</div>
	);
};

export default ToastWithList;
