import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import { RequestOptions, StaticData } from "../../types";
import { createRoute } from "../../utils/request";

import { api } from "./api";

const getStatics = (
	navigate: NavigateFunction,
	options: RequestOptions,
	type?: string
): Promise<string[] | StaticData> => {
	const route = createRoute(false, routes.STATIC, type as string);

	return new Promise((resolve, reject) => {
		api.GET<string[] | StaticData>(navigate, route, options).then(
			response => {
				resolve(response.data);
			},
			error => {
				reject(error);
			}
		);
	});
};

const staticAPI = {
	read: getStatics
};

export default staticAPI;
