import { isCancel } from "axios";
import { toast } from "react-toastify";

import i18n from "../config/i18n";

import { APIError, CRUDArgs, CRUDOptions } from "../types";

import { asyncRetry } from "../utils/method";

const alertError = ({
	error,
	showAllErrors = false
}: {
	error: APIError;
	showAllErrors?: boolean;
}): void => {
	const { response } = error || {};
	const message = response && response.data && response.data.error;

	if (
		!showAllErrors &&
		response &&
		(response.status === 409 || response.status === 422)
	) {
		return;
	}

	toast.error(message || i18n.t("ALERT.ERROR.DEFAULT"));
};

const generateError = (errMsg: string): APIError => ({
	response: {
		data: {
			error: errMsg
		}
	}
});

const handleAPIRateLimiting = <T>(
	func: (...args: CRUDArgs) => Promise<T>,
	args: CRUDArgs
): Promise<T> => {
	const options = args.find(
		obj =>
			typeof obj === "object" &&
			typeof (obj as CRUDOptions).skipRetry !== "undefined"
	);

	const { skipRetry } = (options as CRUDOptions) || {};

	return skipRetry
		? func(...args)
		: asyncRetry(() => func(...args), 5, 1000, true).then(
				res => Promise.resolve(res),
				error => {
					if (error?.response?.status === 429) {
						toast.error(i18n.t("ALERT.ERROR.RETRY_FAILED"));
					}

					return Promise.reject(error);
				}
			);
};

const handleError = (
	error: APIError,
	skipRetry: boolean,
	suppressNotification: boolean,
	reject: (error: APIError) => void
): void => {
	switch (true) {
		case isCancel(error) ||
			suppressNotification ||
			(!skipRetry && error?.response?.status === 429):
			return reject(error);
		default: {
			alertError({ error });
			return reject(error);
		}
	}
};

export { alertError, generateError, handleAPIRateLimiting, handleError };
