import { type NavigateFunction } from "react-router-dom";

import { toast } from "react-toastify";

import { runAPI } from "../api/v2";

import i18n from "../config/i18n";

import { fireGTMEvent } from "../services/googleTag";

import { EVENT_KEYS } from "./const/events";

import { errorKeys } from "./const/errors";

import { handleRequestBodyError } from "./error";
import { arraysEqual } from "./method";
import {
	ActiveRun,
	APIError,
	ProcessedActiveRuns,
	RequestOptions,
	RunParams
} from "../types";

const activeRunsChanged = (
	prevRuns: ProcessedActiveRuns,
	activeRuns: ProcessedActiveRuns
) => {
	if (!arraysEqual(Object.keys(prevRuns), Object.keys(activeRuns))) {
		return true;
	}

	let prevRunIDs: string[] = [];
	let activeRunIDs: string[] = [];

	Object.values(prevRuns).forEach(test => {
		prevRunIDs = [...prevRunIDs, ...Object.keys(test)];
	});
	Object.values(activeRuns).forEach(test => {
		activeRunIDs = [...activeRunIDs, ...Object.keys(test)];
	});

	return !arraysEqual(prevRunIDs, activeRunIDs);
};

const runTest = (
	params: RunParams,
	navigate: NavigateFunction,
	options: RequestOptions
) =>
	new Promise<{} | ActiveRun | APIError>((resolve, reject) => {
		runAPI.TEST.create(navigate, params, options).then(
			run => {
				toast.success(i18n.t("ALERT.SUCCESS.RUN.CREATE"));

				fireGTMEvent({
					event: EVENT_KEYS.TEST.RUN
				});
				resolve(run);
			},
			error => {
				handleRequestBodyError(
					error,
					errorKeys.RUN,
					i18n.t("ALERT.ERROR.TEST_RUN_START")
				);

				reject();
			}
		);
	});

const runActive = (run: ActiveRun, activeRuns: ProcessedActiveRuns) => {
	const { id, test_id } = run;

	const running =
		activeRuns.hasOwnProperty(test_id) &&
		activeRuns[test_id].hasOwnProperty(id);

	return running ? activeRuns[test_id][id] : null;
};

const stopTestRun = (
	params: Required<RunParams>,
	navigate: NavigateFunction,
	options: RequestOptions
) =>
	new Promise<void>((resolve, reject) => {
		runAPI.TEST.stop(navigate, params, options).then(
			() => {
				toast.success(i18n.t("ALERT.SUCCESS.RUN.STOP"));
				resolve();
			},
			() => {
				reject();
			}
		);
	});

export { activeRunsChanged, runActive, runTest, stopTestRun };
