import type { AxiosRequestConfig, AxiosResponse } from "axios";

import { ChangePasswordData } from "../../types";

import { auth } from "./config";

const DELETE = <T>(
	route: string,
	config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => auth.delete(route, config);

const GET = <T>(
	route: string,
	config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => auth.get(route, config);

const POST = <T>(
	route: string,
	data: FormData | {},
	config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => auth.post(route, data, config);

const PUT = <T>(
	route: string,
	data: ChangePasswordData,
	config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => auth.put(route, data, config);

const crud = {
	DELETE,
	GET,
	POST,
	PUT
};

export { crud };
