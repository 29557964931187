import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	InternalPrecondition,
	Pagination,
	Precondition,
	PreconditionParams,
	PreconditionDTO,
	RequestOptions,
	ResponseWithPaging
} from "../../types";

import { createPaginationQuery, createRoute } from "../../utils/request";

import { crud } from "./crud";

const createPrecondition = (
	navigate: NavigateFunction,
	params: PreconditionParams,
	data: Precondition,
	options: RequestOptions
): Promise<PreconditionDTO | {} | APIError> => {
	const { assertID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS
	);

	return crud.CREATE<PreconditionDTO>(navigate, route, data, options);
};

const createUpdateDeletePreconditionBulk = (
	navigate: NavigateFunction,
	params: PreconditionParams,
	data: Partial<InternalPrecondition>[],
	options: RequestOptions
): Promise<ResponseWithPaging<PreconditionDTO> | {} | APIError> => {
	const { assertID, projectID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS,
		routes.BULK
	);

	return crud.CREATE<PreconditionDTO>(navigate, route, data, options);
};

const deletePrecondition = (
	navigate: NavigateFunction,
	params: Required<PreconditionParams>,
	options: RequestOptions
): Promise<void | {} | APIError> => {
	const { assertID, preconditionID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS,
		preconditionID
	);

	return crud.DELETE(navigate, route, options);
};

const getPrecondition = (
	navigate: NavigateFunction,
	params: Required<PreconditionParams>,
	options: RequestOptions
): Promise<PreconditionDTO | APIError> => {
	const { assertID, preconditionID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS,
		preconditionID
	);

	return crud.READ<PreconditionDTO>(navigate, route, options);
};

const getAllPreconditions = (
	navigate: NavigateFunction,
	params: PreconditionParams,
	pagination: Pagination,
	options: RequestOptions
): Promise<ResponseWithPaging<PreconditionDTO> | APIError> => {
	const { assertID, projectID, testID } = params;
	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS
	);

	if (pagination) {
		const { limit, page } = pagination;
		route += createPaginationQuery(limit, page);
	}

	return crud.READ<ResponseWithPaging<PreconditionDTO>>(
		navigate,
		route,
		options
	);
};

const updatePreconditions = (
	navigate: NavigateFunction,
	params: Required<PreconditionParams>,
	data: Precondition,
	options: RequestOptions
): Promise<PreconditionDTO | {} | APIError> => {
	const { assertID, preconditionID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS,
		preconditionID
	);

	return crud.UPDATE<PreconditionDTO>(navigate, route, data, options);
};

const copyPreconditions = (
	navigate: NavigateFunction,
	params: Required<PreconditionParams>,
	options: RequestOptions
): Promise<PreconditionDTO | {} | APIError> => {
	const { assertID, preconditionID, projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.ASSERTS,
		assertID,
		routes.PRECONDITIONS,
		preconditionID,
		routes.COPY
	);

	return crud.CREATE<PreconditionDTO>(navigate, route, options || {});
};

const preconditionAPI = {
	create: createPrecondition,
	createUpdateDeleteBulk: createUpdateDeletePreconditionBulk,
	delete: deletePrecondition,
	read: getPrecondition,
	readAll: getAllPreconditions,
	update: updatePreconditions,
	copy: copyPreconditions
};

export default preconditionAPI;
