// @ts-expect-error: ts thinks that there is not such file
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { RewriteFrames } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import loader from "@monaco-editor/loader";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CookiesProvider } from "react-cookie";

import { Loader } from "ui-kit";

import { store } from "./redux/store/configureStore";

import App from "./App";

import { config, monacoEditor } from "./config/config";
import i18n from "./config/i18n";

import { initCrisp } from "./services/crisp";
import { initGTM } from "./services/googleTag";

import { unregister } from "./registerServiceWorker";

import "normalize.css";
import "./index.scss";
import "ui-kit/index.scss";

window.dataLayer = window.dataLayer || [];

loader.config({
	paths: {
		vs: monacoEditor.url
	}
});

if (process.env.REACT_APP_ENABLE_SENTRY === "true") {
	Sentry.init({
		dsn: config.sentryKey,
		environment: config.env,
		release: process.env.REACT_APP_VERSION,
		tracesSampler: () =>
			Number(process.env.REACT_APP_PUBLIC_TRACE_SAMPLER) || 0,
		integrations: [
			new RewriteFrames({
				root: global.__dirname
			}),
			new Integrations.BrowserTracing()
		]
	});
}

initCrisp();
initGTM();

const persistor = persistStore(store);

const rootInstance = createRoot(document.getElementById("root"));

rootInstance.render(
	<Provider store={store}>
		<PersistGate loading={<Loader centered />} persistor={persistor}>
			<I18nextProvider i18n={i18n}>
				<CookiesProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</CookiesProvider>
			</I18nextProvider>
		</PersistGate>
	</Provider>
);

unregister();
