import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import { queryKeys as qk } from "../../utils/const";
import { checkID } from "../../utils/method";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import {
	APIError,
	Pagination,
	ParticipantResult,
	RequestOptions,
	ResponseWithPaging,
	ResultsParams,
	StatisticsData
} from "../../types";

import { crud } from "./crud";
import { ParticipantReport } from "../../types/reports";

const getAllResults = (
	navigate: NavigateFunction,
	params: ResultsParams,
	pagination: Pagination,
	options: RequestOptions
): Promise<ResponseWithPaging<ParticipantResult> | APIError> => {
	const { projectID, runID, testID } = params;
	const { limit, page } = pagination || {};

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS,
		runID,
		routes.RESULTS
	);

	route += createQuery(
		setQueryParam(qk.DESCRIBE, true),
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page))
	);

	return crud.READ<ResponseWithPaging<ParticipantResult>>(
		navigate,
		route,
		options
	);
};

const getParticipantResults = (
	navigate: NavigateFunction,
	params: Required<ResultsParams>,
	options: RequestOptions
): Promise<ParticipantReport | APIError> => {
	const { participantID, projectID, runID, testID } = params;

	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.RUNS,
		runID,
		routes.RESULTS,
		participantID
	);

	route += createQuery(setQueryParam(qk.DESCRIBE, true));

	return crud.READ<ParticipantReport>(navigate, route, options);
};

const getStatistics = (
	navigate: NavigateFunction,
	params: ResultsParams,
	options: RequestOptions
): Promise<StatisticsData | APIError> => {
	const { projectID, runID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.TESTS,
		checkID(testID),
		routes.RUNS,
		checkID(runID),
		routes.RESULTS,
		routes.STATISTICS
	);

	return crud.READ<StatisticsData>(navigate, route, options);
};

const resultAPI = {
	readParticipant: getParticipantResults,
	readTestRun: getAllResults,
	readStatistics: getStatistics
};

export default resultAPI;
