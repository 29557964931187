import { type NavigateFunction } from "react-router-dom";
import { routes } from "../../router/routes";
import {
	AccountParams,
	APIError,
	AvatarData,
	ProjectParams,
	RequestOptions
} from "../../types";

import { createRoute, initializeEmptyConfig } from "../../utils/request";

import { crud } from "./crud";

const getAccountAvatar = (
	navigate: NavigateFunction,
	params: AccountParams,
	options?: RequestOptions
): Promise<Blob | {} | APIError> => {
	const { accountID } = params;
	const route = createRoute(true, routes.ACCOUNTS, accountID, routes.AVATAR);

	const config = initializeEmptyConfig();
	config.responseType = "blob";

	const updatedOptions = { ...options, config };

	return crud.READ<Blob>(navigate, route, updatedOptions as RequestOptions);
};

const getProjectAvatar = (
	navigate: NavigateFunction,
	params: ProjectParams,
	options?: RequestOptions
): Promise<Blob | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.AVATAR);

	const config = initializeEmptyConfig();
	config.responseType = "blob";

	const updatedOptions = { ...options, config };

	return crud.READ<Blob>(navigate, route, updatedOptions as RequestOptions);
};

const deleteAccountAvatar = (
	navigate: NavigateFunction,
	params: AccountParams,
	options: RequestOptions
): Promise<void | APIError> => {
	const { accountID } = params;
	const route = createRoute(true, routes.ACCOUNTS, accountID, routes.AVATAR);

	return crud.DELETE(navigate, route, options);
};

const deleteProjectAvatar = (
	navigate: NavigateFunction,
	params: ProjectParams,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.AVATAR);

	return crud.DELETE(navigate, route, options as RequestOptions);
};

const updateAccountAvatar = (
	navigate: NavigateFunction,
	params: AccountParams,
	data: AvatarData | FormData,
	options: RequestOptions
): Promise<Blob | {} | APIError> => {
	const { accountID } = params;
	const route = createRoute(true, routes.ACCOUNTS, accountID, routes.AVATAR);

	const config = initializeEmptyConfig();
	config.headers["Content-Type"] = "multipart/form-data";

	const updatedOptions = { ...options, config };

	return crud.UPDATE<Blob>(
		navigate,
		route,
		data,
		updatedOptions as RequestOptions
	);
};

const updateProjectAvatar = (
	navigate: NavigateFunction,
	params: ProjectParams,
	data: AvatarData | FormData,
	options: RequestOptions
): Promise<Blob | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.AVATAR);

	const config = initializeEmptyConfig();
	config.headers["Content-Type"] = "multipart/form-data";

	const updatedOptions = { ...options, config };

	return crud.UPDATE<Blob>(
		navigate,
		route,
		data,
		updatedOptions as RequestOptions
	);
};

const avatarAPI = {
	account: {
		read: getAccountAvatar,
		update: updateAccountAvatar,
		delete: deleteAccountAvatar
	},
	project: {
		read: getProjectAvatar,
		update: updateProjectAvatar,
		delete: deleteProjectAvatar
	}
};

export default avatarAPI;
