import {
	lazy,
	MouseEvent,
	Suspense,
	useEffect,
	useState,
	ComponentType
} from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation, type Location } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
// eslint-disable-next-line
import { type CloseButtonProps } from "react-toastify/dist/components/CloseButton";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Loader, SVGIcon } from "ui-kit";

import { appIcons } from "./assets/svg-icons";

import CookiesBanner from "./components/cookies/CookiesBanner";
import CookiesManageBanner from "./components/cookies/CookiesManageBanner";

import { config, getCrossDomain } from "./config/config";

import useKeyboardFocus from "./hooks/useKeyboardFocus";

import { IStoreState } from "./redux/reducers";
import { checkAuthStatus } from "./redux/slices";
import { AppDispatch } from "./redux/store/configureStore";

import { routes } from "./router/routes";
import ScrollToTop from "./router/ScrollToTop";

import { fireGTMEvent, handleGTMMessage } from "./services/googleTag";

import { cookies, env, toastAlert } from "./utils/const";
import { EVENT_KEYS } from "./utils/const/events";
import { lazyComponent } from "./utils/method";
import { validSession } from "./utils/session";

const AlertCloseButton = ({
	closeToast
}: {
	closeToast: CloseButtonProps;
}): JSX.Element => {
	const clickClose = (e?: unknown): void => {
		closeToast.closeToast(e as MouseEvent<HTMLElement>);
	};

	return (
		<SVGIcon
			onClick={clickClose}
			className="alert-close"
			iconName="alert-close"
			customIconSet={appIcons}
		/>
	);
};

const PrivateRoutes = lazy(
	() =>
		lazyComponent(import("./router/PrivateRoutes")) as Promise<{
			default: ComponentType;
		}>
);

const PublicRoutes = lazy(
	() =>
		lazyComponent(import("./router/PublicRoutes")) as Promise<{
			default: ComponentType;
		}>
);

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = (async () =>
	await loadStripe(config.stripeKey || "", {
		locale: "en"
	}))();

const App = (): JSX.Element => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const location = useLocation();

	const [initialized, setInitialized] = useState(false);
	const setCookie = useCookies([cookies.LOGGED_IN])[1];
	const removeCookie = useCookies([cookies.LOGGED_IN])[2];

	const loggedIn = useSelector(({ ui }: IStoreState) => ui.loggedIn);
	const accountID = useSelector(
		({ account }: IStoreState) => account?.accountData?.id
	);
	const isActive = useSelector(
		({ modal }: IStoreState) => modal.cookiesManageBanner.active
	);

	useKeyboardFocus();

	const displayPrivateRoutes = (
		currentLocation: Location,
		isLoggedIn: boolean
	): boolean => {
		const rules = [
			currentLocation.pathname.startsWith(routes.PROJECTS),
			currentLocation.pathname.startsWith(routes.SETTINGS),
			currentLocation.pathname === routes.LOG_IN,
			currentLocation.pathname === routes.LOG_OUT,
			currentLocation.pathname === routes.ROOT
		];

		const showPrivateRoutes = rules.some(rule => rule);

		return isLoggedIn && showPrivateRoutes;
	};

	useEffect(() => {
		window.addEventListener("message", handleGTMMessage);

		validSession(navigate).then(isLoggedIn => {
			setInitialized(true);

			dispatch(
				checkAuthStatus({ authState: isLoggedIn, nextRoute: null, navigate })
			);
		});

		if (location.pathname.includes(routes.SIGN_UP)) {
			navigate(routes.REGISTER, { replace: true });
		}

		return () => window?.removeEventListener("message", handleGTMMessage);
	}, []);

	useEffect(() => {
		const envSpecificLoggedInCookie =
			config.env === env.STAGE ? cookies.LOGGED_IN_STAGE : cookies.LOGGED_IN;

		if (loggedIn) {
			setCookie(envSpecificLoggedInCookie, true, {
				path: "/",
				domain: getCrossDomain()
			});
		} else {
			removeCookie(envSpecificLoggedInCookie, {
				path: "/",
				domain: getCrossDomain()
			});
		}

		if (displayPrivateRoutes(location, loggedIn) && accountID) {
			fireGTMEvent({
				event: EVENT_KEYS.ACCOUNT.LOGIN,
				account_id: accountID
			});
		}
	}, [accountID, loggedIn, location]);

	if (!initialized) return <Loader centered />;

	return (
		<Elements stripe={stripePromise}>
			<div className="app">
				<ScrollToTop />

				<ToastContainer
					className="alert-container"
					toastClassName="alert-box"
					progressClassName="alert-progress-bar"
					autoClose={toastAlert.AUTO_CLOSE_TIME}
					closeButton={cb => <AlertCloseButton closeToast={cb} />}
					newestOnTop
				/>

				<Suspense fallback={<Loader centered />}>
					{displayPrivateRoutes(location, loggedIn) ? (
						<PrivateRoutes />
					) : (
						<PublicRoutes />
					)}

					<CookiesBanner />

					{isActive && (
						<div className="c-wrapper">
							<CookiesManageBanner />
						</div>
					)}
				</Suspense>
			</div>
		</Elements>
	);
};

export default App;
