import moment from "moment";

import { dateTimeFormat } from "./const";

const formatDuration = (ms: number): string => {
	const duration = moment
		.utc(ms)
		.format(dateTimeFormat.TIME)
		.split(":") as unknown as number[];

	duration[0] = parseInt(
		moment.duration(ms).asHours() as unknown as string,
		10
	);
	duration[0] =
		duration[0] <= 9
			? (`0${duration[0]}` as unknown as number)
			: (duration[0] as unknown as number);

	return duration.join(":");
};

const formatDateTime = (
	dateTimeValue: string | number,
	format: string
): string | number => {
	const { DATE_DASH, DATE_SLASH, DATE_TIME, MONTH_DAY_YEAR_TIME, TIME } =
		dateTimeFormat;

	switch (format) {
		case DATE_DASH:
			return moment(dateTimeValue).format(DATE_DASH);
		case DATE_SLASH:
			return moment(dateTimeValue).format(DATE_SLASH);
		case DATE_TIME:
			return moment(dateTimeValue).format(DATE_TIME);
		case MONTH_DAY_YEAR_TIME:
			return moment(dateTimeValue).format(MONTH_DAY_YEAR_TIME);
		case TIME:
			return moment()
				.startOf("day")
				.seconds(dateTimeValue as number)
				.format(TIME);
		default:
			return dateTimeValue;
	}
};

const validDateFilter = (dateFrom: string, dateTo: string): boolean => {
	const from = moment(dateFrom, dateTimeFormat.DATE_DASH);
	const to = moment(dateTo, dateTimeFormat.DATE_DASH);

	return (
		moment(from).isValid() &&
		moment(to).isValid() &&
		moment(from).isSameOrBefore(moment(), "day") &&
		moment(to).isSameOrBefore(moment(), "day") &&
		moment(from).isSameOrBefore(to)
	);
};

export { formatDateTime, formatDuration, validDateFilter };
