import moment from "moment";

import i18n from "../config/i18n";

import { browser, maxCounterNumber } from "./const";

const comparePasswords = (
	password: string,
	confirmPassword: string
): string => {
	if (!confirmPassword || confirmPassword.length === 0) {
		return "ERROR.REQUIRED";
	}

	if (password !== "" && password !== confirmPassword) {
		return "ERROR.PASSWORD.MATCH";
	}

	return "";
};

const isChromeBrowser = (browserKey: string): boolean =>
	browserKey.indexOf(browser.CHROME) > -1;

const isNumeric = (num: number | string): boolean =>
	!Number.isNaN(parseFloat(num as string)) && Number.isFinite(num);

const validateAvatarFormat = (mimeType: string): boolean => {
	const allowedMimeTypes = ["image/png", "image/jpeg"];

	return allowedMimeTypes.indexOf(mimeType) > -1;
};

const validateCardNumber = (validatedByStripe: string): string => {
	if (!validatedByStripe) {
		return "ERROR.CARD_NUMBER";
	}

	return "";
};

const validateConnectedFields = (a: string, b: string): string => {
	if (a === "" && b !== "") {
		return "ERROR.CONNECTED_FIELD";
	}

	return "";
};

const validateCount = (count: number | string): string => {
	if (
		!count ||
		count === "" ||
		parseInt(count as string, 10) === 0 ||
		parseInt(count as string, 10) > maxCounterNumber
	) {
		return "ERROR.COUNT";
	}

	return "";
};

const validateCVC = (validatedByStripe: string): string => {
	if (!validatedByStripe) {
		return "ERROR.CVC";
	}

	return "";
};

const validateEmail = (email: string): string => {
	if (!email || email.length === 0) {
		return "ERROR.REQUIRED";
	}

	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!re.test(email)) {
		return "ERROR.EMAIL";
	}

	return "";
};

const validateExpiry = (validatedByStripe: string): string => {
	if (!validatedByStripe) {
		return "ERROR.EXPIRY";
	}

	return "";
};

const validateLength = (
	name: string,
	length: number,
	required: boolean
): string => {
	if (required && !name) {
		return "ERROR.REQUIRED";
	}

	if (name && name.length > length) {
		return "ERROR.LENGTH_EXCEEDED";
	}

	return "";
};

const validatePassword = (password: string): string => {
	if (!password || password.length === 0) {
		return "ERROR.REQUIRED";
	}

	if (password.length < 8) {
		return "ERROR.PASSWORD.LENGTH";
	}

	let re = /[a-zA-Z]/;
	if (!re.test(password)) {
		return "ERROR.PASSWORD.SYMBOL";
	}

	re = /[0-9]/;
	if (!re.test(password)) {
		return "ERROR.PASSWORD.NUMBER";
	}

	return "";
};

const validateRequired = (name: string): string => {
	if (!name || name.length === 0) {
		return "ERROR.REQUIRED";
	}

	return "";
};

const validateScript = (name: string): string => {
	if (!name || name.length === 0) {
		return "ERROR.SCRIPT_REQUIRED";
	}

	return "";
};

const validateTestMode = (mode: string, allowSR: boolean): string => {
	if (mode === "session-record" && !allowSR) {
		return "ERROR.SR_UNAVAILABLE";
	}

	return "";
};

const validateTime = (time: string): string => {
	if (!time || time === "00:00:00") {
		return "ERROR.TIME";
	}

	return "";
};

const convertMaxAllowedTime = (timeUnit: string, timeValue: string): string => {
	return timeUnit === "h" ? `${timeValue}:00:00` : `00:${timeValue}:00`;
};

const convertMaxAllowedTimeWithUnit = (testDuration: string): string => {
	const timeUnit = testDuration.slice(-1);
	const timeValue = testDuration.replace(/\D/g, "");

	return timeUnit === "h" ? `${timeValue} hours` : `${timeValue} minutes`;
};

const exceededMaxTime = (
	time: string,
	max: string,
	isTimeout = false
): boolean => {
	const selectedTime = moment.duration(time);
	const maxAllowedTime = moment.duration(max);

	return isTimeout
		? selectedTime >= maxAllowedTime
		: selectedTime > maxAllowedTime;
};

const validateTimerTime = (
	time: string,
	maxAllowedTime: string,
	isTimeout?: boolean
): string => {
	if (!time || !maxAllowedTime) return "";

	const timeUnit = maxAllowedTime.slice(-1);
	const timeValue = maxAllowedTime.replace(/\D/g, "");
	maxAllowedTime = convertMaxAllowedTime(timeUnit, timeValue);

	switch (true) {
		case !time || time === "00:00:00":
			return i18n.t("ERROR.TIME");
		case isTimeout && exceededMaxTime(time, maxAllowedTime, isTimeout):
			return i18n.t("ERROR.MAX_INTERVAL");
		case exceededMaxTime(time, maxAllowedTime):
			return `${i18n.t("ERROR.MAX_DURATION")} ${timeValue} ${
				timeUnit === "h" ? i18n.t("HOURS") : i18n.t("MINUTES")
			}`;
		default:
			return "";
	}
};

const validateDuration = (duration: string): boolean => {
	const regex = /^\d+:\d{2}:\d{2}$/;

	return regex.test(duration);
};

export {
	comparePasswords,
	convertMaxAllowedTimeWithUnit,
	exceededMaxTime,
	isChromeBrowser,
	isNumeric,
	validateAvatarFormat,
	validateCardNumber,
	validateConnectedFields,
	validateCount,
	validateCVC,
	validateDuration,
	validateEmail,
	validateExpiry,
	validateLength,
	validatePassword,
	validateRequired,
	validateScript,
	validateTestMode,
	validateTime,
	validateTimerTime
};
