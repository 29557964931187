import axios from "axios";

import { config } from "../../config/config";

const auth = axios.create({
	baseURL: config.authURL,
	timeout: 15000,
	headers: {
		Authorization: `Basic ${config.basicToken}`
	}
});

export { auth };
