import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { IPaymentsReducer, payments } from "./payments";
import account, { IAccountReducer } from "../slices/account";
import activeRuns, { IActiveRunsReducer } from "../slices/activeRuns";
import dataDump, { IDataDumpReducer } from "../slices/dataDump";
import modal, { IModalReducer } from "../slices/modal";
import persistedStorage, {
	IPersistedStorageReducer
} from "../slices/persistedStorage";
import projects, { IProjectsReducer } from "../slices/projects";
import session, { ISessionReducer } from "../slices/session";
import statics, { IStaticsReducer } from "../slices/statics";
import tests, { ITestReducer } from "../slices/tests";
import testBuilder, { ITestBuilderReducer } from "../slices/testBuilder";
import tour, { ITourReducer } from "../slices/tour";
import ui, { IUIReducer } from "../slices/ui";

export interface IStoreState {
	account: IAccountReducer;
	activeRuns: IActiveRunsReducer;
	dataDump: IDataDumpReducer;
	modal: IModalReducer;
	payments: IPaymentsReducer;
	persistedStorage: IPersistedStorageReducer;
	projects: IProjectsReducer;
	session: ISessionReducer;
	statics: IStaticsReducer;
	testBuilder: ITestBuilderReducer;
	tests: ITestReducer;
	tour: ITourReducer;
	ui: IUIReducer;
}

const rootReducer = combineReducers<IStoreState>({
	account,
	activeRuns,
	dataDump,
	modal,
	payments,
	persistedStorage,
	projects,
	session,
	statics,
	testBuilder,
	tests,
	tour,
	ui
});

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["persistedStorage"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
