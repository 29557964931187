import { type NavigateFunction } from "react-router-dom";

import { store } from "../../redux/store/configureStore";
import { routes } from "../../router/routes";

import {
	APIError,
	QueryParams,
	RequestOptions,
	RequiredField,
	ResponseWithPaging,
	TestDTOCreate,
	TestDTORead,
	TestExtendedDTOCreate,
	TestExtendedDTORead,
	TestParams,
	TestSuccessRates
} from "../../types";
import { queryKeys as qk, sortFields, sorting } from "../../utils/const";
import { checkID } from "../../utils/method";
import {
	createQuery,
	createRoute,
	getOffset,
	setQueryParam
} from "../../utils/request";

import { crud } from "./crud";

const createTest = (
	navigate: NavigateFunction,
	params: TestParams,
	data: TestDTOCreate,
	options: RequestOptions
): Promise<TestDTOCreate | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.TESTS);

	return crud.CREATE<TestDTOCreate>(navigate, route, data, options);
};

const createTestExtended = (
	navigate: NavigateFunction,
	params: TestParams,
	data: TestExtendedDTOCreate,
	options: RequestOptions
): Promise<TestExtendedDTORead | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID.toString()),
		routes.TESTS,
		routes.EXTENDED
	);

	return crud.CREATE<TestExtendedDTORead>(navigate, route, data, options);
};

const deleteTest = (
	navigate: NavigateFunction,
	params: RequiredField<TestParams, "testID">,
	options: RequestOptions
): Promise<void | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID
	);

	return crud.DELETE(navigate, route, options);
};

const getTest = (
	navigate: NavigateFunction,
	params: RequiredField<TestParams, "testID">,
	queryParams: Partial<QueryParams>,
	options: RequestOptions
): Promise<TestDTORead | APIError> => {
	const { projectID, testID } = params;
	const { describe } = queryParams || { describe: false };
	let route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID
	);

	route += createQuery(setQueryParam(qk.DESCRIBE, describe as boolean));

	return crud.READ<TestDTORead>(navigate, route, options);
};

const getTestExtended = (
	navigate: NavigateFunction,
	params: TestParams,
	options?: RequestOptions
): Promise<TestExtendedDTORead | APIError> => {
	const { NEWEST, LAST_UPDATED, OLDEST } = sorting;
	const { CREATED, UPDATED } = sortFields;
	const { projectID, testID } = params;

	let route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID.toString()),
		routes.TESTS,
		checkID(testID?.toString() || ""),
		routes.EXTENDED
	);

	const { asserts, participants } = store.getState().testBuilder.settings;
	const {
		filterValue: assertFilter,
		pagination: assertPagination,
		sorting: assertSorting
	} = asserts;
	const {
		filterValue: participantFilter,
		pagination: participantPagination,
		sorting: participantSorting
	} = participants;

	let queryParams: { [key: string]: string | number | boolean }[] = [];

	if (assertFilter) {
		queryParams = [
			...queryParams,
			setQueryParam(`${qk.PREFIX.ASSERTS}${qk.FILTER.PATH}`, assertFilter)
		];
	}

	if (participantFilter) {
		queryParams = [
			...queryParams,
			setQueryParam(
				`${qk.PREFIX.PARTICIPANTS}${qk.FILTER.NAME}`,
				participantFilter
			)
		];
	}

	if (assertPagination) {
		const { itemsPerPage, currentPage } = assertPagination;

		queryParams = [
			...queryParams,
			setQueryParam(`${qk.PREFIX.ASSERTS}${qk.LIMIT}`, itemsPerPage),
			setQueryParam(
				`${qk.PREFIX.ASSERTS}${qk.OFFSET}`,
				getOffset(itemsPerPage, currentPage)
			)
		];
	}

	if (participantPagination) {
		const { itemsPerPage, currentPage } = participantPagination;

		queryParams = [
			...queryParams,
			setQueryParam(`${qk.PREFIX.PARTICIPANTS}${qk.LIMIT}`, itemsPerPage),
			setQueryParam(
				`${qk.PREFIX.PARTICIPANTS}${qk.OFFSET}`,
				getOffset(itemsPerPage, currentPage)
			)
		];
	}

	if (assertSorting) {
		const orderBy = assertSorting === NEWEST ? `-${CREATED}` : `-${UPDATED}`;

		queryParams = [
			...queryParams,
			setQueryParam(`${qk.PREFIX.ASSERTS}${qk.ORDER_BY}`, orderBy)
		];
	}

	if (participantSorting) {
		let orderBy = `-${CREATED}`;
		if (participantSorting === OLDEST) orderBy = CREATED;
		else if (participantSorting === LAST_UPDATED) orderBy = `-${UPDATED}`;

		queryParams = [
			...queryParams,
			setQueryParam(`${qk.PREFIX.PARTICIPANTS}${qk.ORDER_BY}`, orderBy)
		];
	}

	if (queryParams.length > 0) {
		route += createQuery(...queryParams);
	}

	return crud.READ<TestExtendedDTORead>(navigate, route, options || {});
};

const getAllTests = (
	navigate: NavigateFunction,
	params: TestParams,
	queryParams: QueryParams,
	options: RequestOptions
): Promise<ResponseWithPaging<TestDTORead> | APIError> => {
	const { projectID } = params;
	const { describe, filters, labels, pagination, sorts } = queryParams;
	const { limit, page } = pagination;

	let route = createRoute(true, routes.PROJECTS, projectID, routes.TESTS);

	route += createQuery(
		setQueryParam(qk.DESCRIBE, describe as boolean),
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page)),
		setQueryParam(qk.FILTER.NAME, filters?.name || "")
	);

	const sortsQuery = sorts
		? sorts.map(sort =>
				createQuery(setQueryParam(qk.ORDER_BY, sort["order_by"]))
			)
		: [];

	const labelsQuery = labels
		? labels.map(label => createQuery(setQueryParam(qk.FILTER.LABEL, label)))
		: [];

	route += sortsQuery.join("").replaceAll("?", "&");
	route += labelsQuery.join("").replaceAll("?", "&");

	return crud.READ<ResponseWithPaging<TestDTORead>>(navigate, route, options);
};

const favorite = (
	navigate: NavigateFunction,
	params: RequiredField<TestParams, "testID">,
	options: RequestOptions
): Promise<void | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.FAVORITES
	);

	return crud.CREATE(navigate, route, options);
};

const unfavorite = (
	navigate: NavigateFunction,
	params: RequiredField<TestParams, "testID">,
	options: RequestOptions
): Promise<void | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.FAVORITES
	);

	return crud.DELETE(navigate, route, options);
};

const getAllFavoriteTests = (
	navigate: NavigateFunction,
	params: TestParams,
	queryParams: QueryParams,
	options: RequestOptions
): Promise<ResponseWithPaging<TestDTORead> | APIError> => {
	const { projectID } = params;
	const { labels, pagination, describe } = queryParams;
	const { limit, page } = pagination;

	let route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		routes.FAVORITES
	);

	route += createQuery(
		setQueryParam(qk.DESCRIBE, describe as boolean),
		setQueryParam(qk.LIMIT, limit),
		setQueryParam(qk.OFFSET, getOffset(limit, page)),
		setQueryParam(qk.ORDER_BY, sorting.NAME)
	);

	const labelsQuery = labels
		? labels.map(label => createQuery(setQueryParam(qk.FILTER.LABEL, label)))
		: [];

	route += labelsQuery.join("").replaceAll("?", "&");

	return crud.READ<ResponseWithPaging<TestDTORead>>(navigate, route, options);
};

const getTestsSuccessRates = (
	navigate: NavigateFunction,
	params: TestParams,
	testResults: TestDTORead[],
	options: RequestOptions
): Promise<TestSuccessRates | APIError> => {
	const { projectID } = params;

	let route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		routes.TRENDS
	);

	let query = "";

	testResults.forEach(test => {
		query += createQuery(setQueryParam(qk.TEST_IDS, test.id));
	});

	const firstQuestionMarkIndex = query.indexOf("?");

	route +=
		query.substring(0, firstQuestionMarkIndex + 1) +
		query.substring(firstQuestionMarkIndex + 1).replace(/\?/g, "&");

	return crud.READ<TestSuccessRates>(navigate, route, options);
};

const getSingleTestSuccessRate = (
	navigate: NavigateFunction,
	params: TestParams,
	data: Record<string, number>,
	options: RequestOptions
): Promise<TestSuccessRates | {} | APIError> => {
	const { projectID, testID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID as string,
		routes.TRENDS
	);

	return crud.CREATE<TestSuccessRates>(navigate, route, data, options);
};

const updateTest = (
	navigate: NavigateFunction,
	params: RequiredField<TestParams, "testID">,
	data: TestDTOCreate,
	options: RequestOptions
): Promise<TestDTOCreate | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID
	);

	return crud.UPDATE<TestDTOCreate>(navigate, route, data, options);
};

const copyTest = (
	navigate: NavigateFunction,
	params: Required<TestParams>,
	data: TestDTORead,
	options: RequestOptions
): Promise<TestDTOCreate | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID,
		routes.TESTS,
		testID,
		routes.COPY
	);

	return crud.CREATE<TestDTOCreate>(navigate, route, data, options);
};

const testAPI = {
	create: createTest,
	createExtended: createTestExtended,
	delete: deleteTest,
	favorite,
	read: getTest,
	readExtended: getTestExtended,
	readAll: getAllTests,
	readAllFavorites: getAllFavoriteTests,
	readSuccessRates: getTestsSuccessRates,
	readSuccessRateSingleTest: getSingleTestSuccessRate,
	unfavorite,
	update: updateTest,
	copy: copyTest
};

export default testAPI;
