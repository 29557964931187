import axios from "axios";

import { config } from "../../config/config";
import { store as reduxStore } from "../../redux/store/configureStore";
import { queryKeys } from "../../utils/const";

const v2 = axios.create({
	baseURL: `${config.apiURL}/v2`,
	timeout: 15000
});

v2.interceptors.request.use(
	conf => {
		const { baseURL, url } = conf;

		if (reduxStore.getState().persistedStorage.loadero_superuser) {
			const prefix =
				new URL(((baseURL as string) + url) as string).search === ""
					? "?"
					: "&";
			conf.url += `${prefix}${queryKeys.ADMIN}=true`;
		}

		return conf;
	},
	error => Promise.reject(error)
);

export { v2 };
