import { AxiosError } from "axios";

import {
	BillingResponse,
	PaymentFlow,
	PaymentPlan,
	Subscription
} from "../../types";

import { ActionTypes, Action } from "../actions/actionTypes";

export interface IPaymentsReducer {
	activeStep: number;
	billing: BillingResponse | null;
	billingIsFetching: boolean;
	errorMessage: AxiosError | null;
	flow: PaymentFlow | null;
	isFetching: boolean;
	isSignUpView: boolean;
	isTaxable: boolean;
	selectedPlan: PaymentPlan | null;
	steps: string[];
	subscription: Subscription | null;
}

const initialState: IPaymentsReducer = {
	activeStep: 1,
	billing: null,
	billingIsFetching: false,
	errorMessage: null,
	flow: null,
	isFetching: false,
	isSignUpView: true,
	isTaxable: false,
	selectedPlan: null,
	steps: [],
	subscription: null
};

export const payments = (
	state = initialState,
	action: Action
): IPaymentsReducer => {
	switch (action.type) {
		case ActionTypes.PaymentSetFlow:
			return {
				...state,
				flow: action.flow
			};
		case ActionTypes.PaymentGoNext:
			return {
				...state,
				activeStep: state.activeStep + 1
			};
		case ActionTypes.PaymentGoBack:
			if (state.activeStep === 1) return { ...state };

			return {
				...state,
				activeStep: state.activeStep - 1
			};
		case ActionTypes.PaymentGoToStep:
			return {
				...state,
				activeStep: action.step
			};
		case ActionTypes.PaymentSetSteps:
			return {
				...state,
				steps: action.steps
			};
		case ActionTypes.PaymentSetPlan:
			return {
				...state,
				selectedPlan: action.plan
			};

		case ActionTypes.PaymentClearData:
			return initialState;

		case ActionTypes.PaymentClearBilling:
			return {
				...state,
				billing: null
			};

		case ActionTypes.PaymentSetTaxable:
			return {
				...state,
				isTaxable: action.isTaxable
			};

		case ActionTypes.CreateSubscriptionRequest:
		case ActionTypes.FetchSubscriptionRequest:
		case ActionTypes.CancelSubscriptionRequest:
		case ActionTypes.UpdateSubscriptionRequest:
		case ActionTypes.RemoveCardRequest:
		case ActionTypes.FetchBillingRequest:
		case ActionTypes.DeleteVATRequest:
			return {
				...state,
				isFetching: true,
				errorMessage: null
			};

		case ActionTypes.CreateSubscriptionSuccess:
		case ActionTypes.FetchSubscriptionSuccess:
		case ActionTypes.UpdateSubscriptionSuccess:
			return {
				...state,
				subscription: {
					...state.subscription,
					...action.payload
				},
				isFetching: false
			};

		case ActionTypes.FetchBillingSuccess:
			return {
				...state,
				billing: action.payload,
				isFetching: false
			};
		case ActionTypes.CancelSubscriptionSuccess:
		case ActionTypes.ActivateSubscriptionSuccess:
		case ActionTypes.RemoveCardSuccess:
		case ActionTypes.DeleteVATSuccess:
			return {
				...state,
				isFetching: false
			};
		case ActionTypes.CreateSubscriptionFailure:
		case ActionTypes.UpdateSubscriptionFailure:
		case ActionTypes.FetchSubscriptionFailure:
		case ActionTypes.CancelSubscriptionFailure:
		case ActionTypes.ActivateSubscriptionFailure:
		case ActionTypes.RemoveCardFailure:
		case ActionTypes.FetchBillingFailure:
		case ActionTypes.DeleteVATFailure:
			return {
				...state,
				errorMessage: action.error,
				isFetching: false
			};

		// Billing update
		case ActionTypes.UpdateBillingRequest:
			return {
				...state,
				billingIsFetching: true,
				errorMessage: null
			};
		case ActionTypes.UpdateBillingSuccess:
			return {
				...state,
				billing: action.payload,
				billingIsFetching: false
			};
		case ActionTypes.UpdateBillingFailure:
			return {
				...state,
				errorMessage: action.error,
				billingIsFetching: false
			};
		default:
			return state;
	}
};
