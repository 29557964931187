export const appIcons = {
	"abort-circle": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H24V24H0z"
							transform="translate(-1270 -462) translate(1270 462)"
						/>
						<path
							fill="#8d8da5"
							d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm4.5 7.5h-9l-.175.01C6.58 10.597 6 11.23 6 12c0 .828.672 1.5 1.5 1.5h9l.175-.01C17.42 13.403 18 12.77 18 12c0-.828-.672-1.5-1.5-1.5z"
							transform="translate(-1270 -462) translate(1270 462)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	"action-help": (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#b1zrmqngma)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.067 6a5.4 5.4 0 1 1-10.8 0 5.4 5.4 0 0 1 10.8 0zm-5.4-2.4c-.643 0-1.2.611-1.2 1.2a.6.6 0 0 1-1.2 0c0-1.211 1.054-2.4 2.4-2.4.763 0 1.379.292 1.798.764.41.46.602 1.058.602 1.636 0 .555-.14 1.011-.382 1.373a2.18 2.18 0 0 1-.85.739c-.2.1-.397.164-.568.206V7.8h-1.2V6h.6c.006 0 .018 0 .034-.002a1.613 1.613 0 0 0 .597-.16.98.98 0 0 0 .388-.33c.096-.144.18-.363.18-.708 0-.322-.107-.625-.298-.839-.18-.203-.464-.361-.901-.361zm0 6.6a.6.6 0 1 0 0-1.2.6.6 0 0 0 0 1.2z"
					fill="#8D8DA5"
				/>
			</g>
			<defs>
				<clipPath id="b1zrmqngma">
					<path fill="#fff" transform="translate(.667)" d="M0 0h12v12H0z" />
				</clipPath>
			</defs>
		</svg>
	),
	"alert-close": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#FFFFFF"
				fillOpacity=".8"
				fillRule="evenodd"
				d="M7.87 6l3.743 3.742a1.323 1.323 0 0 1-1.871 1.87L6 7.872l-3.742 3.742a1.323 1.323 0 0 1-1.87-1.871L4.128 6 .387 2.258A1.323 1.323 0 0 1 2.258.388L6 4.128 9.742.387a1.323 1.323 0 0 1 1.87 1.871L7.872 6z"
			/>
		</svg>
	),
	"angled-arrow": (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 7.907V0h2v7.907C8 16.095 14.716 23 23 23h5.586l-4.657-3.87a.98.98 0 0 1 0-1.397 1.008 1.008 0 0 1 1.414 0l6.364 5.556a.98.98 0 0 1 0 1.398l-6.364 6.023a1.008 1.008 0 0 1-1.414 0 .98.98 0 0 1 0-1.397l4.657-4.336H23c-9.389 0-17-7.79-17-17.07z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	"arrow-down-dark": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="7"
			viewBox="0 0 12 7"
		>
			<path
				fill="#4F4F64"
				fillRule="nonzero"
				d="M11.72.28a.967.967 0 0 1 0 1.362L6.71 6.699a1 1 0 0 1-1.42 0L.28 1.642A.967.967 0 0 1 .28.28a.949.949 0 0 1 1.349 0L6 4.694 10.371.281a.949.949 0 0 1 1.348 0z"
			/>
		</svg>
	),
	"arrow_down-groups": (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.034 4.234a.8.8 0 0 1 1.132 0L8 10.07l5.834-5.835a.8.8 0 0 1 1.132 1.132l-6.4 6.4a.8.8 0 0 1-1.132 0l-6.4-6.4a.8.8 0 0 1 0-1.132z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-audio": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.6 10.8a7.2 7.2 0 0 1 7.2-7.2h2.4a7.2 7.2 0 0 1 7.2 7.2v6.6a3 3 0 0 1-6 0v-1.8a2.4 2.4 0 0 1 2.4-2.4H18v-2.4A4.8 4.8 0 0 0 13.2 6h-2.4A4.8 4.8 0 0 0 6 10.8v2.4h1.2a2.4 2.4 0 0 1 2.4 2.4v1.8a3 3 0 1 1-6 0v-6.6z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-audio-expired": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.484 3.014a.9.9 0 1 0-1.367 1.171l1.44 1.68A7.175 7.175 0 0 0 3.6 10.799v6.6a3 3 0 0 0 6 0v-1.8a2.4 2.4 0 0 0-2.4-2.4H6v-2.4c0-1.18.426-2.26 1.132-3.096l7.268 8.479v1.217a3 3 0 0 0 3.569 2.946l.548.64a.9.9 0 1 0 1.367-1.171l-14.4-16.8zM16.8 13.199a2.42 2.42 0 0 0-.337.024l3.912 4.564c.017-.127.025-.256.025-.388v-6.6a7.2 7.2 0 0 0-7.2-7.2h-2.4c-.794 0-1.558.129-2.272.366l1.766 2.06c.166-.017.335-.026.506-.026h2.4a4.8 4.8 0 0 1 4.8 4.8v2.4h-1.2z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-downloads": (
		<svg
			width="72"
			height="72"
			viewBox="0 0 72 72"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 14.4v43.2h36V28.8h-7.2c-4.081 0-7.2-3.11-7.2-7.2v-7.2H18zm-7.2.206c0-4.09 3.119-7.406 7.2-7.406h25.2l18 18v32.4c0 4.09-3.119 7.2-7.2 7.2H18c-4.081 0-7.2-3.11-7.2-7.2V14.606z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-downloads-expired": (
		<svg
			width="72"
			height="72"
			viewBox="0 0 72 72"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.8 18.596 7.2 14.4a2.7 2.7 0 1 1 4.1-3.514l44.75 52.157c.291.34.483.731.579 1.14l.099.115a8.366 8.366 0 0 1-.07.026 2.699 2.699 0 0 1-4.708 2.233L50.442 64.8H18c-4.081 0-7.2-3.11-7.2-7.2V18.596zM44.265 57.6H18V26.988L44.265 57.6zM54 28.8v24.018l6.614 7.716A7.462 7.462 0 0 0 61.2 57.6V25.2l-18-18H18c-.95 0-1.848.18-2.666.507L21.07 14.4H39.6v7.2c0 4.09 3.119 7.2 7.2 7.2H54z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-photos": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.6 6A2.4 2.4 0 0 1 6 3.6h12A2.4 2.4 0 0 1 20.4 6v12a2.4 2.4 0 0 1-2.4 2.4H6A2.4 2.4 0 0 1 3.6 18V6zM18 18H6l3.122-6.243a.6.6 0 0 1 1.045-.05L12.6 15.6l1.32-1.76a.6.6 0 0 1 .96 0L18 18zm-1.8-8.4a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-photos-expired": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m3.6 6.171-.615-.717a.9.9 0 1 1 1.367-1.171l14.4 16.8a.9.9 0 0 1-1.366 1.171l-1.59-1.854H6A2.4 2.4 0 0 1 3.6 18V6.17zM13.739 18H6l2.851-5.703L13.74 18zm1.141-4.16.755 1.007 4.113 4.798c.404-.43.652-1.009.652-1.645V6A2.4 2.4 0 0 0 18 3.6H5.995l8.599 10.031c.11.037.21.107.286.209zM16.2 9.6a1.8 1.8 0 1 0 0-3.6 1.8 1.8 0 0 0 0 3.6z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-video": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 3.6A2.4 2.4 0 0 0 3.6 6v12A2.4 2.4 0 0 0 6 20.4h12a2.4 2.4 0 0 0 2.4-2.4V6A2.4 2.4 0 0 0 18 3.6H6zm-.7 1.2a.5.5 0 0 0-.5.5v1.4c0 .138.056.263.146.353L7.2 4.8H5.3zm4.3 0L7.2 7.2h2.4L12 4.8H9.6zm4.8 0L12 7.2h2.4l2.4-2.4h-2.4zm4.653.146L16.8 7.2h1.9a.5.5 0 0 0 .5-.5V5.3a.498.498 0 0 0-.146-.354zM9.6 15.74v-5.08a.6.6 0 0 1 .909-.515l4.233 2.54a.6.6 0 0 1 0 1.03l-4.233 2.54a.6.6 0 0 1-.909-.515z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"artifacts-video-expired": (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m3.6 6.171-.615-.717a.9.9 0 1 1 1.367-1.171l14.4 16.8a.9.9 0 0 1-1.366 1.171l-1.59-1.854H6A2.4 2.4 0 0 1 3.6 18V6.17zm8.054 9.396-1.145.687a.6.6 0 0 1-.909-.514V13.17l2.054 2.396zM7.2 4.8l-.095.095 1.108 1.292L9.6 4.8H12L9.6 7.2h-.52l3.686 4.3 1.976 1.185a.6.6 0 0 1 0 1.03l-.051.03 5.057 5.9c.404-.43.652-1.009.652-1.645V6A2.4 2.4 0 0 0 18 3.6H5.995l1.029 1.2H7.2zm7.2 0L12 7.2h2.4l2.4-2.4h-2.4zm4.654.146L16.8 7.2h1.9a.5.5 0 0 0 .5-.5V5.3a.498.498 0 0 0-.146-.354z"
				fill="#4F4F64"
			/>
		</svg>
	),
	avatar: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xlinkHref="http://www.w3.org/1999/xlink"
			width="145"
			height="145"
			viewBox="0 0 145 145"
		>
			<defs>
				<circle id="a" cx="72.5" cy="72.5" r="72.5" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<mask id="b" fill="#fff">
					<use xlinkHref="#a" />
				</mask>
				<use fill="#EFF0F6" xlinkHref="#a" />
				<path
					fill="#E3E3E8"
					d="M127.997 145v-11.646c0-5.658-3.66-10.458-8.737-12.162-.4-.134-26.388-6.773-30.109-14.161-1.695-3.374-.022-7.696 1.67-10.675a51.903 51.903 0 0 0 2.263-3.304v-.009c4.405-7.046 7.765-16.311 8.104-27.768C101.708 48.04 88.37 34.043 72.54 34h-.078c-15.831.039-29.164 14.036-28.649 31.27.343 11.456 3.699 20.727 8.104 27.769a48.947 48.947 0 0 0 2.263 3.313c1.696 2.982 3.365 7.301 1.67 10.674-3.716 7.384-29.71 14.023-30.109 14.162-5.077 1.7-8.741 6.504-8.741 12.162v11.646l110.997.005z"
					mask="url(#b)"
				/>
			</g>
		</svg>
	),
	back: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="12"
			viewBox="0 0 14 12"
		>
			<path
				fill="#4F4F64"
				fillRule="nonzero"
				d="M3.025 4.999L6.05 1.764c.37-.413.37-1.072 0-1.467A.88.88 0 0 0 5.385 0a.897.897 0 0 0-.666.313L.278 5.275c-.37.412-.37 1.071 0 1.466l4.44 4.962a.88.88 0 0 0 .667.297.88.88 0 0 0 .666-.297 1.11 1.11 0 0 0 .28-.742c0-.28-.103-.544-.266-.741L3.025 7l9.974-.005C13.517 6.995 14 6.577 14 6s-.483-1.001-1-1.001H3.024z"
			/>
		</svg>
	),
	bolt: (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="m4.042 7.747 1.866-5.6a.8.8 0 0 1 .76-.547h2.241a.8.8 0 0 1 .743 1.097L8.49 5.6h1.768a.8.8 0 0 1 .681 1.22l-4.714 7.66c-.224.364-.785.17-.738-.254L6.09 8.8H4.8a.8.8 0 0 1-.76-1.053z"
				fill="#0D9EAA"
			/>
		</svg>
	),
	book: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m9 6.38-1.106-.67A4.765 4.765 0 0 0 3 5.68v8.449a8.086 8.086 0 0 1 5.956.04l.044.018V6.38zm1 10.386-1.787-.722a6.11 6.11 0 0 0-5.981.8C1.724 17.226 1 16.861 1 16.22V5.061c0-.27.126-.525.34-.687a6.75 6.75 0 0 1 7.583-.395L10 4.63l1.077-.652a6.75 6.75 0 0 1 7.583.395c.214.162.34.416.34.687v11.16c0 .64-.724 1.006-1.232.622a6.11 6.11 0 0 0-5.981-.799L10 16.766zm1-2.578V6.38l1.106-.67A4.765 4.765 0 0 1 17 5.68v8.449a8.086 8.086 0 0 0-5.956.04l-.044.018z"
				fill="#682EC7"
			/>
		</svg>
	),
	calendar: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="16"
			viewBox="0 0 17 16"
		>
			<path
				fill="#E3E3E8"
				fillRule="evenodd"
				d="M14.876 2h-2.125V1c0-.553-.477-1-1.064-1-.586 0-1.062.447-1.062 1v1h-4.25V1c0-.553-.475-1-1.062-1C4.726 0 4.25.447 4.25 1v1H2.125C.952 2 0 2.896 0 4v10c0 1.104.952 2 2.125 2h12.75C16.048 16 17 15.104 17 14V4c0-1.105-.951-2-2.124-2zm0 12H2.125V5.5h12.75l.001 8.5z"
			/>
		</svg>
	),
	"chart-pan-hand": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="14"
			viewBox="0 0 10 14"
		>
			<g fill="none" fillRule="evenodd">
				<path fillRule="nonzero" d="M-2-1h16v16H-2z" opacity=".094" />
				<path
					fill="#4F4F64"
					d="M9.207 2.121a.793.793 0 0 0-.792.794V6.68h-.37V1.724a.793.793 0 1 0-1.585 0v4.955h-.37V.794a.793.793 0 0 0-1.585 0V6.68h-.37V1.724a.793.793 0 0 0-1.585 0v7.11L1.458 7.238a.791.791 0 0 0-1.096-.233.795.795 0 0 0-.233 1.099l2.233 3.873C3.018 13.044 4.471 14 5.981 14c2.412 0 3.916-1.682 3.916-3.76L10 6.68V2.915a.794.794 0 0 0-.793-.795z"
				/>
			</g>
		</svg>
	),
	"chart-reset-zoom": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 15 15"
		>
			<g fill="none" fillRule="nonzero">
				<path d="M-1-1h16v16H-1z" opacity=".094" />
				<path
					fill="#4F4F64"
					d="M10.254 11.697a6.502 6.502 0 0 1-7.159.433 1 1 0 0 1 1-1.732 4.5 4.5 0 1 0-1.317-6.641l1.122.72a.237.237 0 0 1 .073.304.207.207 0 0 1-.104.098L.822 6.124c-.103.042-.219-.017-.257-.132a.243.243 0 0 1-.01-.04L.004 2.399c-.019-.12.054-.236.163-.257a.184.184 0 0 1 .135.027l.79.506a6.5 6.5 0 1 1 9.163 9.023l-.162-.172a.87.87 0 0 1 .099-1.3 1.068 1.068 0 0 1 1.416.092l2.992 3.158a.87.87 0 0 1-.099 1.3 1.068 1.068 0 0 1-1.416-.092l-2.83-2.986z"
				/>
			</g>
		</svg>
	),
	"chart-zoom": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				fill="#4F4F64"
				fillRule="nonzero"
				d="M11.947 10.048l3.66 3.66a1.343 1.343 0 0 1-1.9 1.899l-3.659-3.66a6.5 6.5 0 1 1 1.899-1.899zM10.121 6.5a3.622 3.622 0 1 0-7.243 0 3.622 3.622 0 0 0 7.243 0z"
			/>
		</svg>
	),
	"chart-zoom-in": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 15 15"
		>
			<g fill="none" fillRule="nonzero">
				<path d="M-1-1h16v16H-1z" opacity=".094" />
				<path
					fill="#4F4F64"
					d="M11.762 10.317l2.992 3.158a.87.87 0 0 1-.1 1.3 1.068 1.068 0 0 1-1.415-.092l-2.832-2.988a6.5 6.5 0 1 1 1.355-1.378zM11 6.5a4.5 4.5 0 1 0-9.001.001A4.5 4.5 0 0 0 11 6.5zm-5.5-1v-1a1 1 0 1 1 2 0v1h1a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0v-1h-1a1 1 0 1 1 0-2h1z"
				/>
			</g>
		</svg>
	),
	"chart-zoom-out": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15"
			height="15"
			viewBox="0 0 15 15"
		>
			<g fill="none" fillRule="nonzero">
				<path d="M-1-1h16v16H-1z" opacity=".094" />
				<path
					fill="#4F4F64"
					d="M11.762 10.317l2.992 3.158a.87.87 0 0 1-.1 1.3 1.068 1.068 0 0 1-1.415-.092l-2.832-2.988a6.5 6.5 0 1 1 1.355-1.378zM11 6.5a4.5 4.5 0 1 0-9.001.001A4.5 4.5 0 0 0 11 6.5zm-6.5 1a1 1 0 1 1 0-2h4a1 1 0 0 1 0 2h-4z"
				/>
			</g>
		</svg>
	),
	"check-green": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.65 3.24a1 1 0 0 1 .11 1.41l-10.286 12a1 1 0 0 1-1.397.12l-5.714-4.727a1 1 0 0 1 1.274-1.54l4.958 4.1L17.24 3.35a1 1 0 0 1 1.41-.108z"
				fill="#17A06A"
			/>
		</svg>
	),
	"chevron-right": (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.293 18.707a1 1 0 0 1 0-1.414L12.586 10 5.293 2.707a1 1 0 0 1 1.414-1.414l8 8a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	"chevron-up": (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.965 11.766a.8.8 0 0 1-1.131 0L7.999 5.93l-5.834 5.835a.8.8 0 0 1-1.131-1.132l6.4-6.4a.8.8 0 0 1 1.13 0l6.4 6.4a.8.8 0 0 1 0 1.132z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	close: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
		>
			<path
				fill="#B8B8C7"
				fillRule="evenodd"
				d="M11.806 9l5.613 5.613a1.984 1.984 0 0 1-2.806 2.806L9 11.806 3.387 17.42a1.984 1.984 0 0 1-2.806-2.806L6.194 9 .58 3.387A1.984 1.984 0 0 1 3.387.581L9 6.194 14.613.58a1.984 1.984 0 0 1 2.806 2.806L11.806 9z"
			/>
		</svg>
	),
	collapse: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#4F4F64"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M13 6h-3V3M3 10h3v3"
			/>
		</svg>
	),
	critical: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.375 9.09 9.09 2.375c.5-.5 1.31-.5 1.81 0L17.624 9.1c.498.497.5 1.303.006 1.804l-6.636 6.715a1.28 1.28 0 0 1-1.81.01L2.38 10.906a1.28 1.28 0 0 1-.005-1.815zM10 5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
				fill="#AE192E"
			/>
		</svg>
	),
	download: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 3a1 1 0 1 0-2 0v7.586L6.707 8.293a1 1 0 0 0-1.414 1.414L10 14.414l4.707-4.707a1 1 0 0 0-1.414-1.414L11 10.586V3zM5 13a1 1 0 1 0-2 0v5h14v-5a1 1 0 1 0-2 0v3H5v-3z"
				fill="#262630"
			/>
		</svg>
	),
	duplicate: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H16V16H0z"
							transform="translate(-232.000000, -302.000000) translate(232.000000, 302.000000)"
						/>
						<path
							fill="#72708F"
							d="M12.333 2c.867 0 1.579.661 1.66 1.506l.007.16v6.667c0 .867-.661 1.579-1.506 1.66l-.16.007H12v.333c0 .872-.67 1.588-1.523 1.66l-.144.007H3.667c-.872 0-1.588-.67-1.66-1.523L2 12.333V5.667c0-.872.67-1.588 1.523-1.66L3.667 4H4v-.333c0-.867.661-1.579 1.506-1.66L5.666 2h6.667zM7 6c-.298 0-.546.218-.592.503L6.4 6.6v1.8H4.6l-.108.01C4.212 8.46 4 8.705 4 9c0 .298.218.546.503.592L4.6 9.6l1.8-.001V11.4l.01.108c.05.28.295.492.59.492.298 0 .546-.218.592-.503L7.6 11.4l-.001-1.801L9.4 9.6l.108-.01c.28-.05.492-.295.492-.59 0-.298-.218-.546-.503-.592L9.4 8.4H7.599L7.6 6.6l-.01-.108C7.54 6.212 7.295 6 7 6zm5.333-2.889H5.667c-.273 0-.5.197-.547.456l-.009.1V4h5.222c.872 0 1.588.67 1.66 1.523l.007.144v5.221h.333c.273 0 .5-.196.547-.455l.009-.1V3.667c0-.273-.197-.5-.456-.547l-.1-.009z"
							transform="translate(-232.000000, -302.000000) translate(232.000000, 302.000000)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	error: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g fill="none" fillRule="evenodd">
				<path d="M0 0h16v16H0z" />
				<path
					fill="#E2364F"
					d="M9.414.59l5.996 5.996a2 2 0 0 1 0 2.828L9.414 15.41a2 2 0 0 1-2.828 0L.59 9.414a2 2 0 0 1 0-2.828L6.586.59a2 2 0 0 1 2.828 0zM8.04 10.08h-.08a1 1 0 0 0-1 1v.08a1 1 0 0 0 1 1h.08a1 1 0 0 0 1-1v-.08a1 1 0 0 0-1-1zm0-7.28h-.08a1 1 0 0 0-1 1v4.24a1 1 0 0 0 1 1h.08a1 1 0 0 0 1-1V3.8a1 1 0 0 0-1-1z"
				/>
			</g>
		</svg>
	),
	expand: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				stroke="#4F4F64"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M10 3h3v3M6 13H3v-3"
			/>
		</svg>
	),
	external: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M9 3H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4m-4-8h4m0 0v4m0-4-7 7"
				stroke="#8D8DA5"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	fail: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#E2364F"
				fillRule="nonzero"
				d="M6 3.66L9.175.485a1.655 1.655 0 1 1 2.34 2.34L8.34 6l3.175 3.175a1.655 1.655 0 1 1-2.34 2.34L6 8.34l-3.175 3.175a1.655 1.655 0 0 1-2.34-2.34L3.66 6 .485 2.825a1.655 1.655 0 1 1 2.34-2.34L6 3.66z"
			/>
		</svg>
	),
	"fail-circle": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g transform="translate(-1270 -602) translate(1270 602)">
						<path d="M0 0H24V24H0z" />
						<circle cx="12" cy="12" r="9" fill="#E2364F" fillRule="nonzero" />
						<path
							fill="#FFF"
							fillRule="nonzero"
							d="M8.17 6.596l.125.109L12 10.409l3.705-3.704c.439-.44 1.151-.44 1.59 0 .4.399.436 1.024.11 1.464l-.11.126L13.591 12l3.704 3.705c.44.439.44 1.151 0 1.59-.399.4-1.024.436-1.464.11l-.126-.11L12 13.591l-3.705 3.704c-.439.44-1.151.44-1.59 0-.4-.399-.436-1.024-.11-1.464l.11-.126L10.409 12 6.704 8.295c-.44-.439-.44-1.151 0-1.59.355-.356.888-.424 1.311-.205l.153.096z"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	filter: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 6a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm2 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1zm3 3a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2H8z"
				fill="#262630"
			/>
		</svg>
	),
	folder: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.297 4.445A1 1 0 0 0 7.465 4H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-6.465a1 1 0 0 1-.832-.445l-1.406-2.11z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	help: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g transform="translate(-548.000000, -270.000000) translate(548.000000, 270.000000)">
						<circle
							cx="8"
							cy="8"
							r="8"
							fill="#10BFCE"
							fillRule="nonzero"
							className="help-icon-body"
						/>
						<path
							stroke="#FFF"
							strokeLinecap="round"
							strokeWidth="2"
							d="M8 12V7.2H6.4M8 12H6.4M8 12h1.6"
						/>
						<circle
							cx="8"
							cy="4"
							r="1"
							fill="#FFF"
							fillRule="nonzero"
							stroke="#FFF"
							strokeWidth=".5"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	"help-grey": (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0zm-9-4C8.929 6 8 7.019 8 8a1 1 0 0 1-2 0c0-2.019 1.758-4 4-4 1.271 0 2.298.487 2.997 1.273C13.68 6.041 14 7.037 14 8c0 .925-.234 1.685-.637 2.29a3.632 3.632 0 0 1-1.416 1.23 4.548 4.548 0 0 1-.947.344V13H9v-3h1l.058-.003a2.69 2.69 0 0 0 .995-.266c.256-.129.482-.305.646-.551.16-.24.301-.605.301-1.18 0-.537-.18-1.041-.497-1.398C11.202 6.263 10.729 6 10 6zm0 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	hide: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="14"
			viewBox="0 0 16 14"
		>
			<g fill="none" fillRule="evenodd">
				<path d="M0-1h16v16H0z" />
				<path
					fill="#FFF"
					d="M7.113 9.867A3 3 0 0 0 10.039 4.8l-.419.724c.334.351.55.825.55 1.365 0 1.147-.973 2-2 2-.156 0-.31-.02-.46-.057l-.597 1.035zm4.072-7.054C14.02 4.223 16 6.976 16 6.976S12.418 12 8 12a6.45 6.45 0 0 1-1.94-.31l-.828 1.434a1 1 0 0 1-1.732-1L10 .866a1 1 0 0 1 1.732 1l-.547.947zM7.018 4.164a3.002 3.002 0 0 0-1.964 3.403l-1.609 2.786C1.363 8.888 0 6.976 0 6.976S3.582 2 8 2c.089 0 .177.002.265.006L7.018 4.164z"
				/>
			</g>
		</svg>
	),
	info: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 12 12"
		>
			<path
				fill="#4F4F64"
				fillRule="evenodd"
				d="M6 0a6.001 6.001 0 1 0 .001 12.002A6.001 6.001 0 0 0 6 0zm-.082 9.947a.687.687 0 1 1 0-1.373.687.687 0 0 1 0 1.373zm.75-3.334s-.067.024-.067.064v.545a.684.684 0 0 1-1.367 0v-.545c.006-.62.407-1.168.997-1.36a.97.97 0 0 0 .663-.952.997.997 0 0 0-.982-.945.968.968 0 0 0-.87.551.684.684 0 1 1-1.231-.593A2.326 2.326 0 0 1 5.9 2.053h.013a2.36 2.36 0 0 1 2.344 2.271A2.33 2.33 0 0 1 6.67 6.611l-.001.002z"
			/>
		</svg>
	),
	"info-white": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="8"
			height="8"
			viewBox="0 0 8 8"
		>
			<path
				fill="#FFF"
				fillRule="evenodd"
				d="M4 0a4 4 0 1 0 0 8.002A4 4 0 0 0 4 0zm-.055 6.632a.458.458 0 1 1 0-.916.458.458 0 0 1 0 .916zm.5-2.223s-.044.016-.044.042v.364a.456.456 0 0 1-.912 0V4.45a.964.964 0 0 1 .665-.906.647.647 0 0 0 .442-.635.665.665 0 0 0-.655-.63.646.646 0 0 0-.58.368.456.456 0 1 1-.82-.396 1.55 1.55 0 0 1 1.393-.884h.009a1.574 1.574 0 0 1 1.563 1.515 1.553 1.553 0 0 1-1.06 1.525z"
			/>
		</svg>
	),
	java: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			fill="none"
			viewBox="0 0 40 40"
		>
			<path
				fill="#4F4F64"
				d="M15.284 30.328s-1.362.813.969 1.088c2.823.331 4.266.284 7.377-.321 0 0 .818.527 1.96.983-6.974 3.069-15.784-.178-10.306-1.75zM14.432 26.323s-1.527 1.161.805 1.409c3.016.32 5.397.345 9.518-.47 0 0 .57.594 1.467.918-8.433 2.532-17.825.2-11.79-1.857zM21.616 19.53c1.719 2.031-.451 3.86-.451 3.86s4.363-2.314 2.36-5.21c-1.872-2.7-3.308-4.043 4.463-8.67 0 0-12.197 3.128-6.372 10.02z"
			/>
			<path
				fill="#4F4F64"
				d="M30.84 33.29s1.008.853-1.11 1.512c-4.025 1.252-16.755 1.63-20.29.05-1.272-.568 1.112-1.356 1.862-1.521.782-.174 1.229-.142 1.229-.142-1.414-1.022-9.138 2.008-3.924 2.876 14.22 2.368 25.922-1.066 22.233-2.774zM15.939 22.174s-6.476 1.579-2.293 2.152c1.765.243 5.285.188 8.564-.094 2.68-.232 5.37-.725 5.37-.725s-.944.415-1.628.894c-6.575 1.776-19.277.95-15.62-.866 3.092-1.535 5.607-1.361 5.607-1.361zM27.554 28.84c6.685-3.566 3.594-6.993 1.437-6.531-.529.113-.765.21-.765.21s.197-.315.572-.452c4.267-1.54 7.549 4.543-1.378 6.953 0 0 .103-.095.134-.18zM23.524 2.04s3.702 3.801-3.51 9.648c-5.785 4.69-1.32 7.364-.003 10.42-3.376-3.128-5.854-5.882-4.192-8.444 2.44-3.762 9.199-5.585 7.705-11.625z"
			/>
			<path
				fill="#4F4F64"
				d="M16.596 38.504c6.416.422 16.268-.234 16.501-3.351 0 0-.448 1.181-5.302 2.12-5.476 1.058-12.23.934-16.236.256 0 0 .82.697 5.037.975z"
			/>
		</svg>
	),
	javascript: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
		>
			<path
				fill="#4F4F64"
				fillRule="nonzero"
				d="M0 0h40v40H0V0zm13.592 31.576l-3.06 1.852c.886 1.876 2.63 3.434 5.639 3.434 3.33 0 5.612-1.772 5.612-5.664V18.366h-3.758v12.781c0 1.879-.78 2.362-2.014 2.362-1.292 0-1.829-.886-2.42-1.933zm13.444-.28L24 33.099C25.097 35.311 27.339 37 30.809 37 34.357 37 37 35.118 37 31.683c0-3.187-1.792-4.604-4.967-5.994l-.934-.41c-1.603-.708-2.297-1.171-2.297-2.316 0-.926.693-1.635 1.788-1.635 1.073 0 1.764.462 2.404 1.635l2.91-1.908C34.673 18.845 32.965 18 30.59 18c-3.337 0-5.472 2.178-5.472 5.04 0 3.107 1.79 4.576 4.487 5.75l.934.409c1.704.76 2.72 1.224 2.72 2.533 0 1.091-.989 1.882-2.537 1.882-1.843 0-2.885-.982-3.686-2.317z"
			/>
		</svg>
	),
	"load-more": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<g
				clipPath="url(#lhcxv7gnia)"
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#682EC7"
			>
				<path d="m8.258 13.469 1.122 1.18a.8.8 0 1 1-1.16 1.102l-2.4-2.525a.8.8 0 0 1 0-1.102l2.4-2.525a.8.8 0 1 1 1.16 1.102l-1.106 1.164C10.323 11.716 12 9.91 12 7.625c0-.637-.131-1.192-.362-1.687a.8.8 0 1 1 1.45-.676c.33.71.512 1.495.512 2.363 0 3.104-2.335 5.703-5.342 5.844zM10.18 3.876a.8.8 0 0 0 0-1.102L7.78.249A.8.8 0 0 0 6.62 1.35l1.122 1.18C4.735 2.672 2.4 5.271 2.4 8.375c0 .868.181 1.653.512 2.363a.8.8 0 0 0 1.45-.676A3.932 3.932 0 0 1 4 8.375c0-2.285 1.677-4.091 3.726-4.24L6.62 5.299A.8.8 0 0 0 7.78 6.4l2.4-2.525z" />
			</g>
			<defs>
				<clipPath id="lhcxv7gnia">
					<path fill="#fff" d="M0 0h16v16H0z" />
				</clipPath>
			</defs>
		</svg>
	),
	members: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="16"
			viewBox="0 0 18 16"
		>
			<path
				fill="#B8B8C7"
				fillRule="evenodd"
				d="M5.21 8.358v.056c-.824-1.316-1.455-1.892-1.455-4.066a4.314 4.314 0 0 1 1.019-3.012C5.478.518 6.46.036 7.499 0c2.116 0 3.531 2.174 3.54 4.348.011 2.174-.63 2.696-1.454 4.011-.925 1.62.611 2.305 1.252 2.706 2.757 1.696 3.958 2.337 3.958 3.163v1.087a.677.677 0 0 1-.172.486.591.591 0 0 1-.449.199H.623a.604.604 0 0 1-.447-.202.692.692 0 0 1-.175-.483v-1.087c0-.826 1.201-1.467 3.958-3.163.64-.402 2.177-1.12 1.252-2.707zM17.5 14.185h-1.506a7.57 7.57 0 0 0-.102-.837 11.986 11.986 0 0 0-4.07-2.923c-2.086-.99-.804-2.468-.804-2.468 2.87-3.533.52-6.522.52-6.522.223-.022.448-.022.67 0a2.988 2.988 0 0 1 2.016 1.09c.517.624.79 1.436.763 2.269a4.734 4.734 0 0 1-1.16 3.26c-.702 1.284.51 1.86 1.018 2.175C17.043 11.576 18 12.087 18 12.739v.903a.553.553 0 0 1-.142.383.487.487 0 0 1-.357.16z"
			/>
		</svg>
	),
	"options-toggle": (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 5a1 1 0 0 0 0 2h14a1 1 0 1 0 0-2H3zm0 4a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H3zm0 4a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H3zm10.89-5a.5.5 0 0 0-.485.379l-.18.722c-.258.069-.501.17-.726.301l-.64-.383a.5.5 0 0 0-.61.075l-.155.155a.5.5 0 0 0-.075.61l.383.64c-.13.225-.232.468-.3.726l-.723.18a.5.5 0 0 0-.379.485v.22a.5.5 0 0 0 .379.485l.722.18c.069.258.17.501.301.726l-.383.64a.5.5 0 0 0 .075.61l.155.155a.5.5 0 0 0 .61.075l.64-.383c.225.13.468.232.726.3l.18.723a.5.5 0 0 0 .485.379h.22a.5.5 0 0 0 .485-.379l.18-.722c.258-.069.501-.17.726-.301l.64.383a.5.5 0 0 0 .61-.075l.155-.155a.5.5 0 0 0 .075-.61l-.383-.64c.13-.225.232-.468.3-.726l.723-.18A.5.5 0 0 0 18 12.11v-.22a.5.5 0 0 0-.379-.485l-.722-.18c-.069-.258-.17-.501-.301-.726l.383-.64a.5.5 0 0 0-.075-.61l-.155-.155a.5.5 0 0 0-.61-.075l-.64.383a2.982 2.982 0 0 0-.726-.3l-.18-.723A.5.5 0 0 0 14.11 8h-.22zm1.61 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
				fill="#262630"
			/>
		</svg>
	),
	participant: (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.4 4a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0zM8 .8a3.2 3.2 0 1 0 0 6.4A3.2 3.2 0 0 0 8 .8zM3.2 15.2a4.8 4.8 0 1 1 9.6 0 .8.8 0 1 0 1.6 0 6.4 6.4 0 0 0-12.8 0 .8.8 0 0 0 1.6 0z"
				fill="#4F4F64"
			/>
		</svg>
	),
	pass: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="11"
			viewBox="0 0 14 11"
		>
			<path
				fill="#36E29D"
				fillRule="nonzero"
				d="M13.522.43a1.578 1.578 0 0 1 0 2.197l-6.591 6.8a2 2 0 0 1-2.872 0L.478 5.732a1.578 1.578 0 0 1 0-2.196 1.482 1.482 0 0 1 2.128 0l2.889 2.98L11.394.431a1.482 1.482 0 0 1 2.128 0z"
			/>
		</svg>
	),
	"pass-circle": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H24V24H0z"
							transform="translate(-1270 -535) translate(1270 535)"
						/>
						<path
							fill="#17a06a"
							fillRule="nonzero"
							d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm5.26 5.17c-.458-.42-1.17-.388-1.59.07l-4.798 5.235-2.68-2.09-.14-.092c-.476-.27-1.092-.157-1.44.288-.381.49-.294 1.197.197 1.58l4.319 3.365 6.201-6.766.104-.13c.307-.455.244-1.078-.173-1.46z"
							transform="translate(-1270 -535) translate(1270 535)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	pencil: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H16V16H0z"
							transform="translate(-148.000000, -302.000000) translate(148.000000, 302.000000)"
						/>
						<path
							fill="#72708F"
							d="M7.75.118c1.172 0 2.121.95 2.121 2.122v12.02L7.75 16.382 5.629 14.26V2.24c0-1.172.95-2.122 2.121-2.122z"
							transform="translate(-148.000000, -302.000000) translate(148.000000, 302.000000) translate(7.750000, 8.250000) rotate(-315.000000) translate(-7.750000, -8.250000)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	plus: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 2a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-6v6a1 1 0 1 1-2 0v-6H3a1 1 0 1 1 0-2h6V3a1 1 0 0 1 1-1z"
				fill="#262630"
			/>
		</svg>
	),
	precondition: (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.4 4a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0zM4 .8a3.2 3.2 0 0 0-.8 6.3V8a4 4 0 0 0 4 4h5.27l-1.835 1.834a.8.8 0 0 0 1.132 1.132l3.2-3.2a.8.8 0 0 0 0-1.132l-3.2-3.2a.8.8 0 0 0-1.132 1.132L12.47 10.4H7.201A2.4 2.4 0 0 1 4.8 8v-.9A3.201 3.201 0 0 0 4 .8z"
				fill="#4F4F64"
			/>
		</svg>
	),
	"preview-hide": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="14"
			viewBox="0 0 16 14"
		>
			<g fill="none" fillRule="evenodd">
				<path d="M0-1h16v16H0z" />
				<path
					fill="#FFF"
					d="M7.113 9.867A3 3 0 0 0 10.039 4.8l-.419.724c.334.351.55.825.55 1.365 0 1.147-.973 2-2 2-.156 0-.31-.02-.46-.057l-.597 1.035zm4.072-7.054C14.02 4.223 16 6.976 16 6.976S12.418 12 8 12a6.45 6.45 0 0 1-1.94-.31l-.828 1.434a1 1 0 0 1-1.732-1L10 .866a1 1 0 0 1 1.732 1l-.547.947zM7.018 4.164a3.002 3.002 0 0 0-1.964 3.403l-1.609 2.786C1.363 8.888 0 6.976 0 6.976S3.582 2 8 2c.089 0 .177.002.265.006L7.018 4.164z"
				/>
			</g>
		</svg>
	),
	python: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="40"
			viewBox="0 0 40 40"
		>
			<g fill="none" fillRule="evenodd">
				<g fill="#4F4F64" fillRule="nonzero">
					<g>
						<g>
							<path
								d="M19.752 0c-10.101 0-9.47 4.42-9.47 4.42l.01 4.58h9.64v1.375H6.464S0 9.635 0 19.92c0 10.286 5.642 9.92 5.642 9.92h3.367v-4.772s-.181-5.693 5.552-5.693h9.56s5.372.088 5.372-5.239V5.33S30.31 0 19.753 0zm-5.315 3.08c.959 0 1.734.782 1.734 1.75 0 .967-.775 1.75-1.734 1.75-.96 0-1.734-.783-1.734-1.75 0-.968.775-1.75 1.734-1.75z"
								transform="translate(-470 -491) translate(456 475) translate(14 16)"
							/>
							<path
								d="M20.04 39.944c10.1 0 9.47-4.42 9.47-4.42l-.011-4.58h-9.64v-1.375h13.468s6.464.74 6.464-9.545c0-10.286-5.642-9.92-5.642-9.92h-3.367v4.772s.182 5.693-5.551 5.693H15.67s-5.372-.088-5.372 5.239v8.806s-.815 5.33 9.741 5.33zm5.314-3.08c-.959 0-1.734-.782-1.734-1.75 0-.967.775-1.75 1.734-1.75.96 0 1.735.783 1.735 1.75 0 .968-.775 1.75-1.735 1.75z"
								transform="translate(-470 -491) translate(456 475) translate(14 16)"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	),
	recent: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 10a7 7 0 1 1 14 0 7 7 0 0 1-14 0zm7-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM9 5v5H6v2h3a2 2 0 0 0 2-2V5H9z"
				fill="#4F4F64"
			/>
		</svg>
	),
	search: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				fill="#B8B8C7"
				fillRule="nonzero"
				d="M11.947 10.048l3.66 3.66a1.343 1.343 0 0 1-1.9 1.899l-3.659-3.66a6.5 6.5 0 1 1 1.899-1.899zM10.121 6.5a3.622 3.622 0 1 0-7.243 0 3.622 3.622 0 0 0 7.243 0z"
			/>
		</svg>
	),
	"search-thinner": (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 8.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0zM8.5 1a7.5 7.5 0 1 0 4.55 13.463l4.243 4.244a1 1 0 0 0 1.414-1.414l-4.244-4.244A7.5 7.5 0 0 0 8.5 1z"
				fill="#8D8DA5"
			/>
		</svg>
	),
	settings: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<path
				fill="#B8B8C7"
				fillRule="evenodd"
				d="M15.75 6.997c-.128-.082-1.137-.685-1.537-.869l-.496-1.2c.147-.397.43-1.509.48-1.702a.536.536 0 0 0-.145-.497l-.781-.777a.53.53 0 0 0-.497-.144c-.147.034-1.28.32-1.702.48l-1.2-.496C9.697 1.408 9.11.424 9.003.254A.53.53 0 0 0 8.551 0H7.449a.536.536 0 0 0-.452.25c-.082.127-.685 1.137-.869 1.537l-1.2.496c-.397-.147-1.509-.43-1.702-.48a.536.536 0 0 0-.497.144l-.777.782a.53.53 0 0 0-.144.497c.034.147.32 1.28.48 1.702l-.496 1.2c-.384.174-1.368.762-1.538.869A.53.53 0 0 0 0 7.449v1.1c0 .183.094.354.25.453.127.082 1.137.685 1.537.869l.496 1.2c-.147.396-.43 1.508-.48 1.702-.04.179.014.366.144.496l.778.777c.13.13.317.185.496.145.147-.034 1.28-.32 1.702-.48l1.2.496c.175.384.762 1.368.869 1.537.097.16.27.257.457.256h1.1a.538.538 0 0 0 .453-.25c.082-.128.685-1.137.869-1.537l1.2-.496c.396.147 1.508.43 1.702.48.179.04.366-.015.496-.145l.777-.777a.532.532 0 0 0 .145-.496c-.034-.147-.32-1.28-.48-1.702l.496-1.2c.384-.175 1.368-.762 1.537-.869A.531.531 0 0 0 16 8.551V7.449a.528.528 0 0 0-.25-.452zM8 11.483a3.486 3.486 0 1 1 .005-6.972A3.486 3.486 0 0 1 8 11.483z"
			/>
		</svg>
	),
	"settings-tab": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.905 2.379A.5.5 0 0 1 9.39 2h1.22a.5.5 0 0 1 .485.379l.456 1.823a5.965 5.965 0 0 1 1.452.602l1.612-.967a.5.5 0 0 1 .61.075l.863.862a.5.5 0 0 1 .075.611l-.967 1.612c.26.45.465.938.602 1.452l1.823.456A.5.5 0 0 1 18 9.39v1.22a.5.5 0 0 1-.379.485l-1.823.456a5.962 5.962 0 0 1-.602 1.452l.967 1.612a.5.5 0 0 1-.075.61l-.862.863a.5.5 0 0 1-.611.075l-1.612-.967c-.45.26-.938.465-1.452.602l-.456 1.823a.5.5 0 0 1-.485.379H9.39a.5.5 0 0 1-.485-.379l-.456-1.823a5.961 5.961 0 0 1-1.452-.602l-1.612.967a.5.5 0 0 1-.61-.075l-.863-.862a.5.5 0 0 1-.075-.611l.967-1.612a5.964 5.964 0 0 1-.602-1.452l-1.823-.456A.5.5 0 0 1 2 10.61V9.39a.5.5 0 0 1 .379-.485l1.823-.456a5.964 5.964 0 0 1 .602-1.452l-.967-1.612a.5.5 0 0 1 .075-.61l.862-.863a.5.5 0 0 1 .611-.075l1.612.967c.45-.26.938-.464 1.452-.602l.456-1.823zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
				fill="#4F4F64"
			/>
		</svg>
	),
	sort: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="4"
			height="7"
			viewBox="0 0 4 7"
		>
			<g fill="none" fillOpacity=".8" fillRule="evenodd">
				<g fill="#4F4F64" fillRule="nonzero">
					<g>
						<path
							d="M291 19h4l-2 3-2-3zm2-4l2 3h-4l2-3z"
							transform="translate(-412 -446) translate(121 431)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	"sort-asc": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="6"
			height="4"
			viewBox="0 0 6 4"
		>
			<g fill="none" fillOpacity=".8" fillRule="evenodd">
				<g fill="#4F4F64" fillRule="nonzero">
					<g>
						<path
							d="M273 20L270 16 276 16z"
							transform="translate(-391 -447) translate(121 431) rotate(-180 273 18)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	"sort-desc": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="6"
			height="4"
			viewBox="0 0 6 4"
		>
			<g fill="none" fillOpacity=".8" fillRule="evenodd">
				<g fill="#4F4F64" fillRule="nonzero">
					<g>
						<path
							d="M282 20L279 16 285 16z"
							transform="translate(-400 -447) translate(121 431)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	spinner: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20px"
			height="20px"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
		>
			<circle
				cx="50"
				cy="50"
				r="40"
				strokeWidth="10"
				stroke="#682ec7"
				strokeDasharray="62.5"
				fill="none"
				strokeLinecap="round"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					repeatCount="indefinite"
					dur="1s"
					keyTimes="0;1"
					values="0 50 50;360 50 50"
				/>
			</circle>
		</svg>
	),
	star: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.885 2.605a1 1 0 0 0-1.769 0L7.228 6.184l-3.987.69a1 1 0 0 0-.547 1.682l2.82 2.902-.576 4.005a1 1 0 0 0 1.431 1.04l3.632-1.786 3.63 1.786a1 1 0 0 0 1.432-1.04l-.576-4.005 2.82-2.902a1 1 0 0 0-.547-1.682l-3.987-.69-1.888-3.58z"
				fill="#682EC7"
			/>
		</svg>
	),
	tag: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M2 6.4C2 5.075 2.875 4 3.954 4h9.6c.573 0 1.118.31 1.49.846l2.49 3.6c.621.896.621 2.212 0 3.108l-2.49 3.6c-.372.537-.917.846-1.49.846h-9.6C2.874 16 2 14.925 2 13.6V6.4z"
				stroke="#4F4F64"
				strokeWidth="2"
			/>
		</svg>
	),
	tests: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 2a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H5zM4 5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v5h-3v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-2H4V5z"
				fill="#4F4F64"
			/>
		</svg>
	),
	timeout: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="11"
			viewBox="0 0 14 11"
		>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#AE8709"
					d="M7.864.48l5.259 9.016A1 1 0 0 1 12.259 11H1.741a1 1 0 0 1-.864-1.504L6.137.481a1 1 0 0 1 1.727 0z"
				/>
				<path
					fill="#FFFFFF"
					fillRule="nonzero"
					d="M7 3a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1zm0 5a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
				/>
			</g>
		</svg>
	),
	tour: (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.2 7.2h-.743A5.604 5.604 0 0 1 7.2 2.457V3.2a.8.8 0 1 0 1.6 0v-.743A5.604 5.604 0 0 1 13.543 7.2H12.8a.8.8 0 0 0 0 1.6h.743A5.604 5.604 0 0 1 8.8 13.543V12.8a.8.8 0 0 0-1.6 0v.743A5.604 5.604 0 0 1 2.457 8.8H3.2a.8.8 0 1 0 0-1.6zM8 .8a7.2 7.2 0 1 0 0 14.4A7.2 7.2 0 0 0 8 .8zM6.892 5.995l3.766-1.173c.308-.096.607.167.525.465L10.19 8.91c-.14.514-.549.925-1.082 1.091L5.34 11.176c-.309.096-.607-.168-.526-.465l.994-3.625c.141-.513.549-.925 1.083-1.09zM8.8 8a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0z"
				fill="#682EC7"
			/>
		</svg>
	),
	trash: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H16V16H0z"
							transform="translate(-349.000000, -302.000000) translate(349.000000, 302.000000)"
						/>
						<path
							fill="#72708F"
							d="M12 5v8c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V5h8zm-2-3v1h2c.513 0 .936.386.993.883L13 4H3c0-.552.448-1 1-1h2V2h4z"
							transform="translate(-349.000000, -302.000000) translate(349.000000, 302.000000)"
						/>
					</g>
				</g>
			</g>
		</svg>
	),
	trashAlt: (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 1a1 1 0 0 0-1 1v1H3a1 1 0 0 0-1 1v1h16V4a1 1 0 0 0-1-1h-4V2a1 1 0 0 0-1-1H8zM3 17V7h14v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm7-8a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm5 1a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0v-6zM6 9a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
				fill="#AE192E"
			/>
		</svg>
	),
	view: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="10"
			viewBox="0 0 16 10"
		>
			<g fill="none" fillRule="evenodd">
				<path
					fill="#4F4F64"
					d="M8 6.667C6.98 6.667 6.154 5.92 6.154 5S6.98 3.333 8 3.333c1.02 0 1.846.746 1.846 1.667 0 .92-.827 1.667-1.846 1.667zM4.923 5c0-1.534 1.378-2.778 3.077-2.778S11.077 3.466 11.077 5 9.699 7.778 8 7.778 4.923 6.534 4.923 5zM0 4.976S3.582 10 8 10s8-5.024 8-5.024S12.418 0 8 0 0 4.976 0 4.976z"
				/>
				<path d="M0-3h16v16H0z" />
			</g>
		</svg>
	),
	warning: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#E2364F"
				fillRule="evenodd"
				d="M7.106 3.789l-4.382 8.764c-.333.665.15 1.447.894 1.447h8.764c.743 0 1.227-.782.894-1.447L8.894 3.789c-.368-.737-1.42-.737-1.788 0zM7.5 6v5h1V6h-1zm0 6v1h1v-1h-1z"
				clipRule="evenodd"
			/>
		</svg>
	),
	"warning-circle": (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<path
							d="M0 0H24V24H0z"
							transform="translate(-1270 -395) translate(1270 395)"
						/>
						<path
							fill="#F3C425"
							fillRule="nonzero"
							d="M4.137 18.923L10.664 4.85c.526-1.133 2.146-1.133 2.672 0l6.527 14.073c.45.97-.262 2.077-1.336 2.077H5.473c-1.074 0-1.786-1.107-1.336-2.077z"
							transform="translate(-1270 -395) translate(1270 395)"
						/>
						<path
							fill="#443404"
							fillRule="nonzero"
							d="M13 17v2h-2v-2h2zm0-8v6h-2V9h2z"
							transform="translate(-1270 -395) translate(1270 395)"
						/>
					</g>
				</g>
			</g>
		</svg>
	)
};
