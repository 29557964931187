import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import { APIError, FileParams, FileData, RequestOptions } from "../../types";
import { createRoute } from "../../utils/request";

import { crud } from "./crud";

const getFile = (
	navigate: NavigateFunction,
	params: FileParams,
	options: RequestOptions
): Promise<FileData | {} | APIError> => {
	const { fileID, projectID } = params;

	const route = createRoute(
		true,
		routes.PROJECTS,
		projectID as string,
		routes.FILES,
		fileID as string
	);

	return crud.READ<FileData>(navigate, route, options);
};

const fileAPI = {
	read: getFile
};

export default fileAPI;
