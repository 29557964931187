import type { AxiosResponse } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CRUDArgs,
	CRUDDataOptionsTypes,
	CRUDOptions
} from "../../types";
import { handleAPIRateLimiting, handleError } from "../helper";

import { api } from "./api";

const CREATE = <T>(
	navigate: NavigateFunction,
	route: string,
	data: CRUDDataOptionsTypes,
	options: CRUDDataOptionsTypes
): Promise<T | {} | APIError> => {
	const { skipRetry, suppressNotification } = (options as CRUDOptions) || {};

	return new Promise((resolve, reject) => {
		api.POST<T>(navigate, route, data, options as CRUDOptions).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				handleError(
					error,
					skipRetry as boolean,
					suppressNotification as boolean,
					reject
				);
			}
		);
	});
};

const DELETE = (
	navigate: NavigateFunction,
	route: string,
	options: CRUDDataOptionsTypes
): Promise<void | APIError> => {
	const { skipRetry, suppressNotification } = (options as CRUDOptions) || {};

	return new Promise<void>((resolve, reject) => {
		api.DELETE(navigate, route, options).then(
			() => {
				resolve();
			},
			error => {
				handleError(
					error,
					skipRetry as boolean,
					suppressNotification as boolean,
					reject
				);
			}
		);
	});
};

const READ = <T>(
	navigate: NavigateFunction,
	route: string,
	options: CRUDDataOptionsTypes
): Promise<T> => {
	const { skipRetry, suppressNotification, suppressRedirect } =
		(options as CRUDOptions) || {};

	return new Promise((resolve, reject) => {
		api.GET<T>(navigate, route, options as CRUDOptions).then(
			response => {
				resolve(response.data);
			},
			error => {
				if (error && error.response) {
					const { status } = error.response;

					if (
						navigate &&
						!suppressRedirect &&
						route.startsWith(routes.PROJECTS) &&
						status === 404
					) {
						navigate(routes.PAGE_NOT_FOUND);
					}

					// Don't show toast with invalid token error
					if (status === 401) {
						reject();

						return;
					}
				}

				handleError(
					error,
					skipRetry as boolean,
					suppressNotification as boolean,
					reject
				);
			}
		);
	});
};

const UPDATE = <T>(
	navigate: NavigateFunction,
	route: string,
	data: CRUDDataOptionsTypes,
	options: CRUDDataOptionsTypes
): Promise<AxiosResponse<T> | {} | APIError> => {
	const { skipRetry, suppressNotification } = (options as CRUDOptions) || {};

	return new Promise((resolve, reject) => {
		api.PUT<T>(navigate, route, data, options as CRUDOptions).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				handleError(
					error,
					skipRetry as boolean,
					suppressNotification as boolean,
					reject
				);
			}
		);
	});
};

const crud = {
	CREATE: <T>(...args: CRUDArgs) => handleAPIRateLimiting<T | {}>(CREATE, args),
	READ: <T>(...args: CRUDArgs) => handleAPIRateLimiting<T>(READ, args),
	DELETE: (...args: CRUDArgs) => handleAPIRateLimiting(DELETE, args),
	UPDATE: <T>(...args: CRUDArgs) => handleAPIRateLimiting<T | {}>(UPDATE, args)
};

export { crud };
