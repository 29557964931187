import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";

import {
	APIError,
	BillingResponse,
	PaymentCardParams,
	RequestOptions,
	SubscriptionParams,
	SubscriptionData
} from "../../types";

import { createRoute } from "../../utils/request";

import { crud } from "./crud";

const deleteCard = (
	navigate: NavigateFunction,
	params: PaymentCardParams,
	data?: FormData,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { cardID, projectID, subscriptionID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID,
		routes.BILLING,
		routes.CARDS,
		cardID
	);

	return crud.DELETE(
		navigate,
		route,
		data as FormData,
		options as RequestOptions
	);
};

const deleteCompany = (
	navigate: NavigateFunction,
	params: PaymentCardParams,
	data?: FormData,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { cardID, projectID, subscriptionID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID,
		routes.BILLING,
		routes.NAME,
		cardID
	);

	return crud.DELETE(
		navigate,
		route,
		data as FormData,
		options as RequestOptions
	);
};

const deleteVat = (
	navigate: NavigateFunction,
	params: SubscriptionParams,
	data?: FormData,
	options?: RequestOptions
): Promise<void | APIError> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID as number,
		routes.BILLING,
		routes.VAT
	);

	return crud.DELETE(
		navigate,
		route,
		data as FormData,
		options as RequestOptions
	);
};

const readBilling = (
	navigate: NavigateFunction,
	params: SubscriptionParams,
	options?: RequestOptions
): Promise<BillingResponse | {}> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID as number,
		routes.BILLING
	);

	return crud.READ<BillingResponse>(navigate, route, options as RequestOptions);
};

const updateBilling = (
	navigate: NavigateFunction,
	params: SubscriptionParams,
	data: SubscriptionData,
	options: RequestOptions
): Promise<BillingResponse | {}> => {
	const { projectID, subscriptionID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		projectID,
		routes.SUBSCRIPTIONS,
		subscriptionID as number,
		routes.BILLING
	);

	return crud.UPDATE<BillingResponse>(
		navigate,
		route,
		data as SubscriptionData,
		options
	);
};

const billingAPI = {
	deleteCard,
	deleteCompany,
	deleteVat,
	update: updateBilling,
	read: readBilling
};

export default billingAPI;
