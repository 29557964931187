import { type NavigateFunction } from "react-router-dom";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { avatarAPI } from "../../api/mediaAPI";
import { accountAPI } from "../../api/v2";

import { setUserID } from "../../services/crisp";

import { APIError, AccountData, RequestOptions, Role } from "../../types";

export interface IAccountReducer {
	accountData: AccountData;
	errorMessage: APIError | string | null;
	image: ArrayBuffer | null;
	isFetching: boolean;
	role: string | null;
}

const initialState: IAccountReducer = {
	accountData: {} as AccountData,
	errorMessage: null,
	image: null,
	isFetching: false,
	role: null
};

export const fetchAvatar = createAsyncThunk(
	"account/fetchAvatar",
	async (
		{
			navigate,
			accountID,
			options
		}: {
			navigate: NavigateFunction;
			accountID: number;
			options?: RequestOptions;
		},
		{ rejectWithValue }
	): Promise<Blob | APIError | {}> => {
		const params = { accountID };
		try {
			const avatar = await avatarAPI.account.read(navigate, params, options);

			const reader = new FileReader();

			const image = await new Promise<string>((resolve, reject) => {
				reader.readAsDataURL(avatar as Blob);
				reader.onload = () => resolve(reader.result as string);
				reader.onerror = reject;
			});

			return image;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchAccountData = createAsyncThunk(
	"account/fetchAccountData",
	async (
		{
			navigate,
			options,
			getAvatar = true
		}: {
			navigate: NavigateFunction;
			options: RequestOptions | {};
			getAvatar?: boolean;
		},
		{ rejectWithValue, dispatch }
	): Promise<AccountData | {} | APIError> => {
		try {
			const account = await accountAPI.read(navigate, options);

			if ("id" in account) {
				setUserID(account.id);
				if (getAvatar) {
					await dispatch(fetchAvatar({ navigate, accountID: account.id }));
				}
			}
			return account;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		clearAccountData: () => {
			return initialState;
		},
		setRole: (state, action: { payload: Role }) => {
			state.role = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchAvatar.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
				state.image = null;
			})
			.addCase(fetchAvatar.fulfilled, (state, action) => {
				state.isFetching = false;
				state.errorMessage = null;
				state.image = action.payload as ArrayBuffer;
			})
			.addCase(fetchAvatar.rejected, (state, action) => {
				state.isFetching = false;
				state.errorMessage = action.payload as string;
				state.image = null;
			})
			.addCase(fetchAccountData.pending, state => {
				state.isFetching = true;
				state.errorMessage = null;
			})
			.addCase(fetchAccountData.fulfilled, (state, action) => {
				state.isFetching = false;
				state.errorMessage = null;
				state.accountData = action.payload as AccountData;
			})
			.addCase(fetchAccountData.rejected, (state, action) => {
				state.isFetching = false;
				state.errorMessage = action.payload as string;
				state.accountData = {} as AccountData;
			});
	}
});

export const { setRole, clearAccountData } = accountSlice.actions;

export default accountSlice.reducer;
