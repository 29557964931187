const EVENT_KEYS = {
	ACCOUNT: {
		LOGIN: "loadero.account.loggedin",
		REGISTER: "loadero.account.signup",
		REGISTER_CONFIRM: "loadero.account.signup_confirm",
		REGISTER_OPEN: "loadero.register.open",
		REGISTER_SUBMIT: "loadero.register.submit",
		TRIAL_STARTED: "loadero.trial.started"
	},
	CONTACT_FORM: {
		SUBMITTED: "loadero.contact_form.submitted"
	},
	MEMBER: {
		INVITE: "loadero.member.invite"
	},
	PROJECT: {
		PROJECT_CREATE: "loadero.project.create"
	},
	REGISTER: {
		CONFIRM: "loadero.register.confirm"
	},
	TEST: {
		RUN: "loadero.testrun.start",
		TEST_CREATE: "loadero.test.create",
		TEST_RUN_CREATE: "loadero.testrun.create"
	},
	UPGRADE_FLOW: {
		BILLING_DETAILES_ADDED: "loadero.upgrade.billing_details_added",
		COMPLETE: "loadero.upgrade.completed",
		PAID: "loadero.upgrade.paid",
		START: "loadero.upgrade.started",
		UPGRADE_PLAN_SELECTED: "loadero.upgrade.plan_selected"
	}
};

const EVENT_KEYS_TYPE = "loadero_event_key";

export { EVENT_KEYS, EVENT_KEYS_TYPE };
