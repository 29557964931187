import { isCancel } from "axios";
import { type NavigateFunction } from "react-router-dom";

import { routes } from "../../router/routes";
import {
	APIError,
	CreateLabelData,
	LabelData,
	LabelParams,
	ResponseWithPaging,
	RequestOptions,
	TestLabelData
} from "../../types";

import { alertConflictError } from "../../utils/error";
import { checkID } from "../../utils/method";
import { createRoute } from "../../utils/request";

import { api } from "./api";
import { crud } from "./crud";

const createLabel = (
	navigate: NavigateFunction,
	params: LabelParams,
	data: CreateLabelData,
	options: RequestOptions
): Promise<LabelData | {} | APIError> => {
	const { projectID } = params;
	const route = createRoute(true, routes.PROJECTS, projectID, routes.LABELS);

	return new Promise((resolve, reject) => {
		api.POST<LabelData | {} | APIError>(navigate, route, data, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const deleteLabel = (
	navigate: NavigateFunction,
	params: LabelParams
): Promise<void | {} | APIError> => {
	const { labelID, projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.LABELS,
		checkID(labelID as string)
	);

	return new Promise((resolve, reject) => {
		api.DELETE<void | {} | APIError>(navigate, route).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const getAllLabels = (
	navigate: NavigateFunction,
	params: LabelParams,
	options: RequestOptions
): Promise<ResponseWithPaging<LabelData> | APIError> => {
	const { projectID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.LABELS
	);

	return crud.READ<ResponseWithPaging<LabelData>>(navigate, route, options);
};

const updateLabel = (
	navigate: NavigateFunction,
	params: LabelParams,
	data: CreateLabelData,
	options: RequestOptions
): Promise<LabelData | {} | APIError> => {
	const { labelID, projectID } = params;

	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.LABELS,
		checkID(labelID as string)
	);

	return crud.UPDATE<LabelData>(navigate, route, data, options);
};

// Test labels
const createTestLabel = (
	navigate: NavigateFunction,
	params: LabelParams,
	data: { label_id: number },
	options: RequestOptions
): Promise<TestLabelData | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.TESTS,
		checkID(testID as string),
		routes.LABELS
	);

	return new Promise((resolve, reject) => {
		api
			.POST<TestLabelData | {} | APIError>(navigate, route, data, options)
			.then(
				response => {
					resolve(response.data || {});
				},
				error => {
					if (isCancel(error)) return reject(error);

					alertConflictError(error);
					reject(error);
				}
			);
	});
};

const deleteTestLabel = (
	navigate: NavigateFunction,
	params: LabelParams
): Promise<void | {} | APIError> => {
	const { labelID, projectID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.TESTS,
		checkID(testID as string),
		routes.LABELS,
		checkID(labelID as string)
	);

	return new Promise((resolve, reject) => {
		api.DELETE<void | {} | APIError>(navigate, route).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const getAllTestLabels = (
	navigate: NavigateFunction,
	params: LabelParams,
	options: RequestOptions
): Promise<ResponseWithPaging<TestLabelData> | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.TESTS,
		checkID(testID as string),
		routes.LABELS
	);

	return crud.READ<ResponseWithPaging<TestLabelData>>(navigate, route, options);
};

const bulkUpdateTestLabels = (
	navigate: NavigateFunction,
	params: LabelParams,
	ids: { label_ids: number[] },
	options: RequestOptions
): Promise<LabelData | {} | APIError> => {
	const { projectID, testID } = params;
	const route = createRoute(
		false,
		routes.PROJECTS,
		checkID(projectID),
		routes.TESTS,
		checkID(testID as string),
		routes.LABELS,
		routes.BULK
	);

	return new Promise((resolve, reject) => {
		api.POST<LabelData | {} | APIError>(navigate, route, ids, options).then(
			response => {
				resolve(response.data || {});
			},
			error => {
				if (isCancel(error)) return reject(error);

				alertConflictError(error);
				reject(error);
			}
		);
	});
};

const labelAPI = {
	PROJECT: {
		create: createLabel,
		delete: deleteLabel,
		readAll: getAllLabels,
		update: updateLabel
	},
	TEST: {
		create: createTestLabel,
		delete: deleteTestLabel,
		readAll: getAllTestLabels,
		updateList: bulkUpdateTestLabels
	}
};

export default labelAPI;
