import { createSlice } from "@reduxjs/toolkit";
import { TourStep } from "../../types";

export interface ITourReducer {
	continuous: boolean;
	loading: boolean;
	run: boolean;
	stepIndex: number;
	steps: TourStep[];
}

const initialState: ITourReducer = {
	continuous: true,
	loading: false,
	run: false,
	stepIndex: 0,
	steps: []
};

const tourSlice = createSlice({
	name: "tour",
	initialState,
	reducers: {
		nextPrevTour: (state, action: { payload: number }) => {
			state.stepIndex = action.payload;
		},
		resetTour: state => {
			state.stepIndex = 0;
		},
		restartTour: state => {
			state.stepIndex = 0;
			state.run = true;
		},
		startTour: state => {
			state.run = true;
		},
		stopTour: state => {
			state.run = false;
		},
		setTourSteps: (state, action: { payload: TourStep[] }) => {
			state.steps = action.payload;
		}
	}
});

export const {
	nextPrevTour,
	resetTour,
	restartTour,
	startTour,
	stopTour,
	setTourSteps
} = tourSlice.actions;

export default tourSlice.reducer;
