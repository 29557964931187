import { config, keys } from "../config/config";
import { env, paymentPlans } from "../utils/const";

declare global {
	interface Window {
		$crisp: unknown[];
		CRISP_WEBSITE_ID: string;
	}
}

const initCrisp = (): void => {
	if (config.env === env.DEV || typeof window === "undefined") return;

	window.$crisp = [];
	window.$crisp.push(["safe", true]);
	window.CRISP_WEBSITE_ID = keys.CRISP as string;

	const script = document.createElement("script");
	script.src = "https://client.crisp.chat/l.js";
	script.async = true;

	document.head.appendChild(script);
};

const isCrispAvailable = (): boolean => "$crisp" in window;

const setUserID = (id: number): void => {
	if (!isCrispAvailable()) return;

	window.$crisp.push(["set", "session:data", [[["account_id", id]]]]);
};

const setProjectID = (id: number): void => {
	if (!isCrispAvailable()) return;

	window.$crisp.push(["set", "session:data", [[["project_id", id]]]]);
};

const setSubscriptionPlan = (
	planName: string,
	status = "none",
	hasNoPlan?: boolean
): void => {
	if (!isCrispAvailable()) return;

	const { ENTERPRISE, ESSENTIAL, MONTHLY, TRIAL, ULTIMATE, YEARLY } =
		paymentPlans;

	let plan;

	switch (planName) {
		case ENTERPRISE:
			plan = ENTERPRISE;
			break;
		case ESSENTIAL.value:
			plan = ESSENTIAL.name;
			break;
		case ULTIMATE.value:
			plan = ULTIMATE.name;
			break;
		case MONTHLY.value:
			plan = MONTHLY.name;
			break;
		case YEARLY.value:
			plan = YEARLY.name;
			break;
		default:
			plan = hasNoPlan ? "none" : TRIAL.name;
	}

	window.$crisp.push([
		"set",
		"session:data",
		[
			[
				["subscription_plan", plan],
				["subscription_status", status]
			]
		]
	]);
};

const setProjectLanguage = (language: string): void => {
	if (!isCrispAvailable()) return;

	window.$crisp.push([
		"set",
		"session:data",
		[[["project_language", language]]]
	]);
};

const clearAllData = (): void => {
	if (!isCrispAvailable()) return;

	window.$crisp.push([
		"set",
		"session:data",
		[
			[
				["account_id", ""],
				["project_id", ""],
				["project_language", ""],
				["subscription_plan", ""],
				["subscription_status", ""]
			]
		]
	]);
};

const clearProjectData = (accountID: number): void => {
	if (!isCrispAvailable()) return;

	window.$crisp.push([
		"set",
		"session:data",
		[
			[
				["account_id", accountID],
				["project_id", ""],
				["project_language", ""],
				["subscription_plan", ""],
				["subscription_status", ""]
			]
		]
	]);
};

export {
	clearAllData,
	clearProjectData,
	initCrisp,
	setProjectID,
	setProjectLanguage,
	setSubscriptionPlan,
	setUserID
};
