import { createSlice } from "@reduxjs/toolkit";
import {
	AlertModal,
	ConfirmModal,
	ContactModal,
	CookiesManageBanner,
	ModalType,
	ScriptPreviewModal,
	SuccessModal
} from "../../types/modal";

export type ConfirmModalInfo = {
	message: string | JSX.Element;
	name: string | number;
	payload?: string | number;
	type: ModalType;
};

export interface IModalReducer {
	alertModal: AlertModal;
	confirmModal: ConfirmModal;
	contactModal: ContactModal;
	cookiesManageBanner: CookiesManageBanner;
	data: string | number | null;
	isActive: boolean;
	name: string | number;
	scriptPreviewModal: ScriptPreviewModal;
	successModal: SuccessModal;
}

const initialState: IModalReducer = {
	alertModal: {
		active: false
	},
	confirmModal: {
		active: false,
		message: null,
		name: null,
		payload: null,
		type: null
	},
	contactModal: {
		active: false,
		source: ""
	},
	cookiesManageBanner: {
		active: false
	},
	isActive: false,
	data: null,
	name: "",
	scriptPreviewModal: {
		active: false,
		scriptFileID: 0,
		title: ""
	},
	successModal: {
		active: false,
		info: null
	}
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		showContactModal: (state, action: { payload: string }) => {
			state.contactModal.source = action.payload || "";
			state.contactModal.active = true;
		},
		hideContactModal: state => {
			state.contactModal = initialState.contactModal;
		},
		showConfirmModal: (state, action: { payload: ConfirmModalInfo }) => {
			state.confirmModal = { ...action.payload, active: true };
		},
		hideConfirmModal: state => {
			state.confirmModal = initialState.confirmModal;
		},
		showModal: (
			state,
			action: {
				payload: { modalName: string | number; data?: string | number };
			}
		) => {
			state.isActive = true;
			state.name = action.payload.modalName;
			state.data = action.payload.data || null;
		},
		hideModal: state => {
			state.isActive = false;
			state.name = "";
			state.data = null;
		},
		showScriptPreviewModal: (
			state,
			action: { payload: { scriptFileID: number; title: string } }
		) => {
			state.scriptPreviewModal = {
				active: true,
				scriptFileID: action.payload.scriptFileID,
				title: action.payload.title
			};
		},
		hideScriptPreviewModal: state => {
			state.scriptPreviewModal = initialState.scriptPreviewModal;
		},
		showAlertModal: state => {
			state.alertModal.active = true;
		},
		hideAlertModal: state => {
			state.alertModal.active = false;
		},
		showSuccessModal: (state, action: { payload: string }) => {
			state.successModal = { active: true, info: action.payload };
		},
		hideSuccessModal: state => {
			state.successModal = initialState.successModal;
		},
		showCookiesManageBanner: state => {
			state.cookiesManageBanner.active = true;
		},
		hideCookiesManageBanner: state => {
			state.cookiesManageBanner.active = false;
		}
	}
});

export const {
	showContactModal,
	hideContactModal,
	showConfirmModal,
	hideConfirmModal,
	showModal,
	hideModal,
	showScriptPreviewModal,
	hideScriptPreviewModal,
	showAlertModal,
	hideAlertModal,
	showSuccessModal,
	hideSuccessModal,
	showCookiesManageBanner,
	hideCookiesManageBanner
} = modalSlice.actions;

export default modalSlice.reducer;
